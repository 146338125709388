import React from "react";

const Support = () => {
  const phoneNumber = +919485948516;
  return (
    <div className="contact-us-outer container">
      <div className="contact-us d-flex">
        <div className="contact-us-left">
          <img src="/assets/images/young-man.svg" />
        </div>
        <div className="contact-us-right">
          <h4>Need Support ?</h4>
          <p>
            Have questions or need assistance? Insurefast is here for you. Our
            dedicated support team provides expert guidance and timely
            assistance, ensuring your insurance journey is smooth and
            worry-free.
          </p>
          <div className="contact-us-buttons">
            <a href={`tel:${phoneNumber}`}>
              <button className="contact-us-button">
                <img className="me-3" src="/assets/images/callIcon.svg" />
                <span>Call Us</span>
              </button>
            </a>
            <a href="mailto:support@insurefast.in">
              <button className="contact-us-button">
                <img className="me-3" src="/assets/images/mailIcon.svg" />
                <span>Mail Us</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
