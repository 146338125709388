import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import { Link, useNavigate } from "react-router-dom";
import {
  dispatchHealthQuickQuote,
  dispatchProcessFlowHealth,
  dispatchResetProcessFlowHealth,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  healthQuickQuoteResult,
  quickQuoteResult,
  resetHealthQuickQuoteResults,
  resetQuickQuoteResults,
  resetSelectedPlan,
} from "../store/action/userActions";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerHealth,
  processFlow,
  sendOtp,
} from "./services/TPApiCall";
import store from "../store";
import WhyChoose from "./WhyChoose";
import AboutHealth from "./AboutHealth";
import Footer from "./common/Footer";
import OtpModel from "./common/OtpModel";
import Layout from "./common/Layout";
import { PostDataWithToken } from "../api/apiHelper";
import ReactSelect from "./Tags/ReactSelect";
import { getVehiclePincodeHealth } from "./services/masterServices";

const Health = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [diseaseErrors, setDiseaseErrors] = useState("");
  const healthRequestQQ = useSelector((state) => state.root.healthRequestQQ);
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [noExistingDisease, setNoExistingDisease] = useState("");
  // const [healthQuoteResults, setHealthQuoteResults] = useState(null);
  const [resendObj, setResendObj] = useState({});
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  const [age, setAge] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit0,
    register: register0,
    getValues: getValues0,
    formState: { errors: errors0 },
    reset0,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    getValues: getValues1,
    formState: { errors: errors1 },
    reset1,
  } = useForm({ mode: "onBlur" });

  // const handleButtonClick = (checkboxId) => {
  //   const checkboxElement = document.getElementById(checkboxId);
  //   checkboxElement.checked = !isChecked; // Toggle checkbox state directly
  //   setIsChecked(!isChecked);
  // };

  const ageRange = () => {
    const age = [];
    for (let i = 18; i <= 99; i++) {
      age.push(i);
    }
    setAge(age);
  };

  useEffect(() => {
    ageRange();
  }, []);

  useEffect(() => {
    if (mobileNumber.length === 10) {
      submitUserDetails();
    }
  }, [mobileNumber]);

  useEffect(() => {
    // setQueryStringType(false);

    getVehiclePincodeHealth()
      .then((response) => {
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pincode,
              label: response.data[i].Pincode,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
            });

            i++;
          }
          while (j < response.data.length) {
            arr1.push({
              value: response.data[j].Pin_Code,
              label: response.data[j].District,
              District: response.data[j].District,
              City: response.data[j].City,
            });

            j++;
          }
          setPincodeData(arr);
          // setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));

    dispatch(resetHealthQuickQuoteResults());
    dispatch(resetSelectedPlan());
    dispatchResetProcessFlowMotor();
    dispatchResetProcessFlowHealth();
  }, []);

  const handlePincodeChange = (val) => {
    const newData = { ...healthRequestQQ };

    dispatchHealthQuickQuote("CustomerDetails.customerPincode", val.value);

    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.pincode",
      val.value
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.pincode",
      val.value
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.city",
      val.City
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.state",
      val.State
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.city",
      val.City
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.state",
      val.State
    );
  };

  const submitMedicalHistory = (data) => {
    if (
      data?.NoExistingDesease ||
      data?.Diabetes ||
      data?.endoAndPituitaryDeasease ||
      data?.hypertensionOrBloodPressure ||
      data?.lungDeaseas ||
      data?.surgeries
    ) {
      if (data.noExistingDisease === true) {
        setNoExistingDisease(true);
      }
      const min = 10000000000;
      const max = 99999999999;
      const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
      dispatchHealthQuickQuote("enquiryId", randomNumber);
      let newData = { ...healthRequestQQ };

      for (let key in data) {
        dispatchHealthQuickQuote(
          `CustomerDetails.customerMedicalHistory.${key}`,
          data[key]
        );
      }
      dispatchProcessFlowHealth("step2", data);
      const flowData = {
        ...processFlowObjHealth,
        step: "step2",
        step2: data,
      };
      processFlow({ processFlow: flowData }).then((response) => {});

      setCurrentStep(currentStep + 1);
    } else {
      setDiseaseErrors(
        'Please select at least one disease or choose "No Disease".'
      );
    }
  };

  const submitUserDetails = async () => {
    setSpinnerActive(true);
    const data = { ...healthRequestQQ };

    // const planType = "health";
    // const optData = { ...data, planType: "health" };

    // const customerData = {
    //   customerName: data?.CustomerDetails?.customerMiddleName
    //     ? data?.CustomerDetails?.customerFirstName +
    //       " " +
    //       data?.CustomerDetails?.customerMiddleName +
    //       " " +
    //       data?.CustomerDetails?.customerLastName
    //     : data?.CustomerDetails?.customerFirstName +
    //       " " +
    //       data?.CustomerDetails?.customerLastName,
    //   customerMobileNumber: data?.CustomerDetails?.customerMobileNumber,
    // };
    // dispatchProcessFlowHealth("step3", customerData);

    // const newData = {
    //   ...processFlowObjHealth,
    //   loggedInUser:
    //     userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false,
    //   step: "step3",
    //   step3: customerData,
    // };

    // processFlow({ processFlow: newData }).then((response) => {
    //   console.log("RESPONSE", response);
    // });

    // console.log("OTP RESPONSE HEALTH", response);
    if (Object.keys(userDetails).length > 0) {
      dispatchResetQuickQuote();
      navigate("/health-quote");
    } else {
      setResendObj({ ...data, planType: "health" });
      const response = await sendOtp({ ...data, planType: "health" });
      dispatchResetQuickQuote();
      setOtpVerfiyModel(true);
      setSpinnerActive(false);
    }

    // try {
    //   console.log("HEALTH REQUEST QQ", healthRequestQQ);
    //   let userData = { ...healthRequestQQ };
    //   console.log("NEW DATAAAA", userData);

    //   // const response = await createCustomerHealth(userData);

    //   if (response) {
    //     // Handle the successful response here
    //     console.log("Received response in the health component:", response);
    //     let healthQuoteResults = response?.data?.successQuotes;

    //     store.dispatch(healthQuickQuoteResult(healthQuoteResults));
    //   } else {
    //     // Handle the case where response is null or success is not true
    //     console.error("Failed to get a valid response");
    //   }
    //   setSpinnerActive(false);
    //   // navigate("/quotelist");
    // } catch (error) {
    //   // Handle errors that might occur during the API call
    //   console.error("Error in submitUserDetails:", error);
    // }
  };

  const editMobileNumber = () => {
    setOtpVerfiyModel(false);
    // setMobileNumber("");
    setShowOtpField(false);
  };

  const handleChangeMobileNumber = (value) => {
    setMobileNumber(value);
    dispatchHealthQuickQuote("CustomerDetails.customerMobileNumber", value);

    if (value.length === 10) {
      setShowOtpField(true);
      PostDataWithToken("admin/checkCustomer", {
        phoneNumber: value,
      }).then((response) => {
        if (
          response?.data?.success === false &&
          response?.data?.message ===
            "Customer Does not exist with this Number!!"
        ) {
          const min = 1000000000000;
          const max = 9999999999999;
          const UniqueId = Math.floor(Math.random() * (max - min + 1) + min);
          const newUserData = {
            name: userName,
            // emailId: data?.Email,
            phoneNumber: value,
            customerUniqueNumber: UniqueId,
          };
          PostDataWithToken("admin/add_customer", newUserData).then(
            (response) => {
              dispatchProcessFlowHealth(
                "step3.customerDetails",
                response?.data
              );
              dispatchProcessFlowHealth(
                "customerDetails.FullName",
                response?.data?.customer_name
              );
              dispatchProcessFlowHealth(
                "customerDetails.Phone",
                response?.data?.customer_phone
              );
              dispatchProcessFlowHealth(
                "customerDetails.CustomerUniqueNumber",
                response?.data?.customer_unique_number
              );
              const newData = {
                ...processFlowObjHealth,
                step: "step3",
                step5: {
                  ...processFlowObjHealth.step3,
                  customerDetails: response?.data,
                },
              };

              processFlow({ processFlow: newData }).then((response) => {});
            }
          );
        } else if (
          response?.success === true &&
          response?.message === "Customer already Exists!!"
        ) {
          // dispatchProcessFlowMotor("step", "step5");
          dispatchProcessFlowHealth("step3.customerDetails", response?.data);
          dispatchProcessFlowHealth(
            "customerDetails.FullName",
            response?.data?.customer_name
          );
          dispatchProcessFlowHealth(
            "customerDetails.Phone",
            response?.data?.customer_phone
          );
          dispatchProcessFlowHealth(
            "customerDetails.CustomerUniqueNumber",
            response?.data?.customer_unique_number
          );

          const newData = {
            ...processFlowObjHealth,
            step: "step3",
            step5: {
              ...processFlowObjHealth.step3,
              customerDetails: response?.data,
            },
          };

          processFlow({ processFlow: newData }).then((response) => {});
        }
      });
    } else {
      setShowOtpField(false);
    }
  };

  const handleNoExistingDisease = (isChecked, value) => {
    if (isChecked) {
      setValue("NoExistingDesease", true);
    } else {
      setValue("NoExistingDesease", false);
    }
    setNoExistingDisease(value);
  };

  const [optVerfiyModel, setOtpVerfiyModel] = useState(false);

  const handlePreviousClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleUserName = (name) => {
    setUserName(name);
    const newNameData = {
      customerFirstName: "",
      customerMiddleName: "",
      customerLastName: "",
    };
    const nameParts = name.split(" ");
    if (nameParts.length > 0) {
      newNameData.customerFirstName = nameParts[0];
    }
    if (nameParts.length > 2) {
      newNameData.customerMiddleName = nameParts[1];
    }

    if (nameParts.length > 1) {
      newNameData.customerLastName = nameParts[nameParts.length - 1];
    }

    if (newNameData?.customerFirstName) {
      for (let key in newNameData) {
        dispatchHealthQuickQuote(`CustomerDetails.${key}`, newNameData[key]);
      }
    }
  };

  // --------------******************---------------------
  const submitQuoteDetails = (data) => {
    console.log("health data", data);
    let newData = { ...healthRequestQQ };
    dispatch(resetQuickQuoteResults());
    dispatchResetQuickQuote();
    // getQuoteCount().then((response) => {
    // if (
    //   response.success === true &&
    //   response.message === "Quote Count Fetched"
    // ) {
    //   if (response?.dataCount === 0) {
    //     const today = new Date().toLocaleDateString("en-GB");
    //     const [day, month, year] = today.split("/");

    //     const paddedDay = day.padStart(2, "0");
    //     const paddedMonth = month.padStart(2, "0");

    //     const formattedDate = paddedDay + paddedMonth + year;

    //     var newQuotationNumber = `INFST${formattedDate}0001`;

    //     dispatchHealthQuickQuote("quotationNumber", newQuotationNumber);
    //     dispatchProcessFlowHealth("quoteType", "health");
    //     dispatchProcessFlowHealth("quotationNumber", newQuotationNumber);
    //     dispatchProcessFlowHealth(
    //       "loggedInUser",
    //       userDetails?.Phone && userDetails?.CustomerUniqueNumber
    //         ? true
    //         : false
    //     );
    //     if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
    //       for (let key in userDetails) {
    //         dispatchProcessFlowHealth(
    //           `customerDetails.${key}`,
    //           userDetails[key]
    //         );
    //       }
    //     }
    //   } else {
    //     const newCount = response?.dataCount + 1;
    //     const today = new Date().toLocaleDateString("en-GB");
    //     const [day, month, year] = today.split("/");

    //     const paddedDay = day.padStart(2, "0");
    //     const paddedMonth = month.padStart(2, "0");

    //     const formattedDate = paddedDay + paddedMonth + year;

    //     const paddedCount = newCount.toString().padStart(4, "0");

    //     var newQuotationNumber = `INFST${formattedDate}${paddedCount}`;

    //     dispatchHealthQuickQuote("quotationNumber", newQuotationNumber);
    //     dispatchProcessFlowHealth("quoteType", "health");
    //     dispatchProcessFlowHealth("quotationNumber", newQuotationNumber);
    //     dispatchProcessFlowHealth(
    //       "loggedInUser",
    //       userDetails?.Phone && userDetails?.CustomerUniqueNumber
    //         ? true
    //         : false
    //     );
    //     if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
    //       for (let key in userDetails) {
    //         dispatchProcessFlowHealth(
    //           `customerDetails.${key}`,
    //           userDetails[key]
    //         );
    //       }
    //     }
    //   }
    // }
    dispatchProcessFlowHealth("step1", data);
    const newDataa = {
      ...processFlowObjHealth,
      // quotationNumber: newQuotationNumber,
      step: "step1",
      quoteType: "health",
      loggedInUser:
        userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false,
      step1: data,
    };
    processFlow(newDataa).then((response) => {
      console.log("RESPONSE", response);
    });
    // });
    // dispatchHealthQuickQuote("state", "health");
    // dispatchQuickQuote("state", "");
    for (let key in data) {
      const stateKey = `CustomerDetails.${key}`;
      newData = {
        ...newData,
        [stateKey]: data[key],
      };

      dispatchHealthQuickQuote(stateKey, data[key]);
      setCurrentStep(1);
    }
  };

  return (
    <Layout>
      <div className="container">
        <div class="health-main-page">
          <div className="health-profile-details">
            {currentStep === 0 && (
              <form
                className="w-100"
                onSubmit={handleSubmit(submitQuoteDetails)}
              >
                <div className="inputBox-motor inputBox-motor-inner row mt-3">
                  <div className="mb-4">
                    <h2>Secure Your Health in Minutes</h2>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-lg-6">
                    <div className="did-floating-label-content">
                      <div className="add-class">
                        {/* css-1n6nu92-control */}
                        <select
                          name="customerRelation"
                          {...register("customerRelation", {
                            required: "Field is required",
                          })}
                          className="form-select form-control-inner"
                          id="idxx2"
                        >
                          <option value="">For Whom</option>
                          <option value="Self">Self</option>
                          {/* <option value="Self">Spouse</option> */}
                          {/* <option value="Self">Son</option> */}
                          {/* <option value="Self">Daughter</option> */}
                          {/* <option value="Self">Mother</option> */}
                          {/* <option value="Self">Father</option> */}
                        </select>
                        {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerRelation
                      </label> */}

                        {errors?.customerRelation?.message && (
                          <span className="text-danger">
                            {errors?.customerRelation?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-lg-6">
                    <div className="did-floating-label-content">
                      <div className="add-class">
                        <select
                          name="customerGender"
                          {...register("customerGender", {
                            required: "Field is required",
                          })}
                          className="form-select form-control-inner"
                          id="idxx2"
                        >
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerGender
                      </label> */}

                        {errors?.customerGender?.message && (
                          <span className="text-danger">
                            {errors?.customerGender?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-lg-6">
                    <div className="did-floating-label-content">
                      <div
                        className="add-class"
                        // className="form-floating position-relative undefined me-1"
                        // style={{ position: "relative", height: "100%" }}
                      >
                        <select
                          name="customerAge"
                          {...register("customerAge", {
                            required: "Field is required",
                          })}
                          className="form-select form-control-inner"
                          id="idxx2"
                        >
                          <option value="">Age</option>
                          {age?.map((item) => {
                            return (
                              <option value={item}>
                                {item} {"Years"}
                              </option>
                            );
                          })}
                        </select>
                        {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerAge
                      </label> */}
                        {errors?.customerAge?.message && (
                          <span className="text-danger">
                            {errors?.customerAge?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 col-lg-6">
                    <div className="did-floating-label-content">
                      <div className="remove-class">
                        <ReactSelect
                          // className="form-select form-control-inner"
                          options={pincodeData}
                          onChange={(val) => handlePincodeChange(val)}
                          placeholder="Select  pincode"
                        />
                        {/* <label htmlFor="idxx4" className="did-floating-label">
                        Select area pincode
                      </label> */}
                      </div>
                    </div>
                  </div>

                  <div className="add-class">
                    <button
                      className="btn checkQuote checkQuote-health rounded"
                      type="submit"
                    >
                      Free Quote
                    </button>
                  </div>
                </div>
              </form>
            )}
            {currentStep === 1 && (
              <form
                className="w-100"
                onSubmit={handleSubmit(submitMedicalHistory)}
              >
                <div className="health-main-pera">
                  <div className="health-heading">
                    <div className="health-main-heading">
                      <h1>Medical History</h1>
                      <p>
                        Do you have an existing disease? If yes, then answer
                        below to help us find you the best plan that covers your
                        condition
                      </p>
                    </div>
                    <div className="health-heading-btn">
                      <div className="progress-wrapper">
                        <div className="progress-bar"></div>
                      </div>
                      <div className="progress-wrapper-button form-check">
                        <button type="button" className="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="Diabates"
                            {...register("Diabetes")}
                            disabled={noExistingDisease ? "None" : false}
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="Diabates"
                            className="form-check-label"
                          >
                            Diabates
                          </label>
                        </button>
                        <button type="button" className="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="hypertensionOrBloodPressure"
                            {...register("hypertensionOrBloodPressure")}
                            disabled={noExistingDisease ? "None" : false}
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="hypertensionOrBloodPressure"
                            className="form-check-label"
                          >
                            Blood pressure
                          </label>
                        </button>

                        <button type="button" className="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="surgeries"
                            {...register("surgeries")}
                            disabled={noExistingDisease ? "None" : false}
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="surgeries"
                            className="form-check-label"
                          >
                            Sugar
                          </label>
                        </button>
                        <button type="button" className="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="lungDeaseas"
                            {...register("lungDeaseas")}
                            disabled={noExistingDisease ? "None" : false}
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="lungDeaseas"
                            className="form-check-label"
                          >
                            Asthama
                          </label>
                        </button>
                        <button type="button" class="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="endoAndPituitaryDeasease"
                            {...register("endoAndPituitaryDeasease")}
                            disabled={noExistingDisease ? "None" : false}
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="endoAndPituitaryDeasease"
                            className="form-check-label"
                          >
                            Thyroid
                          </label>
                        </button>

                        <button type="button" className="btn-1">
                          <input
                            className="me-2 form-check-input d-none"
                            type="checkbox"
                            id="NoExistingDesease"
                            {...register("NoExistingDesease")}
                            onChange={(e) =>
                              handleNoExistingDisease(
                                e.target.checked,
                                e.target.checked ? "None" : ""
                              )
                            }
                            onClick={() => setDiseaseErrors("")}
                          />
                          <label
                            htmlFor="NoExistingDesease"
                            className="form-check-label"
                          >
                            No Disease
                          </label>
                        </button>
                      </div>
                      {diseaseErrors !== "" && (
                        <span className="text-danger">{diseaseErrors}</span>
                      )}
                    </div>
                    <div className="rto-sub">
                      <button type="submit" className="btn-primary">
                        Next
                      </button>
                    </div>
                  </div>
                  {/* <div class="health-img">
                    <img src="/assets/images/health-img.png" alt=""></img>
                  </div> */}
                </div>
              </form>
            )}
            {currentStep === 2 && (
              <form
                className="w-100 d-flex justify-content-center"
                onSubmit={handleSubmit1(submitUserDetails)}
              >
                <div className="health-main-pera">
                  <div className="health-heading">
                    <div className="health-main-heading">
                      <h1>Enter Your Personal Details</h1>
                      <p>
                        Enter Your Name and Phone Number to complete the profile
                      </p>
                    </div>
                    <div className="details-feild">
                      <div className="progress-wrapper">
                        <div className="progress-bar progress-bar-2"></div>
                      </div>
                      <div className="details-feild-inner">
                        <div className="did-floating-label-content">
                          <input
                            type="text"
                            className="did-floating-input w-100"
                            name="FullName"
                            {...register1("FullName", {
                              required: "Field is required",
                              minLength: {
                                value: 3,
                                message: "Please enter atleast 3 character",
                              },
                            })}
                            placeholder=""
                            onChange={(e) => handleUserName(e.target.value)}
                            maxLength={50}
                            onInput={(e) =>
                              (e.target.value = e.target.value.replace(
                                /\d/g,
                                ""
                              ))
                            }
                          />
                          <label htmlFor="name" className="did-floating-label">
                            Enter Full Name
                          </label>
                          {errors1?.FullName?.message && (
                            <span className="text-danger">
                              {errors1?.FullName?.message}
                            </span>
                          )}
                        </div>

                        <div className="did-floating-label-content">
                          <input
                            type="number"
                            name="mobileNumber"
                            {...register1("customerMobileNumber", {
                              required: "Field is required",
                              pattern: {
                                value:
                                  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                                message: "Invalid Mobile Number",
                              },
                            })}
                            className="did-floating-input w-100"
                            value={mobileNumber}
                            onChange={(e) => {
                              let value = e.target.value.trim();
                              if (
                                value.startsWith("0") ||
                                value.startsWith("1") ||
                                value.startsWith("2") ||
                                value.startsWith("3") ||
                                value.startsWith("4") ||
                                value.startsWith("5")
                              ) {
                                value = value.substring("1");
                              }
                              e.target.value = value;
                              handleChangeMobileNumber(e.target.value);
                            }}
                            onInput={(e) =>
                              (e.target.value = e.target.value.slice(0, 10))
                            }
                            placeholder=""
                          />
                          <label htmlFor="name" className="did-floating-label">
                            Enter Mobile Number
                          </label>
                          {errors1?.customerMobileNumber?.message && (
                            <span className="text-danger">
                              {errors1?.customerMobileNumber?.message}
                            </span>
                          )}
                        </div>
                        {/* {showOtpField && (
                      <button
                        type="button"
                        onClick={() => editMobileNumber()}
                        className="edit-button"
                      >
                        Edit
                      </button>
                    )} */}
                        {/* {showOtpField && (
                      <input
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                      />
                    )} */}
                      </div>
                    </div>

                    <div className="health-sub-btn d-flex align-items-center gap-5">
                      <button
                        // type="submit"
                        className=""
                        onClick={() => handlePreviousClick()}
                      >
                        <span>Previous</span>
                      </button>
                      <button
                        type=""
                        className=""
                        disabled={spinnerActive === true ? true : false}
                      >
                        {spinnerActive === true ? (
                          <div class="spinner-border text-light" role="status">
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <span>Continue</span>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* <div class="health-img">
                    <img src="/assets/images/health-img.png" alt="" />
                  </div> */}
                </div>
              </form>
            )}
          </div>
          <div class="health-img">
            <img src="/assets/images/health-img.png" alt="" />
          </div>
        </div>
      </div>

      <WhyChoose />
      <AboutHealth />
      {/* <Footer /> */}
      {optVerfiyModel && (
        <OtpModel
          show={optVerfiyModel}
          setModel={setOtpVerfiyModel}
          resendObj={resendObj}
          editMobileNumber={editMobileNumber}
          mobileNumber={mobileNumber}
        />
      )}
    </Layout>
  );
};

export default Health;
