import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { GetDataWithToken, PostDataWithToken } from "../../api/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchUserDetails,
  resetQuickQuoteResults,
  resetUserDetails,
} from "../../store/action/userActions";
import {
  failedUpdateMsg,
  sendSaveUserMessage,
} from "../services/masterServices";
import { FaFemale, FaHome } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
// import { MdOutlinePolicy, MdPolicy } from "react-icons/md";
import { MdOutlineLocalPolice } from "react-icons/md";
import { MdPolicy } from "react-icons/md";
import { LuBaggageClaim } from "react-icons/lu";

import { AiOutlineTransaction } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import LogoutModal from "./LogoutModal";
import { MDBDataTable, MDBIcon } from "mdbreact";
import { getPreviousUserDetails } from "../services/TPApiCall.js";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";

const MyProfile = () => {
  const [avatar, setAvatar] = useState();
  const userDetails = useSelector((state) => state?.root?.userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mySideBar, setSideBar] = useState("myProfile");
  const location = useLocation();
  const [sideNav, setSideNav] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [customerPolicyData, setCustomerPolicyData] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [defaultDate, setDefaultDate] = useState(null);
  const [customerQuotationData, setCustomerQuotationData] = useState([]);
  const [stepsData, setStepsData] = useState({});
  const [dob, setDob] = useState(defaultDate ? new Date(defaultDate) : null);
  console.log("LOCATION", location);
  useEffect(() => {
    if (location?.state === null) {
      setSideBar("myProfile");
    } else {
      setSideBar(location?.state);
    }
  }, []);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    reset,
    watch,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    setValue("phone", userDetails?.Phone || userDetails?.mobile_no);
    setValue("email", userDetails?.Email || userDetails?.email);
    setValue("name", userDetails?.FullName || userDetails?.name);
    setValue("gender", userDetails?.Gender || userDetails?.gender);
  }, []);

  function formatDate(inputDate) {
    const date = moment(
      inputDate,
      ["YYYY-MM-DD", "MM/DD/YYYY", "YYYY/MM/DD", "DD/MM/YYYY"],
      true
    );
    if (date.isValid()) {
      return date.format("YYYY-MM-DD");
    } else {
      return "Invalid Date";
    }
  }

  useEffect(() => {
    console.log("userDetails?.DateofBirth", userDetails?.DateofBirth);
    const formattedDate = formatDate(
      userDetails?.DateofBirth || userDetails?.dob
    );
    console.log("formattedDate", formattedDate);
    setDefaultDate(formattedDate);
    setDob(formattedDate);
  }, [userDetails]);

  const [dataTable, setDataTable] = useState({
    columns: [
      {
        label: "Quote No.",
        field: "quoteNo",
        sort: "asc",
        width: 200,
      },
      {
        label: "Case Type",
        field: "caseType",
        sort: "asc",
        width: 120,
      },
      {
        label: "Product Type",
        field: "productType",
        sort: "asc",
        width: 200,
      },
      {
        label: "Policy No.",
        field: "policyNum",
        sort: "asc",
        width: 110,
      },
      {
        label: "Insurer Name",
        field: "insurerName",
        sort: "asc",
        width: 250,
      },
      {
        label: "Vehicle No.",
        field: "vehicleNum",
        sort: "asc",
        width: 250,
      },
      // {
      //   label: "Customer Name",
      //   field: "customerName",
      //   sort: "asc",
      //   width: 250,
      // },
      {
        label: "Start Date",
        field: "policyStartDate",
        sort: "asc",
        width: 250,
      },
      {
        label: "End Date",
        field: "policyEndDate",
        sort: "asc",
        width: 250,
      },
      {
        label: "Issue Date",
        field: "policyIssueDate",
        sort: "asc",
        width: 250,
      },
      {
        label: "Premium Amount",
        field: "premiumAmount",
        sort: "asc",
        width: 250,
      },
      {
        label: "Pdf",
        field: "downloadPdf",
        sort: "asc",
        width: 100,
      },
    ],
    row: [],
  });

  const [quotationDataTable, setQuotationDataTable] = useState({
    columns: [
      {
        label: "Serial No.",
        field: "serialNo",
        sort: "asc",
        width: 100,
      },
      {
        label: "Quotation No.",
        field: "quotationNo",
        sort: "asc",
        width: 100,
      },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 100,
      },
      {
        label: "Customer Email",
        field: "customerEmail",
        sort: "asc",
        width: 100,
      },
      {
        label: "Last Step",
        field: "lastStep",
        sort: "asc",
        width: 100,
      },
      {
        label: "Redirect",
        field: "proceed",
        sort: "asc",
        width: 100,
      },
    ],
    row: [],
  });

  useEffect(() => {
    const fetchCustomerPolicyData = async () => {
      if (mySideBar === "myPolicies") {
        try {
          const response = await GetDataWithToken(
            `admin/get_cust_policy/${userDetails?.CustomerUniqueNumber}`
          );

          console.log("customerPolicy Response", response);
          console.log("customerPolicy Response", response.data.length);
          handleSetDataTable(response.data);

          if (response?.data?.length !== 0) {
            setCustomerPolicyData(response?.data);
          }
        } catch (error) {
          console.log("Error fetching customer policy:", error);
        }
      }
    };
    fetchCustomerPolicyData();
  }, [mySideBar === "myPolicies"]);

  useEffect(() => {
    const fetchCustomerQuotationData = async () => {
      if (mySideBar === "myQuotations") {
        const data = {
          userType: "user",
          customerUniqueId: userDetails?.CustomerUniqueNumber,
        };
        try {
          const response = await PostDataWithToken(`admin/allQuotation`, data);

          console.log("customerPolicy Response", response);
          console.log("customerPolicy Response", response.data.length);
          handleSetQuotationDataTable(response.data);

          if (response?.data?.length !== 0) {
            setCustomerQuotationData(response?.data);
          }
        } catch (error) {
          console.log("Error fetching customer policy:", error);
        }
      }
    };
    fetchCustomerQuotationData();
  }, [mySideBar === "myQuotations"]);

  const handlePdfDownload = (data) => {
    console.log("handlePdfDownload", data);
    window.open(data, "_blank");
  };

  const handleSetDataTable = (table) => {
    console.log("table=", table);
    let tableValue = [];
    table.map((data) => {
      console.log("data in table rows", data);
      return tableValue.push({
        quoteNo: (
          <span
            className="badge bg-success rounded-pill text-capitalize"
            style={{ cursor: "pointer" }}
          >
            {data?.quotationNumber}
          </span>
        ),
        caseType: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {data?.policyType}
          </span>
        ),
        productType: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {data?.productType}
          </span>
        ),
        policyNum: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {data?.policyNumber}
          </span>
        ),
        insurerName: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {data?.policyInsurer}
          </span>
        ),
        vehicleNum: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {data?.vehicleNumber}
          </span>
        ),
        // customerName: (
        //   <span
        //     className="badge rounded-pill text-capitalize"
        //     style={{ cursor: "pointer", color: "black" }}
        //   >
        //     {typeof data?.customerDetails === "string"
        //       ? JSON.parse(data?.customerDetails).customer_name
        //       : data?.customerDetails?.customer_name}
        //   </span>
        // ),
        policyStartDate: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {(data?.policyStartDate).replace(/"/g, "")}
          </span>
        ),
        policyEndDate: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {(data?.policyEndDate).replace(/"/g, "")}
          </span>
        ),
        policyIssueDate: (
          <span
            className="badge rounded-pill text-capitalize"
            style={{ cursor: "pointer", color: "black" }}
          >
            {(data?.policyIssueDate).replace(/"/g, "")}
          </span>
        ),
        premiumAmount: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span
              className="badge rounded-pill text-capitalize"
              style={{ cursor: "pointer", color: "black" }}
            >
              Net Premium: {data?.netPremium}
            </span>
            <span
              className="badge rounded-pill text-capitalize"
              style={{ cursor: "pointer", color: "black" }}
            >
              Gross Premium: {data?.grossPremium}
            </span>
          </div>
        ),
        downloadPdf: (
          <button
            onClick={() => handlePdfDownload(data?.pdfUrl)}
            className="badge rounded-pill text-capitalize"
            style={{
              cursor: "pointer",
              color: "black",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              <MDBIcon className="ms-1" icon="download" size="sm" />
            </span>
          </button>
        ),
      });
    });
    setDataTable({
      ...dataTable,
      rows: tableValue,
    });
  };

  const handleSetQuotationDataTable = (customerData) => {
    let tableValue = [];
    customerData.map((data) => {
      let stepsData = data.stepsData;
      if (typeof stepsData === "string") {
        stepsData = JSON.parse(stepsData);
      }
      return tableValue.push({
        serialNo: data?.id,
        quotationNo: <>{data?.quotationId}</>,
        customerName: (
          <>
            {stepsData?.processFlow?.step5?.customerDetails?.customer_name ||
              data?.stepsData?.step5?.customerDetails?.customer_name}
          </>
        ),
        customerEmail: (
          <>
            {stepsData?.processFlow?.step5?.customerDetails?.customer_email ||
              data?.stepsData?.step5?.customerDetails?.customer_email}
          </>
        ),
        lastStep: <>{data?.ProcessDiscription}</>,
        proceed: (
          <>
            <button
              style={{
                border: "none",
                borderRadius: "1rem",
                padding: "0.3rem 1rem",
                background: "#56b96c8f",
                color: "#fff",
              }}
              onClick={() => handleProceedButton(data?.quotationId)}
            >
              Proceed
            </button>
          </>
        ),
      });
    });
    console.log("TABLE VALUES", tableValue);
    setQuotationDataTable({
      ...quotationDataTable,
      rows: tableValue,
    });
  };

  const handleContinueJourney = () => {
    console.log("handleContinueJourney", stepsData);
    const stepData = stepsData?.processFlow;
    const requestData = { ...stepData.apiRequestQQ };

    // const ApiUniqueNumber = { ...stepData.apiRequestQQ.ApiUniqueNumber };
    // const KYC = { ...stepData.apiRequestQQ.KYC };
    // const VariantCode = { ...stepData.apiRequestQQ.VariantCode };
    console.log("handleContinueJourney", stepData, stepData?.apiRequestQQ);
    // console.log("handleContinueJourney", requestData);

    // console.log("handleContinueJourney", ApiUniqueNumber);
    // console.log("handleContinueJourney", KYC);
    // console.log("handleContinueJourney", VariantCode);
    // setQueryStringType(true);
    for (let key in stepData?.apiRequestQQ) {
      if (key === "VariantCode") {
        for (let key in stepData?.apiRequestQQ?.VariantCode) {
          dispatchQuickQuote(
            `VariantCode.${key}`,
            stepData?.apiRequestQQ?.VariantCode[key]
          );
        }
      } else if (key === "ApiUniqueNumber") {
        for (let key in stepData?.apiRequestQQ?.ApiUniqueNumber) {
          dispatchQuickQuote(
            `ApiUniqueNumber.${key}`,
            stepData?.apiRequestQQ?.ApiUniqueNumber[key]
          );
        }
      } else if (key === "KYC") {
        for (let key in stepData?.apiRequestQQ?.KYC) {
          dispatchQuickQuote(`KYC.${key}`, stepData?.apiRequestQQ?.KYC[key]);
        }
      } else {
        console.log(`${key}`, stepData?.apiRequestQQ[key]);
        dispatchQuickQuote(`${key}`, stepData?.apiRequestQQ[key]);
      }
    }
    // for (let key in VariantCode) {
    //   console.log(`${key}`, stepData?.apiRequestQQ[key]);
    //   dispatchQuickQuote(`VariantCode.${key}`, stepData?.apiRequestQQ[key]);
    // }
    if (stepData?.step4?.variantSelection?.Cubic_Capacity !== "") {
      dispatchQuickQuote(
        "CubicCapacity",
        stepData?.step4?.variantSelection?.Cubic_Capacity
      );
      dispatchQuickQuote(
        "FuelType",
        stepData?.step4?.variantSelection?.Fuel_Type
      );
      dispatchQuickQuote(
        "VariantName",
        stepData?.step4?.variantSelection?.label
      );
    }
    if (stepData?.step5?.customerDetails?.customer_name) {
      const nameParts =
        stepData?.step5?.customerDetails?.customer_name.split(" ");
      console.log("firstName", nameParts);
      if (nameParts.length > 2) {
        dispatchQuickQuote("FirstName", nameParts[0]);
        dispatchQuickQuote("MiddleName", nameParts[1]);
        dispatchQuickQuote("LastName", nameParts[2]);
      } else {
        dispatchQuickQuote("FirstName", nameParts[0]);
        dispatchQuickQuote("LastName", nameParts[1]);
      }
      dispatchQuickQuote(
        "MobileNumber",
        stepData?.step5?.customerDetails?.customer_phone
      );
      dispatchQuickQuote(
        "Email",
        stepData?.step5?.customerDetails?.customer_email
      );
      dispatchQuickQuote("CustomerType", requestData?.CustomerType);
      dispatchQuickQuote(
        "PreviousOwnerChanged",
        requestData?.PreviousOwnerChanged
      );
    }
    console.log("stepData?.step6?.pypSelection", stepData?.step6?.pypSelection);
    if (
      stepData &&
      stepData?.step6?.pypSelection &&
      Object.keys(stepData?.step6?.pypSelection)
    ) {
      for (let key in stepData?.step6?.pypSelection) {
        console.log(`${key}`, stepData?.step6?.pypSelection[key]);
        dispatchQuickQuote(`${key}`, stepData?.step6?.pypSelection[key]);
      }
    }
    if (stepData?.step === "step1") {
      console.log("stepDAta", stepData);
      dispatchQuickQuote("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );

      navigate("/motor", {
        state: {
          activeStep: 2,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
        },
      });
    } else if (stepData?.step === "step2") {
      console.log("stepDAta", stepData);
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);

      navigate("/motor", {
        state: {
          activeStep: 3,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step3") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);

      navigate("/motor", {
        state: {
          activeStep: 4,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step4") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );

      navigate("/motor", {
        state: {
          activeStep: 6,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step5") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );

      navigate("/motor", {
        state: {
          activeStep: 8,
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step6") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else if (stepData?.step === "step7") {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchQuickQuote("NCBSET", false);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );
      dispatch(resetQuickQuoteResults());

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    } else {
      dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
      dispatchQuickQuote("NCBSET", false);
      dispatchProcessFlowMotor(
        "step1.registerationNumber",
        stepData.step1.registerationNumber
      );
      dispatchProcessFlowMotor("step2.make", stepData.step2.make);
      dispatchProcessFlowMotor("step3.model", stepData.step3.model);
      dispatchProcessFlowMotor(
        "step4.variantSelection",
        stepData.step4.variantSelection
      );
      dispatchProcessFlowMotor(
        "step5.customerDetails",
        stepData.step5.customerDetails
      );
      dispatchProcessFlowMotor(
        "step6.pypSelection",
        stepData.step6.pypSelection
      );
      dispatch(resetQuickQuoteResults());

      navigate("/quotelist", {
        state: {
          journeyContinue: true,
          vehicleModal: requestData?.vehicleModal,
          vehicleType: requestData?.VehicleType,
          requestData: requestData,
        },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(stepsData).length > 0) {
      handleContinueJourney();
    }
  }, [stepsData]);

  const handleProceedButton = (data) => {
    console.log("PROCEED BUTTON DATA", data);
    getPreviousUserDetails({ quotationNumber: data }).then((response) => {
      console.log("RESPONSE", response);
      if (
        response?.success &&
        response?.message === "Data for customer process!!"
      ) {
        const stepsData = JSON.parse(response?.data?.stepsData);
        console.log("stepsData", stepsData);
        setStepsData(stepsData);
      }
    });
  };

  console.log("DATA TABLE", dataTable);

  const updateProfile = async (data) => {
    console.log("update profile data", data);
    dispatchUserDetails(
      "FullName",
      data?.name ? data?.name : userDetails?.FullName
    );
    dispatchUserDetails(
      "Gender",
      data?.gender ? data?.gender : userDetails?.Gender
    );
    dispatchUserDetails(
      "Email",
      data?.email ? data?.email : userDetails?.Email
    );
    dispatchUserDetails("Phone", data?.phone);
    dispatchUserDetails(
      "MaritalStatus",
      data?.maritalStatus ? data?.maritalStatus : userDetails?.MaritalStatus
    );
    dispatchUserDetails(
      "DateofBirth",
      data?.DateOfBirth ? data?.DateOfBirth : userDetails?.DateofBirth
    );
    dispatchUserDetails("City", data?.City ? data?.City : userDetails?.City);

    const postData = {
      customerName: data?.name,
      customerGender: data?.gender,
      customerBody: data?.email ? data?.email : userDetails?.Email,
      customerDob: data?.DateOfBirth
        ? data?.DateOfBirth
        : userDetails?.DateofBirth,
      maritalStatus: data?.maritalStatus
        ? data?.maritalStatus
        : userDetails?.MaritalStatus,
      city: data?.City,

      customerUniqueNumber:
        userDetails?.customerUniqueNumber || userDetails?.CustomerUniqueNumber,
    };
    console.log("postData", postData);
    try {
      const response = await PostDataWithToken(
        "admin/update_customer",
        postData
      );
      console.log(response, "response update");
      if (
        response?.success === true &&
        response?.message === "User Updated Successfully!!"
      ) {
        sendSaveUserMessage();
        navigate("/");
      } else {
        failedUpdateMsg(response?.data?.message);
      }
      // dispatchUserDetails('FullName', response)
    } catch (err) {
      console.log(err);
    }
  };

  const handleSideBarClick = () => {
    if (isMobile) {
      setSideNav(!sideNav);
    }
  };

  console.log(moment(userDetails?.DateofBirth).format(""), "Moment Date");

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return "";

    const [day, month, year] = dateString.split("/");

    return `${year}-${month}-${day}`;
  };

  // Handle date change from DatePicker
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setDob(date);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", formattedDate);
    } else {
      setDob(null);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", "");
    }
  };

  // Handle manual input change with mask
  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setDob(date);
        clearErrors("DateOfBirth");
        setValue("DateOfBirth", formattedDate);
      }
    } else {
      setDob(null);
      clearErrors("DateOfBirth");
      setValue("DateOfBirth", "");
    }
  };

  return (
    <>
      <div className="app">
        <div className="dashBoard_overLay"></div>
        {/* <div className="layout">
          <div className="dash_header">
            <div className="nav_dash_wrap">
              <div className="nav_dash_wrpLeft">
                <a
                  href="javascript:void(0);"
                  className="dashIconFold"
                  id="foldBtn"
                >
                  <div className="notFolded">
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      className=""
                      data-icon="menu-fold"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z"></path>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>

         
        </div> */}
      </div>
      {/* SideNav, Top Right Section, Pages other than profile */}
      <div className="layout">
        <div className={`dash_header ${sideNav ? "open" : ""}`}>
          <div className="nav_dash_wrap ">
            <div className="nav_dash_wrpLeft">
              <a
                // href="javascript:void(0);"
                className="dashIconFold"
                onClick={() => setSideNav(!sideNav)}
                id="foldBtn"
              >
                <div className="notFolded">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="menu-fold"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 0 0 0 13.8z"></path>
                  </svg>
                </div>

                <div className="folded ">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="menu-unfold"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"></path>
                  </svg>
                </div>
              </a>
            </div>
            {/* Navbar Top Right Section */}
            <div className="nav_dash_wrpRight">
              <span className="d-bock d-md-none d-lg-none">
                {/* <img
                  src="/assets/images/insurefastlogo.png"
                  alt=""
                  onClick={() => navigate("/")}
                /> */}
                <img
                  class="logo_fold"
                  src="/logo.ico"
                  alt=""
                  onClick={() => navigate("/")}
                />
              </span>
              <a
                href="javascript:void(0);"
                className="nav_right_msg rightMenuBtn mr-15"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <g opacity="0.6">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03972 17.8477C7.3093 17.623 7.6491 17.5 8 17.5H19C19.8284 17.5 20.5 16.8284 20.5 16V6C20.5 5.17157 19.8284 4.5 19 4.5H5C4.17157 4.5 3.5 5.17157 3.5 6V19.7299C3.5 20.1538 3.99443 20.3854 4.32009 20.114L7.03972 17.8477ZM8 19H19C20.6569 19 22 17.6569 22 16V6C22 4.34315 20.6569 3 19 3H5C3.34315 3 2 4.34315 2 6V19.7299C2 21.4256 3.97771 22.3519 5.28037 21.2664L8 19Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H16C16.4142 8.25 16.75 8.58579 16.75 9C16.75 9.41421 16.4142 9.75 16 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
                      fill="black"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H12C12.4142 12.25 12.75 12.5858 12.75 13C12.75 13.4142 12.4142 13.75 12 13.75H8C7.58579 13.75 7.25 13.4142 7.25 13Z"
                      fill="black"
                    />
                  </g>
                </svg>
              </a>
              {/* Notification */}
              <div className="nav_right_notification dropdown mr-15">
                <a
                  href="javascript:void(0);"
                  className="rightMenuBtn newNotiMsg"
                  data-bs-toggle="dropdown"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g opacity="0.6">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 18H20.5858C21.3668 18 22 17.3668 22 16.5858C22 16.2107 21.851 15.851 21.5858 15.5858L20.5858 14.5858C20.2107 14.2107 20 13.702 20 13.1716L20 9.97067C20 5.56859 16.4314 2 12.0293 2C7.61789 2 4.04543 5.58319 4.05867 9.9946L4.06814 13.1476C4.06977 13.6922 3.84928 14.2139 3.45759 14.5922L2.42864 15.586C2.15471 15.8506 2 16.2151 2 16.5959C2 17.3714 2.62863 18 3.40408 18H8C8 20.2091 9.79086 22 12 22C14.2091 22 16 20.2091 16 18ZM19.5251 15.6464L20.3787 16.5H3.64147L4.49967 15.6711C5.18513 15.009 5.57099 14.0961 5.56813 13.1431L5.55867 9.9901C5.54792 6.40887 8.44807 3.5 12.0293 3.5C15.603 3.5 18.5 6.39702 18.5 9.97067L18.5 13.1716C18.5 14.0998 18.8687 14.9901 19.5251 15.6464ZM14.5 18H9.5C9.5 19.3807 10.6193 20.5 12 20.5C13.3807 20.5 14.5 19.3807 14.5 18Z"
                        fill="black"
                      />
                    </g>
                  </svg>
                </a>
                {/* <div className="dropdown-menu dropdown-menu-end">
                  <div className="notiHeader_top">
                    <div className="notiHeaderTopHeading">
                      <i className="fal fa-bell"></i>
                      <span className="">Notification</span>
                    </div>
                    <a className="viewBtn" href="javascript:void(0);">
                      <small>View All</small>
                    </a>
                  </div>
                  <ul className="notificationListDrop">
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" className="dropdown-item">
                        <div className="notiListCard">
                          <div className="notiListImg">
                            <img src="./img/dash-userimg.jpg" alt="" />
                          </div>
                          <div className="notiListContent">
                            <p>
                              Channel: <span>Social Relationship</span> request
                              to join has be accepted.
                            </p>
                            <small>04 April, 2021 | 04:00 PM</small>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Side Bar */}
        <div className={`side_nav ${sideNav ? "open" : ""}`}>
          <div class="dash_logo">
            <a href="javascript:void(0);">
              <img
                src="/assets/images/insurefastlogo.png"
                alt=""
                onClick={() => navigate("/")}
              />
              <img
                class="logo_fold"
                src="/logo.ico"
                alt=""
                onClick={() => navigate("/")}
              />
            </a>
          </div>
          <div className="side_nav_inner">
            <ul className="sideNav_menu">
              {/* My Profile */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myProfile");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myProfile" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/profile.png"></img>
                  </span>
                  <span className="title_dash_nav">My Profile</span>
                </a>
              </li>
              {/* My Policies */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myPolicies");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myPolicies" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/policy.png"></img>
                  </span>
                  <span className="title_dash_nav">My Policies</span>
                </a>
              </li>
              {/* My Quotations */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myQuotations");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myQuotations" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/quotation.png"></img>
                  </span>
                  <span className="title_dash_nav">My Quotations</span>
                </a>
              </li>
              {/* Claim Support */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("claimSupport");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "claimSupport" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/claim (1).png"></img>
                  </span>
                  <span className="title_dash_nav">Claim Support</span>
                </a>
              </li>
              {/* My Transactions */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setSideBar("myTransaction");
                  handleSideBarClick();
                }}
              >
                <a
                  href="javascript:void(0);"
                  className={mySideBar === "myTransaction" ? "active" : ""}
                >
                  <span className="icon_holder">
                    <img src="/assets/images/rupee.png"></img>
                  </span>
                  <span className="title_dash_nav">My Transaction</span>
                </a>
              </li>
              {/* Logout */}
              <li
                className="dash_nav_item"
                onClick={() => {
                  setLogoutModal(true);
                }}
              >
                <a href="javascript:void(0);">
                  <span className="icon_holder">
                    <img src="/assets/images/sign-out.png"></img>
                  </span>
                  <span className="title_dash_nav">Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        {/* Profile Section */}
        {mySideBar === "myProfile" && (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div className="main_content">
              <div className="row walletSearchRow">
                <div className="col-12">
                  <div className="dashboardInner_box">
                    <div className="profile_detail">
                      <div className="section_block">
                        <h2 className="sectionHeading">My Profile</h2>
                      </div>
                      <div className="profile_avatar">
                        <div className="avatar_img">
                          <img
                            // src={
                            //   watch("gender") === "male"
                            //     ? "/assets/images/male icon.png"
                            //     : "/assets/images/female icon.png"
                            // }
                            src={
                              userDetails?.Gender === "Male"
                                ? "/assets/images/male icon (1).png"
                                : userDetails?.Gender === "Female"
                                ? "/assets/images/female icon .png"
                                : userDetails?.Gender === null
                                ? watch("gender") === "Male"
                                  ? "/assets/images/male icon.png"
                                  : "/assets/images/female icon.png"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      {/* Profile Tab */}
                      {defaultDate !== null && (
                        <div className="profile-tab">
                          <div className="profile-pills">
                            <ul
                              className="nav nav-pills"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-home"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  Personal Detail
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link"
                                  id="pills-profile-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-profile"
                                  type="button"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                >
                                  Contact Detail
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="tab-content" id="pills-tabContent">
                            {/* Profile Section tab */}
                            <div
                              className="tab-pane fade show active"
                              id="pills-home"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                              tabindex="0"
                            >
                              <div className="profile_info">
                                {
                                  <form
                                    className=""
                                    onSubmit={handleSubmit(updateProfile)}
                                  >
                                    <div className=" w-100 mt-0">
                                      <div className="profile_details">
                                        <div className="mt-3">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-4 mb-md-4">
                                                <div className="profile_gender mt-0">
                                                  <div className="d-flex yesnoinput">
                                                    {userDetails?.Gender ===
                                                    "Male" ? (
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          className="d-none"
                                                          id="male"
                                                          name="gender"
                                                          value={"Male"}
                                                          // {...register("gender")}
                                                          checked={
                                                            userDetails?.Gender ===
                                                            "Male"
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label htmlFor="male">
                                                          <span className="user_img">
                                                            <img src="/assets/images/male-user.svg"></img>
                                                          </span>
                                                          Male
                                                        </label>
                                                      </div>
                                                    ) : userDetails?.Gender ===
                                                      "Female" ? (
                                                      <div>
                                                        <input
                                                          type="radio"
                                                          className="d-none"
                                                          id="female"
                                                          name="gender"
                                                          value={"Female"}
                                                          checked={
                                                            userDetails?.Gender ===
                                                            "Female"
                                                              ? true
                                                              : false
                                                          }
                                                          // {...register("gender")}
                                                        />
                                                        <label htmlFor="female">
                                                          <span className="user_img">
                                                            <img src="/assets/images/male-user.svg"></img>
                                                          </span>
                                                          Female
                                                        </label>
                                                      </div>
                                                    ) : (
                                                      <>
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            className="d-none"
                                                            {...register(
                                                              "gender"
                                                            )}
                                                            id="male"
                                                            name="gender"
                                                            value={"Male"}
                                                            // {...register("gender")}
                                                            // checked={
                                                            //   userDetails?.Gender ===
                                                            //   "Male"
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                          />
                                                          <label htmlFor="male">
                                                            <span className="user_img">
                                                              <img src="/assets/images/male-user.svg"></img>
                                                            </span>
                                                            Male
                                                          </label>
                                                        </div>
                                                        <div>
                                                          <input
                                                            type="radio"
                                                            className="d-none"
                                                            {...register(
                                                              "gender"
                                                            )}
                                                            id="female"
                                                            name="gender"
                                                            value={"Female"}
                                                            // checked={
                                                            //   userDetails?.Gender ===
                                                            //   "Female"
                                                            //     ? true
                                                            //     : false
                                                            // }
                                                            // {...register("gender")}
                                                          />
                                                          <label htmlFor="female">
                                                            <span className="user_img">
                                                              <img src="/assets/images/male-user.svg"></img>
                                                            </span>
                                                            Female
                                                          </label>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="text"
                                                    className="did-floating-input"
                                                    {...register("name")}
                                                    id="name"
                                                    placeholder=" "
                                                    name="name"
                                                    defaultValue={
                                                      userDetails?.FullName
                                                    }
                                                    onInput={(e) =>
                                                      (e.target.value =
                                                        e.target.value.replace(
                                                          /[0-9!@#\$%\^\&*\)\(+=._-]+$/g,
                                                          ""
                                                        ))
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="name"
                                                    className="did-floating-label"
                                                  >
                                                    Full Name
                                                  </label>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <DatePicker
                                                    selected={dob}
                                                    onChange={handleDateChange}
                                                    customInput={
                                                      <ReactInputMask
                                                        mask="99/99/9999"
                                                        placeholder="DD/MM/YYYY"
                                                        value={
                                                          dob
                                                            ? moment(
                                                                dob
                                                              ).format(
                                                                "DD/MM/YYYY"
                                                              )
                                                            : ""
                                                        }
                                                        onChange={
                                                          handleInputChange
                                                        }
                                                        alwaysShowMask={false}
                                                      />
                                                    }
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Select Date of Birth"
                                                    className="did-floating-input"
                                                  />
                                                  <label
                                                    htmlFor="dob"
                                                    className="did-floating-label"
                                                  >
                                                    Date of Birth
                                                  </label>
                                                  {errors?.DateOfBirth && (
                                                    <p className="f-error text-danger">
                                                      {
                                                        errors.DateOfBirth
                                                          .message
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <select
                                                    className=" form-select text-truncate"
                                                    {...register(
                                                      "maritalStatus",
                                                      {
                                                        required:
                                                          "Field is required",
                                                      }
                                                    )}
                                                  >
                                                    <option value="">
                                                      Select Marital Status
                                                    </option>
                                                    <option
                                                      selected={
                                                        userDetails?.MaritalStatus ===
                                                        "Single"
                                                          ? true
                                                          : false
                                                      }
                                                      value={"Single"}
                                                    >
                                                      Single
                                                    </option>
                                                    <option
                                                      selected={
                                                        userDetails?.MaritalStatus ===
                                                        "Married"
                                                          ? true
                                                          : false
                                                      }
                                                      value={"Married"}
                                                    >
                                                      Married
                                                    </option>
                                                  </select>
                                                  <label
                                                    htmlFor="maritalStatus"
                                                    className="did-floating-label"
                                                  >
                                                    Marital Status
                                                  </label>
                                                  {errors?.maritalStatus && (
                                                    <span className="text-danger">
                                                      {
                                                        errors?.maritalStatus
                                                          ?.message
                                                      }
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="text"
                                                    className="did-floating-input"
                                                    {...register("City", {
                                                      required:
                                                        "Field is required",
                                                    })}
                                                    id="City"
                                                    onInput={(e) => {
                                                      e.target.value =
                                                        e.target.value.replace(
                                                          /[0-9!@#\$%\^\&*\)\(+=._-]+$/g,
                                                          ""
                                                        );
                                                    }}
                                                    maxLength={20}
                                                    placeholder=" "
                                                    name="City"
                                                    defaultValue={
                                                      userDetails?.City
                                                    }
                                                  />
                                                  <label
                                                    htmlFor="City"
                                                    className="did-floating-label"
                                                  >
                                                    City
                                                  </label>
                                                  {errors?.City && (
                                                    <span className="text-danger">
                                                      {errors?.City?.message}
                                                    </span>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {!userDetails?.username && (
                                        <div className="profile_btn_div ">
                                          <button
                                            className="profile_btn"
                                            type="submit"
                                          >
                                            Save
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  </form>
                                }
                              </div>
                            </div>
                            {/* Contact Details tab */}
                            <div
                              className="tab-pane fade"
                              id="pills-profile"
                              role="tabpanel"
                              aria-labelledby="pills-profile-tab"
                              tabindex="0"
                            >
                              <div className="profile_info">
                                {
                                  <form
                                    className=""
                                    onSubmit={handleSubmit(updateProfile)}
                                  >
                                    <div className="w-100 mt-0">
                                      <div className="profile_details">
                                        <div className="mt-3">
                                          <div className="row">
                                            <div className="col-md-12">
                                              <div className="mb-4 mb-md-4">
                                                {/* <div className="profile_gender mt-0">
                                                  <div className="d-flex yesnoinput">
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        className="d-none"
                                                        id="male"
                                                        name="gender"
                                                        value={"male"}
                                                        // {...register("gender")}
                                                        checked={
                                                          userDetails?.Gender ===
                                                          "Male"
                                                            ? true
                                                            : false
                                                        }
                                                      />
                                                      <label htmlFor="male">
                                                        <span className="user_img">
                                                          <img src="/assets/images/male-user.svg"></img>
                                                        </span>
                                                        Male
                                                      </label>
                                                    </div>
                                                    <div>
                                                      <input
                                                        type="radio"
                                                        className="d-none"
                                                        id="female"
                                                        name="gender"
                                                        value={"female"}
                                                        checked={
                                                          userDetails?.Gender ===
                                                          "Female"
                                                            ? true
                                                            : false
                                                        }
                                                        // {...register("gender")}
                                                      />
                                                      <label htmlFor="female">
                                                        <span className="user_img">
                                                          <img src="/assets/images/male-user.svg"></img>
                                                        </span>
                                                        Female
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div> */}
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="number"
                                                    {...register("phone")}
                                                    className="did-floating-input"
                                                    id="phone"
                                                    name="phone"
                                                    defaultValue={
                                                      userDetails?.Phone
                                                    }
                                                    disabled
                                                    placeholder="Enter Mobile Number"
                                                  />
                                                  <label
                                                    htmlFor="phone"
                                                    className="did-floating-label"
                                                  >
                                                    Mobile No.
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6">
                                              <div className="mb-4">
                                                <div className="did-floating-label-content">
                                                  <input
                                                    type="email"
                                                    className="did-floating-input"
                                                    {...register("email")}
                                                    id="email"
                                                    placeholder=" "
                                                    name="email"
                                                    defaultValue={
                                                      userDetails?.Email
                                                    }
                                                    maxLength={100}
                                                    onInput={(e) => {
                                                      let inputValue =
                                                        e.target.value;

                                                      if (
                                                        inputValue.includes(
                                                          ".com"
                                                        )
                                                      ) {
                                                        inputValue =
                                                          inputValue.replace(
                                                            /\.com.*/,
                                                            ".com"
                                                          );
                                                      } else if (
                                                        inputValue.includes(
                                                          ".in"
                                                        )
                                                      ) {
                                                        inputValue =
                                                          inputValue.replace(
                                                            /\.in.*/,
                                                            ".in"
                                                          );
                                                      }

                                                      e.target.value =
                                                        inputValue;
                                                    }}
                                                  />
                                                  <label
                                                    htmlFor="email"
                                                    className="did-floating-label"
                                                  >
                                                    Email
                                                  </label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="profile_btn_div ">
                                        <button
                                          className="profile_btn"
                                          type="submit"
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Pages other than Profile */}
        {mySideBar !== "myProfile" &&
          mySideBar !== "myPolicies" &&
          mySideBar !== "myQuotations" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "claimSupport"
                              ? "Claim Support"
                              : mySideBar === "myTransaction"
                              ? "My Transaction"
                              : ""}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No{" "}
                            {mySideBar === "claimSupport"
                              ? "active Claims"
                              : mySideBar === "myTransaction"
                              ? "Transactions"
                              : ""}{" "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myPolicies"
                              ? "Once you buy a new policy,"
                              : mySideBar === "claimSupport"
                              ? "If there are any Claims,"
                              : mySideBar === "myTransaction"
                              ? "If there are any transactions,"
                              : ""}{" "}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {/* Policy Section */}
        {mySideBar === "myPolicies" && customerPolicyData.length > 0 ? (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div
              className={`main_content`}
              style={{
                padding: sideNav
                  ? "calc(90px + 30px) 50px 30px 100px"
                  : "calc(90px + 30px) 50px 30px",
              }}
            >
              {/* style={{ padding: "calc(90px + 30px) 50px 30px" }} */}
              <div className="row">
                {/* New-POS table start */}
                <div className="col-12">
                  <div
                    className="dashboardInner_box"
                    style={{ padding: "42px 20px" }}
                  >
                    <div class="section_block">
                      <h2 class="sectionHeading">My Policies</h2>
                    </div>
                    <div
                      className=""
                      // style={{ marginTop: "6rem" }}
                    >
                      <div className="card-body p-0">
                        {/* <div className="text-end">
                                    <Button
                                      className="btn btn-fill btn-primary"
                                      // headers={headers}
                                      // data={csvData}
                                    >
                                      Download CSV
                                    </Button>
                                  </div> */}
                        <div className="text-center">
                          <MDBDataTable
                            scrollX
                            noBottomColumns
                            hover
                            entriesOptions={[10, 25, 50, 75, 100]}
                            entries={10}
                            data={dataTable}
                            infoLabel={["Showing", "to", "of", "entries"]}
                            pagesAmount={10}
                            paginationLabel={["<", ">"]}
                            bordered
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* New-POS table end */}
              </div>
            </div>
          </div>
        ) : (
          mySideBar === "myPolicies" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "myPolicies" && "My Policies"}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No{" "}
                            {mySideBar === "myPolicies" && "active Policies "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myPolicies" &&
                              "Once you buy a new policy,"}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {/* Quotation Section */}
        {mySideBar === "myQuotations" && customerQuotationData.length > 0 ? (
          <div className={`page_container ${sideNav ? "open" : ""}`}>
            <div
              className={`main_content`}
              style={{
                padding: sideNav
                  ? "calc(90px + 30px) 50px 30px 100px"
                  : "calc(90px + 30px) 50px 30px",
              }}
            >
              {/* style={{ padding: "calc(90px + 30px) 50px 30px" }} */}
              <div className="row">
                {/* New-POS table start */}
                <div className="col-12">
                  <div
                    className="dashboardInner_box"
                    style={{ padding: "42px 20px" }}
                  >
                    <div class="section_block">
                      <h2 class="sectionHeading">My Quotations</h2>
                    </div>
                    <div
                      className=""
                      // style={{ marginTop: "6rem" }}
                    >
                      <div className="card-body p-0">
                        {/* <div className="text-end">
                                    <Button
                                      className="btn btn-fill btn-primary"
                                      // headers={headers}
                                      // data={csvData}
                                    >
                                      Download CSV
                                    </Button>
                                  </div> */}
                        <div className="text-center">
                          <MDBDataTable
                            scrollX
                            noBottomColumns
                            hover
                            entriesOptions={[10, 25, 50, 75, 100]}
                            entries={10}
                            data={quotationDataTable}
                            infoLabel={["Showing", "to", "of", "entries"]}
                            pagesAmount={10}
                            paginationLabel={["<", ">"]}
                            bordered
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* New-POS table end */}
              </div>
            </div>
          </div>
        ) : (
          mySideBar === "myQuotations" && (
            <div className={`page_container ${sideNav ? "open" : ""}`}>
              <div className="main_content">
                <div className="row walletSearchRow">
                  <div className="col-12">
                    <div className="dashboardInner_box">
                      <div className="profile_detail">
                        <div className="section_block">
                          <h2 className="sectionHeading">
                            {mySideBar === "myQuotations" && "My Quotations"}
                          </h2>
                        </div>
                        <div className="policy_content">
                          <div className="policy_img mb-3">
                            <img src="/assets/images/docSearchingIcon.svg"></img>
                          </div>
                          <h2 className="">
                            No {mySideBar === "myQuotations" && "Quotations "}
                            found
                          </h2>
                          <p>
                            {mySideBar === "myQuotations" &&
                              "Once you view a Quotation, "}
                            it will show up here
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {logoutModal && (
          <LogoutModal show={logoutModal} setModel={setLogoutModal} />
        )}
      </div>
    </>
  );
};

export default MyProfile;
