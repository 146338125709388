import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import {
  dispatchHealthQuickQuote,
  dispatchResetHealthQuickQuote,
  healthQuickQuoteResult,
  resetHealthQuickQuoteResults,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import { createCustomerHealth } from "../services/TPApiCall";
import store from "../../store";
function QuotelistModelPlanHealth({ show, setModel, list, heading }) {
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [spinner, setSpinner] = useState(false);
  const healthRequestQQ = useSelector((state) => state.root.healthRequestQQ);

  const dispatch = useDispatch();
  useEffect(() => {
    // dispatchHealthQuickQuote("PlanDetails.planTenure", select);
    if (heading === "Sort By") {
      dispatchHealthQuickQuote("PlanDetails.planTenure", select);
    } else if (heading === "Cover") {
      dispatchHealthQuickQuote("PlanDetails.sumInsuredValue", select);
    }
  }, [select]);

  const handleApplyButton = async () => {
    setSpinner(true);
    dispatch(resetHealthQuickQuoteResults());

    const response = await createCustomerHealth(healthRequestQQ);
    store.dispatch(healthQuickQuoteResult(response?.data?.successQuotes));
    toggle();
    setSpinner(false);
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
              <h4>{heading}</h4>
              <div onClick={toggle} class="v2_close false">
                <img
                  src="./assets/images/v2_close.svg"
                  title="Close"
                  alt="close"
                />
              </div>
            </div>
            <div className="mb-3">
              {list?.map((res) => {
                return (
                  <div
                    onClick={() => setSelect(res?.key)}
                    className={`plan-type-list ${
                      select == res?.key ? "plan-type-list-active" : ""
                    }`}
                  >
                    <div>
                      <span className="plan-type-list-span">{res?.label}</span>
                      {res?.type == "Comprehensive with Zero Dep" && (
                        <span className="plan-type-list-span-recommended">
                          Recommended
                        </span>
                      )}
                    </div>
                    {/* {res?.desc && (
                      <span className="plan-type-list-span-inner">
                        {res?.desc}
                      </span>
                    )} */}
                  </div>
                );
              })}
            </div>
            <div class="v2_btngrp mb-3">
              <div onClick={() => toggle()} class="wrapper">
                <button
                  type="submit"
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12 cancel"
                >
                  <div>Cancel</div>
                </button>
              </div>
              <div class="wrapper">
                <button
                  type="button"
                  name="submitBtn"
                  onClick={() => handleApplyButton()}
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  {spinner === true ? (
                    <div class="spinner-border text-light" role="status">
                      {/* <span class="sr-only">Loading...</span> */}
                    </div>
                  ) : (
                    <div>Apply</div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistModelPlanHealth;
