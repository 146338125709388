// import moment from "moment";
import { post } from "jquery";
import {
  GetDataWithToken,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../api/apiHelper";
import store from "../../store";
// import {
//   dispatchQuickQuote,
//   quickQuoteResult,
// } from "../../store/actions/userActions";
// import {
//   createOnlinePolicyObj,
//   createQuotePayloadObj,
//   quickQuotePayloadObj,
// } from "../../store/reducers/userReducers";
// import { policyResultArr } from "../common/MakeModelArr";
import { sendErrorMessage } from "./masterServices";
import {
  HEALTH_QUICK_QUOTE_RESULTS,
  QUICK_QUOTE_RESULTS,
} from "../../store/types";
import {
  apiErrorReponse,
  dispatchHealthQuickQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  healthQuickQuoteResult,
  quickQuoteResult,
  resetHealthQuickQuoteResults,
} from "../../store/action/userActions";
import {
  failureCaseArr,
  healthPolicyResultArr,
  policyResultArr,
} from "../common/MakeModelArr";
import {
  createOnlinePolicyObj,
  createQuotePayloadObj,
  kycObj,
  kycStatus,
  motorQuotationObj,
  quickQuotePayloadObj,
  saveDataForVahan,
  sendEmailRequest,
  sendSmsRequest,
  submitProposalRequest,
} from "../../store/reducer/userReducers";
import moment from "moment";
import { useDispatch } from "react-redux";
import { round } from "lodash";

// Create Customer
export const createCustomer = (postdata) => {
  let formData = new FormData();
  for (let key in postdata) {
    formData.append(key, postdata[key]);
  }
  PostImageDataWithToken("motor/create-customer", formData).then((response) => {
    if (response.status === true && response?.data !== null) {
      dispatchQuickQuote("customerId", response.data.id);
    } else {
      // sendErrorMessage(response);
    }
  });
};

export const addDocuments = (postData) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    for (let key in postData) {
      if (key === "file" && postData[key].type === "application/pdf") {
        formData.append(key, postData[key], "document.pdf");
      } else {
        formData.append(key, postData[key]);
      }
    }
    PostImageDataWithToken("motor/getMotorKycDocumentUrl", formData)
      .then((response) => {
        if (response?.status === "success") {
          resolve(response);
        } else {
          console.log("Error in uploading docs");
          resolve(response);
          reject(new Error("Error in uploading docs"));
        }
      })
      .catch((error) => {
        console.error("Error in uploading docs", error);
        reject(error);
      });
  });
};

export const createCustomerHealth = async (postdata) => {
  try {
    const response = await PostDataWithToken("health/All_quotes", postdata);

    if (response.success === true) {
      let healthQuoteResults = response?.data?.successQuotes;
      store.dispatch(healthQuickQuoteResult(healthQuoteResults));

      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error; // Rethrow the error to be handled in the component
  }
};

// Save Policy to Database / Fill-Policy-Data
export const savePolicyToDatabase = (apiRequestQQ) => {
  let obj = createOnlinePolicyObj(apiRequestQQ);
  let formData = new FormData();
  for (let key in obj) {
    formData.append(key, obj[key]);
  }

  // formData.append("posId", store.getState().root.userDetails.id);

  formData.append("customerId", store.getState().root.apiRequestQQ.customerId);
  PostImageDataWithToken("motor/fill-policy-data", formData).then(
    (response) => {
      if (response.status === true) {
        dispatchQuickQuote("policyId", response.data.id);
      } else {
        sendErrorMessage(response);
      }
    }
  );
};

// Change Policy Status
export const changePolicyStatus = (status, leadId) => {
  let postdata = {
    status: status,
    leadId: leadId,
  };
  PostDataWithToken("motor/change-lead-status", postdata).then((response) => {
    if (response.status === true) {
    } else {
      toast.info(response.message);
    }
  });
};

// Digit Api
export const digitAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Digit" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Digit", uniqueNumber);

  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };

  PostDataWithToken("motor/digit", quickQuotePayloadObj(newData))
    .then((response) => {
      if (
        response.status === true &&
        response?.message === "Motor Data Fetched Successfully"
      ) {
        let {
          enquiryId,
          contract,
          vehicle,
          netPremium,
          grossPremium,
          serviceTax,
          discounts,
        } = response?.data["Motor-Quick Quote"];

        let ncbDiscount = 0;
        if (discounts?.otherDiscounts && discounts.otherDiscounts.length > 0) {
          let index = discounts.otherDiscounts.findIndex(
            (item) => item.discountType === "NCB_DISCOUNT"
          );
          if (index > -1) {
            ncbDiscount =
              discounts.otherDiscounts &&
              discounts.otherDiscounts[index].discountAmount;
          }
        }
        store.dispatch(
          quickQuoteResult(
            policyResultArr(
              "/assets/images/logos/partners/digit.png",
              "digit",
              "",
              enquiryId,
              vehicle?.licensePlateNumber,
              vehicle?.make,
              vehicle?.model,
              apiRequestQQ?.VariantName,
              contract?.coverages?.ownDamage?.netPremium
                ? contract?.coverages?.ownDamage?.netPremium
                : 0,
              contract?.coverages?.thirdPartyLiability?.netPremium
                ? contract?.coverages?.thirdPartyLiability?.netPremium
                : 0,
              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : false,
              netPremium,
              serviceTax.totalTax,
              grossPremium,
              vehicle.vehicleIDV.minimumIdv || 0,
              vehicle.vehicleIDV.maximumIdv || 0,
              vehicle.vehicleIDV.idv || 0,
              contract.startDate,
              contract.endDate,
              discounts.totalLoadingAmount,
              "Go Digit General Insurance Ltd",
              apiRequestQQ.NewPolicyType,
              contract.coverages.addons.roadSideAssistance.selection === true
                ? contract.coverages.addons.roadSideAssistance.netPremium
                : false,
              contract.coverages.addons.engineProtection.selection === true
                ? contract.coverages.addons.engineProtection?.netPremium
                : false,
              contract.coverages.addons.tyreProtection.selection === true
                ? contract.coverages.addons.tyreProtection?.netPremium
                : false,
              contract.coverages.addons.rimProtection.selection === true
                ? contract.coverages.addons.rimProtection?.netPremium
                : false,
              contract.coverages.addons.consumables.selection === true
                ? contract.coverages.addons.consumables?.netPremium
                : false,
              contract.coverages.accessories.electrical.selection,
              contract.coverages.accessories.nonElectrical.selection,
              contract.coverages.addons.returnToInvoice.selection === true
                ? contract.coverages.addons.returnToInvoice?.netPremium
                : false,
              false,
              false,
              false,
              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
                ? contract.coverages.unnamedPA.unnamedPaidDriver?.netPremium
                : false,
              contract.coverages.addons.personalBelonging.selection === true
                ? contract.coverages.addons.personalBelonging?.netPremium
                : false,
              contract.coverages.addons.partsDepreciation.selection === true
                ? contract.coverages.addons.partsDepreciation?.netPremium
                : false,
              contract.coverages.addons.keyAndLockProtect.selection === true
                ? contract.coverages.addons.keyAndLockProtect?.netPremium
                : false,
              contract.coverages.personalAccident.selection === true
                ? contract.coverages.personalAccident.netPremium
                : 0,
              contract.coverages.unnamedPA.unnamedPaidDriver.selection === true
                ? contract.coverages.unnamedPA.unnamedPaidDriver.netPremium
                : 0,
              contract.coverages.accessories.electrical.selection === true
                ? contract.coverages.accessories.electrical.insuredAmount
                : 0,
              contract.coverages.accessories.nonElectrical.selection === true
                ? contract.coverages.accessories.nonElectrical.insuredAmount
                : 0,
              0,
              ncbDiscount,
              contract.coverages.accessories.cng.selection,
              contract.coverages.legalLiability.paidDriverLL.selection === true
                ? contract.coverages.legalLiability.paidDriverLL.netPremium
                : false
            )
          )
        );
      } else {
        console.log("failure response", response);
        store.dispatch(
          apiErrorReponse(
            failureCaseArr("digit", "/assets/images/logos/partners/digit.png")
          )
        );
        sendErrorMessage(response);
      }
    })
    .catch((err) => {
      console.log(err);
      store.dispatch(
        apiErrorReponse(
          failureCaseArr("digit", "/assets/images/logos/partners/digit.png")
        )
      );
    });

  console.log("exit");
};

export const digitCreateQuoteApiCall = async (apiRequestQQ) => {
  try {
    let response = await PostDataWithToken(
      "motor/digit-create-quote",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log(err);
    alert("something went wrong");
  }
};

export const digitPaymentApi = (
  applicationId,
  ApiUniqueNumber,
  quoteNumber,
  policyNumber,
  Api_name
) => {
  let postData = {
    applicationId: applicationId,
    cancelReturnUrl: process.env.REACT_APP_ERROR_URL,
    successReturnUrl: `${process.env.REACT_APP_SUCCESS_URL}?applicationId=${applicationId}&quotationNumber=${quoteNumber}&policyNumber=${policyNumber}&Api_name=${Api_name}&ApiUniqueNumber=${ApiUniqueNumber}`,
    ApiUniqueNumber: ApiUniqueNumber,
    quotationNumber: quoteNumber,
  };

  PostDataWithToken("motor/digit-payment", postData).then((response) => {
    if (response.status === true) {
      window.location.href =
        response.data[
          "Motor-Motor payment link generation Api"
        ].dispatcherResponse;
    }
  });
};

export const downloadDigitPdf = (
  applicationId,
  ApiUniqueNumber,
  quoteNumber,
  processFlowObjMotor,
  setDownloadLoader
) => {
  let postData = {
    policyId: applicationId,
    ApiUniqueNumber: ApiUniqueNumber,
    quotationNumber: quoteNumber,
  };
  PostDataWithToken("motor/digit-pdf-download", postData).then((res) => {
    if (res.status === true) {
      window.location.href = res.data.schedulePath;
      setDownloadLoader(false);

      dispatchProcessFlowMotor("step13.pdf", res.data.schedulePath);
      const postData = {
        ...processFlowObjMotor,
        step: "step13",
        step13: { pdf: res.data.schedulePath },
      };
      processFlow(postData);
    }
  });
};

// export function downloadBase64File(contentBase64, fileName) {
//   const linkSource = `data:application/pdf;base64,${contentBase64}`;
//   const downloadLink = document.createElement("a");
//   document.body.appendChild(downloadLink);

//   downloadLink.href = linkSource;
//   downloadLink.target = "_self";
//   downloadLink.download = fileName;
//   downloadLink.click();
// }

// Sompo

export const sompoAPICall = (apiRequestQQ) => {
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Sompo" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Sompo", uniqueNumber);

  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };

  PostDataWithToken("motor/sompo", quickQuotePayloadObj(newData))
    .then((response) => {
      if (
        response.root.Errors.ErrDescription._text === "Authentication Success"
      ) {
        const data = response.root;

        store.dispatch(
          quickQuoteResult(
            policyResultArr(
              "/assets/images/logos/partners/usgi-logo.svg",
              "Sompo",
              "",
              data?.Authentication?.GUID?._text,
              data?.Product?.Risks?.RegistrationNumber?._attributes?.Value,
              data?.Product?.Risks?.VehicleMake?._attributes?.Value,
              data.Product.Risks.VehicleModel._attributes.Value,
              "",
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[0].Premium._attributes.Value,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[3].Premium._attributes.Value,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[11].Premium._attributes.Value !== "0.00"
                ? root.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[11].Premium._attributes.Value
                : false,
              data.Product.PremiumCalculation.NetPremium._attributes.Value,

              parseInt(data.Product.PremiumCalculation.CGST._attributes.Value) +
                parseInt(
                  data.Product.PremiumCalculation.SGST._attributes.Value
                ) +
                parseInt(
                  data.Product.PremiumCalculation.UGST._attributes.Value
                ) +
                parseInt(
                  data.Product.PremiumCalculation.IGST._attributes.Value
                ),
              data.Product.PremiumCalculation.TotalPremium._attributes.Value,
              0,
              0,
              data.Product.Risks.VehicleIDV._attributes.Value,
              moment(
                data.Product.GeneralProposal.GeneralProposalGroup
                  .GeneralProposalInformation.PolicyEffectiveDate.Fromdate
                  ._attributes.Value,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD"),
              moment(
                data.Product.GeneralProposal.GeneralProposalGroup
                  .GeneralProposalInformation.PolicyEffectiveDate.Todate
                  ._attributes.Value,
                "DD/MM/YYYY"
              ).format("YYYY-MM-DD"),
              data?.Product?.Risks?.Risk?.RisksData?.["De-tariffDiscounts"]?.[
                "De-tariffDiscountGroup"
              ]?.["De-tariffDiscountGroupData"]?.Premium?._attributes?.Value,
              "Universal Sompo General Insurance Co. Ltd.",
              apiRequestQQ.NewPolicyType,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[10].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[10].Premium._attributes.Value
                : false,
              data.Product.Risks.FuelType._attributes.Value === "Petrol"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[5].Premium._attributes.Value
                : data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[4].Premium._attributes.Value,
              data.Product.Risks.Risk.RisksData.AddonCoverDetails.AddonCovers
                .AddonCoversData[12].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.AddonCoverDetails
                    .AddonCovers.AddonCoversData[12].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.AddonCoverDetails.AddonCovers
                .AddonCoversData[12].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.AddonCoverDetails
                    .AddonCovers.AddonCoversData[12].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.AddonCoverDetails.AddonCovers
                .AddonCoversData[1].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.AddonCoverDetails
                    .AddonCovers.AddonCoversData[1].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[1].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[1].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[2].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[2].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.AddonCoverDetails.AddonCovers
                .AddonCoversData[9].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.AddonCoverDetails
                    .AddonCovers.AddonCoversData[9].Premium._attributes.Value
                : false,
              false,
              false,
              data.Product.Risks.Risk.RisksData.OtherDiscounts
                .OtherDiscountGroup.OtherDiscountGroupData[1].Premium
                ._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.OtherDiscounts
                    .OtherDiscountGroup.OtherDiscountGroupData[1].Premium
                    ._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[5].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[5].Premium._attributes.Value
                : false,
              false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[8].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[8].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[7].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[7].Premium._attributes.Value
                : false,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[11].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[11].Premium._attributes.Value
                : 0,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[5].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[5].Premium._attributes.Value
                : 0,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[1].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[1].Premium._attributes.Value
                : 0,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[2].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[2].Premium._attributes.Value
                : 0,
              data.Product.Risks.Risk.RisksData.OtherDiscounts
                .OtherDiscountGroup.OtherDiscountGroupData[1].Premium
                ._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.OtherDiscounts
                    .OtherDiscountGroup.OtherDiscountGroupData[1].Premium
                    ._attributes.Value
                : 0,
              data.Product.Risks.Risk.RisksData.OtherDiscounts
                .OtherDiscountGroup.OtherDiscountGroupData[5].Premium
                ._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.OtherDiscounts
                    .OtherDiscountGroup.OtherDiscountGroupData[5].Premium
                    ._attributes.Value
                : 0,
              0,
              data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                .CoversData[12].Premium._attributes.Value !== "0.00"
                ? data.Product.Risks.Risk.RisksData.CoverDetails.Covers
                    .CoversData[12].Premium._attributes.Value
                : 0,
              data?.Product?.Risks?.Risk?.RisksData?.["De-tariffDiscounts"]?.[
                "De-tariffDiscountGroup"
              ]?.["De-tariffDiscountGroupData"]?.Rate?._attributes?.Value,
              data?.Product?.Risks?.Risk?.RisksData?.["De-tariffLoadings"]?.[
                "De-tariffLoadingGroup"
              ]?.["De-tariffLoadingGroupData"]?.Rate?._attributes?.Value
            )
          )
        );
      } else if (response?.root?.Errors?.ErrDescription?._text !== "") {
        store.dispatch(
          apiErrorReponse(
            failureCaseArr(
              "Sompo",
              "/assets/images/logos/partners/usgi-logo.svg"
            )
          )
        );
      } else {
        store.dispatch(
          apiErrorReponse(
            failureCaseArr(
              "Sompo",
              "/assets/images/logos/partners/usgi-logo.svg"
            )
          )
        );
        sendErrorMessage(response);
      }
    })
    .catch((err) => {
      console.log(err);
      store.dispatch(
        apiErrorReponse(
          failureCaseArr("Sompo", "/assets/images/logos/partners/usgi-logo.svg")
        )
      );
    });

  console.log("exit");
};

export const sompoCreateQuote = async (apiRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "motor/sompo-create-quote",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const sompoPaymentApi = (premium, policyNo, ApiUniqueNumber) => {
  let postData = {
    premium: premium,
    policyNo: policyNo,
    ApiUniqueNumber: ApiUniqueNumber,
  };

  PostDataWithToken("motor/sompo-payment", postData).then((response) => {
    if (response.status === true) {
      window.location.href = response?.url;
    }
  });
};

export const sompoPaymentApiHealth = (enquiryId, planType, quotationId) => {
  let postData = {
    enquiryId: enquiryId,
    planType: planType,
    quotationNumber: quotationId,
  };

  // debugger;
  PostDataWithToken("health/sompo_payment_health", postData).then(
    (response) => {
      if (response.status === true) {
        window.location.href = response?.url;
      }
    }
  );
};

// Kotak API
export const KotakAPICall = async (apiRequestQQ) => {
  // debugger
  // store.dispatch(resetQuickQuoteResults())
  const min = 10000000000;
  const max = 99999999999;

  const randomNumber = Math.floor(Math.random() * (max - min + 1) + min);

  const uniqueNumber = "Kotak" + randomNumber;

  dispatchQuickQuote("ApiUniqueNumber.Kotak", uniqueNumber);

  const newData = { ...apiRequestQQ, ApiUniqueNumber: uniqueNumber };

  const response = await PostDataWithToken(
    "motor/kotak",
    quickQuotePayloadObj(newData)
  );
  if (response.status === true) {
    if (response.data.vErrorMsg === "Success") {
      const data = response.data;
      dispatchQuickQuote("KGIStatus", true);
      store.dispatch(
        quickQuoteResult(
          policyResultArr(
            "/assets/images/logos/partners/kotak.png",
            "Kotak",
            data.vWorkFlowID,
            data.vQuoteId,
            apiRequestQQ.RegistrationNumber,
            data.vMake,
            data.vModel,
            data.vVariant,
            data.vOwnDamagePremium,
            data.vBasicTPPremium,
            data.vPACoverForOwnDriver !== "-"
              ? data.vPACoverForOwnDriver
              : false,
            data.vNetPremium,
            data.vGSTAmount,
            data.vTotalPremium,
            parseInt(data.vFinalIDV) - (parseInt(data.vFinalIDV) * 11) / 100,
            parseInt(data.vFinalIDV) + (parseInt(data.vFinalIDV) * 20) / 100,
            data.vFinalIDV,
            moment(data.vPolicyStartDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            moment(data.vPolicyEndDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            "",
            "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LTD.",
            apiRequestQQ.NewPolicyType,
            data.vRSA != "0" ? data.vRSA : false,
            data.vEngineProtect != "0" ? data.vEngineProtect : false,
            data.nTyreCoverPremium != "0" ? data.nTyreCoverPremium : false,
            false,
            data.vConsumableCover != "0" ? data.vConsumableCover : false,
            data.vElectronicSI != "0" ? data.vElectronicSI : false,
            data.vNonElectronicSI != "0" ? data.vNonElectronicSI : false,
            data.vReturnToInvoice != "0" ? data.vReturnToInvoice : false,
            false,
            data.nNCBProtectPremium != "0" ? data.nNCBProtectPremium : false,
            data.vVoluntaryDeduction != "0" ? data.vVoluntaryDeduction : false,
            data.vPAForUnnamedPassengerPremium != "0"
              ? data.vPAForUnnamedPassengerPremium
              : false,
            data.nLossPersonalBelongingsPremium != "0"
              ? data.nLossPersonalBelongingsPremium
              : false,
            data.vDepreciationCover != "0" ? data.vDepreciationCover : false,
            data.nKeyReplacementPremium != 0
              ? data.nKeyReplacementPremium
              : false,
            data.vPACoverForOwnDriver !== "-"
              ? data.vPACoverForOwnDriver
              : false,
            data.vPAForUnnamedPassengerPremium != "0"
              ? data.vPAForUnnamedPassengerPremium
              : false,
            data.vElectronicSI != "0" ? data.vElectronicSI : 0,
            data.vNonElectronicSI != "0" ? data.vNonElectronicSI : 0,
            data.vVoluntaryDeduction != "0" ? data.vVoluntaryDeduction : 0,
            data.vNCB,
            parseFloat(data.vCngLpgKitPremium) +
              parseFloat(data.vCngLpgKitPremiumTP),
            parseInt(data?.vLegalLiabilityPaidDriverNo)
          )
        )
      );
    } else {
      dispatchQuickQuote("KGIStatus", false);
      store.dispatch(
        apiErrorReponse(
          failureCaseArr("Kotak", "assets/images/logos/partners/kotak.png")
        )
      );
    }
  } else {
    sendErrorMessage(response);

    store.dispatch(
      apiErrorReponse(
        failureCaseArr("Kotak", "/assets/images/logos/partners/kotak.png")
      )
    );
  }
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(response.data);
    }, 1800)
  );
};

export const kotakIssueApi = async (apiRequestQQ) => {
  // debugger;
  try {
    const response = await PostDataWithToken(
      "motor/saveKotakProposal",
      createQuotePayloadObj(apiRequestQQ)
    );
    return response;
  } catch (err) {
    console.log("KOTAK ISSUE API", err);
  }
};

// Care
export const careKyc = async (healthRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "health/care_ckyc",
      healthRequestQQ
    );
    if (response.success === true) {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const sompoKYC = async (healthRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "health/sompo_kyc",
      healthRequestQQ
    );
    if (response.success === true) {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (err) {
    console.error("ERROR", err);
    throw err;
  }
};

export const getRC = async (data) => {
  try {
    const response = await PostDataWithToken("motor/getRC", data);

    if (response) {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const issueHealthPolicy = async (healthRequestQQ) => {
  try {
    const response = await PostDataWithToken(
      "health/care_createPolicy",
      healthRequestQQ
    );
    if (response.success === true) {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const carePayment = async (enquiryId) => {
  try {
    const response = await PostDataWithToken("health/care_payment", {
      enquiryId: enquiryId,
    });
    dispatchHealthQuickQuote("paymentURL", response);
    if (response.success === true) {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const sompoHealthIssuePolicy = async (healthRequestQQ, planType) => {
  try {
    const response = await PostDataWithToken("health/sompo_createPolicy", {
      healthRequestQQ,
      planType,
    });
    if (response.message === "Data fetched in sompo proposal api") {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const sendOtp = async (data) => {
  const FullName = data?.CustomerDetails.customerMiddleName
    ? data?.CustomerDetails.customerFirstName +
      " " +
      data?.CustomerDetails.customerMiddleName +
      " " +
      data?.CustomerDetails.customerLastName
    : data?.CustomerDetails.customerFirstName +
      " " +
      data?.CustomerDetails.customerLastName;
  const newData = {
    name: FullName,
    number: data?.CustomerDetails?.customerMobileNumber,
    planType: data?.planType,
  };
  try {
    const response = await PostDataWithToken("auth/send-otp", newData);
    if (response.message === "Otp sent successfully") {
      return response; // Return the response if it's successful
    } else {
      // Handle the case where success is not true
      console.error("Unsuccessful response:", response);
      return null;
    }
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

export const verifyOtp = async (newData) => {
  const response = await PostDataWithToken("auth/verify-otpData", newData);
  if (response.success === true) {
    return response; // Return the response if it's successful
  } else {
    // Handle the case where success is not true
    // console.error("Unsuccessful response:", response);
    return response.data;
  }
};

export function downloadBase64File(contentBase64, fileName) {
  const linkSource = `data:application/pdf;base64,${contentBase64}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = fileName;
  downloadLink.click();
}

export const checkCustomer = (userData) => {
  const postData = {
    phoneNumber: userData?.MobileNumber,
  };

  PostDataWithToken("admin/checkCustomer", postData).then((response) => {
    if (
      response?.data?.success === false &&
      response?.data?.message === "Customer Does not exist with this Number!!"
    ) {
      const min = 1000000000000;
      const max = 9999999999999;
      const UniqueId = Math.floor(Math.random() * (max - min + 1) + min);

      const newUserData = {
        name: userData?.FullName,
        emailId: userData?.Email,
        phoneNumber: userData?.MobileNumber,
        customerUniqueNumber: UniqueId,
      };
      PostDataWithToken("admin/add_customer", newUserData).then((response) => {
        return response;
      });
    } else if (
      response?.data?.success === true &&
      response?.data?.message === "Customer already Exists!!"
    ) {
      return response;
    }
  });
};

export const getQuotationNumber = (apiRequestQQ, userDetails) => {
  const postData = {
    userType: "user",
    customerUniqueId: userDetails?.CustomerUniqueNumber || "",
  };

  PostDataWithToken("admin/allQuotation", postData).then((response) =>
    console.log("RESPONSE QUOTATION NUMBER", response)
  );
};

export const processFlow = async (data) => {
  try {
    const response = await PostDataWithToken("admin/flow", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getQuoteCount = async () => {
  try {
    const response = await GetDataWithToken("admin/quote_count");
    return response;
  } catch (error) {
    return error;
  }
};

export const getPreviousUserDetails = async (data) => {
  try {
    const response = await PostDataWithToken("admin/get_proc_status", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPreviousJourneyDetails = async (data) => {
  try {
    const response = await PostDataWithToken("admin/getFlow", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getMotorQuote = async (motorRequest) => {
  try {
    const response = await PostDataWithToken(
      "motor/getQuotesData",
      motorQuotationObj(motorRequest)
    );

    return response?.data;
  } catch (error) {
    return error;
  }
};

export const getKycConfig = async (insurerId, customerType) => {
  const postData = {
    insurerId: insurerId,
    customerType: customerType,
  };
  try {
    const response = await PostDataWithToken("kyc/getKycConfig", postData);
    return response;
  } catch (error) {
    return error;
  }
};

export const getKyc = async (data, selectedPlan, kycFields) => {
  try {
    const response = await PostDataWithToken(
      "kyc/getKyc",
      kycObj(data, selectedPlan, kycFields)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycStatus = async (data, planDetail) => {
  try {
    const response = await PostDataWithToken(
      "kyc/getKycStatus",
      kycStatus(data, planDetail)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const submitProposal = async (newData, selectedPlan, kycFields) => {
  try {
    const response = await PostDataWithToken(
      "motor/submitProposal",
      submitProposalRequest(newData, selectedPlan, kycFields)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getNomineeRelations = async () => {
  try {
    const response = await GetDataWithToken("motor/getRelations");
    return response;
  } catch (error) {
    return error;
  }
};

export const getPaymentStatus = async (data) => {
  const postData = {
    caseId: data,
  };
  try {
    const response = await PostDataWithToken(
      "motor/getPaymentStatus",
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getPolicyDocument = async (data) => {
  try {
    const response = await GetDataWithToken(`motor/getPolicyDocument/${data}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const addDocumentsForMotorCustomer = async (data) => {
  try {
    const response = await PostDataWithToken("admin/add_cust_doc", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFlow = async (data) => {
  try {
    const response = await PostDataWithToken("admin/getFlow", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await PostDataWithToken("admin/sendEmail", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const generateSms = async (data) => {
  try {
    const response = await PostDataWithToken("admin/generateSms", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const checkEmployee = async (data) => {
  try {
    const response = await PostDataWithToken("admin/checkEmployee", {
      mobileNumber: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const employeeLogin = async (data) => {
  try {
    const response = await PostDataWithToken("admin/employeeLogin", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeDetails = async (data) => {
  try {
    const response = await PostDataWithToken("admin/getEmployeeDetails", {
      username: data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getKycServiceStatus = async (data) => {
  try {
    const response = await PostDataWithToken("kyc/getKycServiceStatus", data);
    return response;
  } catch (error) {
    return error;
  }
};

export const createVehicleDetailsSave = async (motorRequest, data) => {
  try {
    const response = await PostDataWithToken(
      "motor/registrationNo-save",
      saveDataForVahan(motorRequest, data)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const searchVehicleDetails = async (registrationNumber) => {
  const postData = {
    registrationNumber: registrationNumber,
  };
  try {
    const response = await PostDataWithToken(
      "motor/registrationNo-search",
      postData
    );
    return response;
  } catch (error) {
    return error;
  }
};
