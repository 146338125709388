import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { CiCircleInfo } from "react-icons/ci";
function TooltipCommon({ data }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <span style={{ marginLeft: "10px" }} id="DisabledAutoHideExample">
        <CiCircleInfo fontSize={20} />
      </span>
      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        autohide={false}
        target="DisabledAutoHideExample"
        toggle={toggle}
      >
        {data}
      </Tooltip>
    </>
  );
}

export default TooltipCommon;
