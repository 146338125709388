import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Layout from "./Layout";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [active, setActive] = useState("");
  return (
    <Layout>
      <div className="breadcrumb">
        <h2>About Us</h2>
      </div>
      <div className="findinsurance">
        <div className="findinsurance-first">
          <div className="findinscnt">
            <h2>Your Trusted Insurance Web Aggregator</h2>
            <p className="justify_para">
              At InsureFast, we revolutionize how you navigate insurance. Our
              platform simplifies the complex world of insurance by offering a
              seamless, user-friendly interface for comparing various policies.
              We're committed to ensuring that you make informed decisions about
              your coverage.
            </p>
            <p className="justify_para">
              Our extensive network connects you with top insurers, presenting a
              comprehensive array of options in real-time. Transparency is our
              cornerstone; we provide clear, concise policy details, empowering
              you to choose what suits you best.
            </p>
            <p className="justify_para">
              Customer satisfaction is our priority. Our dedicated support team
              is here to guide you through your insurance journey, offering
              assistance at every step.
            </p>
            <p className="justify_para">
              Safety of your data matters. InsureFast employs top-notch security
              measures, ensuring your sensitive information remains protected
              throughout your interaction with our platform.
            </p>
            <p className="justify_para">
              Join us at InsureFast and experience a new era of hassle-free,
              empowered insurance decision-making. Your peace of mind is our
              commitment.
            </p>
          </div>
          <div className="aboutimg">
            <img
              src={"https://expertcover.in/assetss/images/aboutimg01.png"}
              alt="img"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* <!-- How do we do --> */}
      <div className="howdowe">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="howdoheading">How do we do it?</h2>
              <p className="wedotitle justify_para">
                Our approach is grounded in trust, transparency, and
                professionalism. We carefully tailor insurance solutions to your
                unique needs, simplifying the buying process, providing expert
                guidance in policy management, and ensuring swift and fair claim
                settlements. Your peace of mind is our top priority.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={
                    "https://expertcover.in/assetss/images/buyinginsuicon.png"
                  }
                  alt="img"
                  className="img-fluid"
                />
                <h2>Buying Insurance</h2>
                <p className="justify_para">
                  We simplify the process of buying insurance. It's our priority
                  to ensure you have the right coverage for your unique
                  circumstances.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={
                    "https://expertcover.in/assetss/images/manageinsicon.png"
                  }
                  alt="img"
                  className="img-fluid"
                />
                <h2>Managing Insurance</h2>
                <p className="justify_para">
                  We're here to assist you in managing your insurance policies.
                  Our experts are always available to answer your questions and
                  provide support.
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="ourmanegement">
                <img
                  src={"https://expertcover.in/assetss/images/claimgicon.png"}
                  alt="img"
                  className="img-fluid"
                />
                <h2>Claim Settlement</h2>
                <p className="justify_para">
                  In the unfortunate event of a claim, we guide you through the
                  process and ensure a swift and fair settlement. Your peace of
                  mind is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Vision --> */}
      <div className="visionsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <img
                src={"https://expertcover.in/assetss/images/vision.png"}
                alt="img"
                className="img-fluid"
              />
            </div>

            <div className="col-md-12 col-lg-6">
              <div className="visioncnt">
                <h2>Our vision</h2>
                <p className="justify_para">
                  Our vision at Insurefast is to provide tailored insurance
                  solutions that protect you against life's uncertainties. We
                  aim to build trust with our clients, deliver quality service,
                  and become your long-term partner in safeguarding your future.
                </p>
                <p className="justify_para">
                  {" "}
                  We are committed to ensuring you are prepared for any
                  situation. Your peace of mind is our top priority, and our
                  goal is to help you achieve your financial goals while staying
                  protected.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="solutionsec">
        <div className="container">
          <h2>Solving Your Insurance Needs on Time</h2>
          <div className="getcover">
            <p>
              Get world-class insurance coverage that not only protects you but
              also helps you build wealth. Let us be your insurance partner.
            </p>
            <div className="getworldbtn">
              <button>
                <Link to="/">Get Started</Link>
              </button>
              <button>
                <Link to="/contact-us">Contact Us</Link>
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default AboutUs;
