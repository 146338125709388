import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";
import {
  dispatchHealthQuickQuote,
  dispatchMotorQuote,
  dispatchProcessFlowHealth,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetHealthQuickQuote,
  dispatchResetProcessFlow,
  dispatchResetProcessFlowHealth,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  dispatchUserData,
  dispatchUserDetails,
  resetHealthQuickQuoteResults,
  resetProcessFlowMotor,
  resetQuickQuoteResults,
  resetSelectedPlan,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehiclePincode,
  getVehiclePincodeHealth,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant,
} from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelect";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import {
  FOUR_WHEELER_VEHICLE_TYPE,
  POLICY_STATUS,
  POLICY_TYPE,
  TWO_WHEELER_VEHICLE_TYPE,
  VEHICLE_TYPE,
  caseStatus,
} from "../utility/constants";
import { useCookies } from "react-cookie";
import ReactInputMask from "react-input-mask";
import DatePicker from "react-datepicker";

const VehicleDetailsModal = ({
  show,
  setModal,
  vehicleDetails,
  setActiveStep,
}) => {
  if (!vehicleDetails) return null;
  const {
    registrationNumber,
    rtoCode,
    vehicleType,
    manufaturingDate,
    mobileNumber,
    firstName,
    lastName,
    registrationDate,
    variantCode,
    makeId,
    modelId,
    businessType,
    customerType,
    prevPolicyType,
    prevInsurerCode,
    prevPolicyEndDate,
    newPolicyType,
    isOwnerChanged,
    email,
  } = vehicleDetails?.data || {};

  //   const registrationNumber = vehicleDetails?.data?.registrationNumber || "N/A";
  console.log("VehicleDetails---", variantCode);

  const manufacturingYear =
    manufaturingDate !== "N/A"
      ? new Date(manufaturingDate).getFullYear()
      : "N/A";
  const [healthModal, setHealthModal] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm({ mode: "onBlur" });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedBox, setSelectedBox] = useState("");
  const [pincodeData, setPincodeData] = useState([]);
  const [insurerData, setInsurerData] = useState([]);
  const [age, setAge] = useState([]);
  const [filteredPincodes, setFilteredPincodes] = useState([]);
  //   const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!show);
  const [retentionModal, setRetentionModal] = useState(false);
  const toggleRetentionModal = () => setRetentionModal(!retentionModal);
  const [currentSteps, setCurrentSteps] = useState(1);

  const [submit, setSubmit] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const userDetails = useSelector((state) => state?.root?.userDetails);

  const params = new URLSearchParams(window.location.search);
  console.log("PARAMSSSS", params.get("employeeId"));

  const [differenceYear, setDifferenceYear] = useState(0);

  useEffect(() => {}, [selectedBox === "Pvt Car", selectedBox === "MotorBike"]);

  const [variantData, setVariantData] = useState([]);
  const [variantDetails, setVariantDetails] = useState(null);
  const [maxRegDate, setMaxRegDate] = useState("");
  const [prevPolicyDate, setPrevPolicyDate] = useState(prevPolicyEndDate || "");

  const [previousInsurerDetails, setPreviousInsurerDetails] = useState(null);
  useEffect(() => {
    console.log("prevPolicyDate", prevPolicyDate);
  }, [prevPolicyDate]);

  useEffect(() => {
    getVehicleVariant({
      makeId: makeId,
      modelId: modelId,
      vehicleType: vehicleType,
    }).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        // let fuelTypeArr = data.filter(
        //   (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        // );
        // let j = 0;
        // let fuelarr = [];
        // while (j < fuelTypeArr.length) {
        //   fuelarr.push(fuelTypeArr[j].fuelType);
        //   j++;
        // }
        // setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            make: item?.make,
            makeId: item?.makeId,
            model: item?.model,
            modelId: item?.modelId,
            label: `${item.variant}(${item.cc}cc)`,
            value: item.variantId,
            fuelType: item.fuelType,
            cubicCapacity: item.cc,
            seatingCapacity: item.seats,
          });
          i++;
        }
        setVariantData(arr);
        console.log("setVariantData", arr);

        setVariantDetails(
          arr.find((vehicle) => vehicle?.value === parseInt(variantCode))
        );

        // if (motorRequest?.vehicleType === "4w") {
        //   handleStepSelection(activeStep + 1);
        // } else {
        //   dispatchMotorQuote("fuelType", fuelarr[0]);
        //   handleStepSelection(activeStep + 2);
        // }
      }
    });
  }, []);

  useEffect(() => {
    console.log("veriantDEtails-----", variantDetails);
  }, [variantDetails]);

  const handleUserDetails = (data) => {
    debugger;
    console.log("Form Data Submitted:", data);

    // Reset necessary state/actions
    dispatchResetHealthQuickQuote();

    const newData = {
      insurerCode: "",
      prevPolicyEndDate: "",
    };

    for (let key in newData) {
      dispatchMotorQuote(key, newData[key]);
    }

    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
      navigate(`/quotelist/${motorRequest?.customerLeadId}`);
    }, 1000);
  };

  const handleNotKnownVehicleDetails = () => {
    debugger;
    toggleModal(!show);
    // If registration number exists, proceed to the next step
    setActiveStep(2);
    localStorage.clear();
  };

  const handleSaveVehicleDetails = () => {
    dispatchMotorQuote("saveDataForVahanDetails", vehicleDetails);
    dispatchMotorQuote("rtoCode", rtoCode);
    dispatchMotorQuote("registrationNumber", registrationNumber);
    dispatchMotorQuote("manufaturingDate", manufaturingDate);
    dispatchMotorQuote("vehicleType", vehicleType);
    dispatchMotorQuote("mobileNumber", mobileNumber);
    dispatchMotorQuote("firstName", firstName);
    dispatchMotorQuote("lastName", lastName);
    dispatchMotorQuote("registrationDate", registrationDate);
    dispatchMotorQuote("variantId", variantCode);
    dispatchMotorQuote("makeId", makeId);
    dispatchMotorQuote("makeName", variantDetails?.make);
    dispatchMotorQuote("modelId", modelId);
    dispatchMotorQuote("modelName", variantDetails?.model);
    dispatchMotorQuote("variantName", variantDetails?.label);
    dispatchMotorQuote("fuelType", variantDetails?.fuelType);
    dispatchMotorQuote("businessType", businessType);
    dispatchMotorQuote("customerType", customerType);
    dispatchMotorQuote("prevPolicyType", prevPolicyType);
    dispatchMotorQuote("insurerCode", prevInsurerCode);
    dispatchMotorQuote("prevPolicyEndDate", prevPolicyEndDate);
    dispatchMotorQuote("newPolicyType", newPolicyType);
    dispatchMotorQuote("isOwnerChanged", isOwnerChanged);
    dispatchMotorQuote("email", email);
    const customerLeadId = motorRequest?.customerLeadId;
    saveToLocalStorage(
      "vehicleDetailsResponse",
      vehicleDetails,
      customerLeadId,
      15
    );
    navigate(`/quotelist/${motorRequest?.customerLeadId}`);
  };
  const saveToLocalStorage = (
    key,
    value,
    customerLeadId,
    expirationInMinutes
  ) => {
    const expirationTime =
      new Date().getTime() + expirationInMinutes * 60 * 1000; // Calculate expiration time
    const dataToSave = {
      value: {
        ...value, // Save response data
        customerLeadId, // Include customerLeadId
      },
      expirationTime, // Save expiration time
    };
    localStorage.setItem(key, JSON.stringify(dataToSave)); // Save data as a string in local storage
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Validate the input date format (DD/MM/YYYY)
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);
      console.log("handleInputChange", date);
      if (!isNaN(date.getTime())) {
        setPrevPolicyDate(date);
        setValue("prevPolicyDate", date); // Update form value
      }
    } else {
      setPrevPolicyDate(null);
      setValue("prevPolicyDate", ""); // Clear value if invalid
    }
  };

  const handlePrevPolicyEndDate = (val) => {
    const date = moment(val).format("YYYY-MM-DD");
    console.log("handleDateChange", date);
    if (date) {
      setPrevPolicyDate(date);
      setValue("prevPolicyEndDate", date);
    } else {
      setPrevPolicyDate(null);
      setValue("prevPolicyEndDate", "");
    }
  };
  useEffect(() => {
    // getVehicleRto().then((response) => {
    //   console.log(response, "response");
    //   if (response.status === "success") {
    //     let data = response?.data;
    //     let i = 0;
    //     let arr = [];
    //     while (i < data?.length) {
    //       let item = data[i];
    //       arr.push({
    //         label: item.registered_city_name + " (" + item.RTO_Code + ")",
    //         value: item.RTO_Code,
    //         registered_state_name: item.registered_state_name,
    //         id: item.id,
    //         registered_city_name: item.registered_city_name,
    //       });
    //       i++;
    //     }
    //     setRtoData(arr);
    //   }
    // });

    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            // value: item.Digit_Code,
            value: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
        setPreviousInsurerDetails(
          arr.find((vehicle) => vehicle?.value === parseInt(prevInsurerCode))
        );
      }
    });
  }, []);

  useEffect(() => {
    console.log("previousInsurerDetails-----", previousInsurerDetails);
  }, [previousInsurerDetails]);

  return (
    <div className="container">
      <div className="d-flex hero-container">
        {/* <div className="youthImage">
          <img src="assets/images/young.png" />
        </div> */}
      </div>
      {/* -----------------------------MODAL BOX-------------------------- */}
      <Modal
        isOpen={show}
        className="car_modal"
        toggle={toggleModal}
        size="md"
        backdrop="static"
        centered
        // className="vehicleInfoModal"
      >
        <ModalBody>
          {currentSteps === 1 && (
            <div className="modalBody">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-3">
                <h4 className="text-center">Did we get the right details?</h4>
                <div onClick={toggleModal} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="modalContent">
                <div className="leftContent">
                  <h5 className="vehicleNumber">
                    {/* {rcName === "" ? "RJ52CA1597" : rcName.toUpperCase()} */}
                    {registrationNumber}
                  </h5>
                  {motorRequest?.vehicleType === "2w" ? (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  ) : (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  )}
                  <p>
                    {/* GRAND i10 */}
                    {/* <FaDotCircle size={13} className="dot" /> */}
                  </p>
                  <p>
                    {manufacturingYear}
                    <FaDotCircle size={13} className="dot" />{" "}
                    {`${variantDetails?.fuelType}`}{" "}
                    {/* <FaDotCircle size={13} className="dot" /> */} <br />
                    {/* Jaipur */}
                  </p>
                </div>
                <div className="rightContent">
                  <img
                    src={
                      vehicleType === "2w"
                        ? "/assets/images/BIKE PNG (1).png"
                        : "/assets/images/car-wash.png"
                    }
                  />
                </div>
              </div>
              <div className="modalBtn">
                <button
                  className="modalBtnYes"
                  onClick={() => {
                    vehicleType === "2w" ? (
                      <>{dispatchMotorQuote("vehicleType", "2w")}</>
                    ) : (
                      <>{dispatchMotorQuote("vehicleType", "4w")}</>
                    );
                    handleSaveVehicleDetails();
                    // setCurrentSteps(currentSteps + 1);
                  }}
                >
                  {vehicleType === "2w"
                    ? "Yes, Its my Bike"
                    : "Yes, Its my Car"}
                </button>
                <button
                  className="modalBtnYes"
                  onClick={() => handleNotKnownVehicleDetails()}
                >
                  {vehicleType === "2w"
                    ? "No, Its not my Bike"
                    : "No, Its not my Car"}
                </button>
              </div>
            </div>
          )}
          {currentSteps === 2 && (
            <div className="modalBody">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-3">
                <h4 className="text-center">Fill your Personal Details?</h4>
                <div onClick={toggleModal} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="modalContent">
                <div className="leftContent">
                  <h5 className="vehicleNumber">
                    {registrationNumber}

                    {/* {rcName ? rcName : "RJ45SA9490"} */}
                  </h5>
                  {motorRequest?.vehicleType === "2w" ? (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  ) : (
                    <p>{`${variantDetails?.make} ${variantDetails?.model} ${variantDetails?.label}`}</p>
                  )}
                </div>
                <div className="rightContent">
                  <img
                    src={
                      vehicleType === "2w"
                        ? "/assets/images/BIKE PNG (1).png"
                        : "/assets/images/car-wash.png"
                    }
                  />
                </div>
              </div>
              <form
                onSubmit={handleSubmit1(handleUserDetails)}
                className="w-100"
              >
                <div>
                  {/* Insurer Dropdown */}
                  <div className="formStep8PolicyInsurer">
                    <div
                      className="did-floating-label-content"
                      style={{ marginBottom: "0px" }}
                    >
                      <select
                        name="insurerCode"
                        {...register("insurerCode", {
                          required: "Previous Policy Insurer is required",
                        })}
                        className="form-select formStep8PolicyInsurerSelect"
                        id="idxx3"
                        defaultValue={previousInsurerDetails?.value || ""}
                      >
                        <option className="d-none" value="">
                          Previous Policy Insurer
                        </option>
                        {insurerData &&
                          insurerData.map((item, i) => (
                            <option key={i} value={item.value}>
                              {item.option}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="idxx3" className="did-floating-label">
                        Select Previous Policy Insurer
                      </label>
                    </div>
                    <p className="f-error text-danger">
                      {errors?.insurerCode?.message}
                    </p>
                  </div>

                  {/* Previous Policy Expiry Date */}
                  {motorRequest?.isVehicleNew === false && (
                    <div className="did-floating-label-content">
                      <DatePicker
                        {...register("prevPolicyEndDate", {
                          required: "Previous Policy Expiry Date is required",
                        })}
                        selected={prevPolicyDate}
                        onChange={handlePrevPolicyEndDate}
                        customInput={
                          <ReactInputMask
                            mask="99/99/9999"
                            placeholder="DD/MM/YYYY"
                            value={
                              prevPolicyDate
                                ? moment(prevPolicyDate).format("DD/MM/YYYY")
                                : ""
                            }
                            onChange={handleInputChange}
                            alwaysShowMask={false}
                          />
                        }
                        name="prevPolicyEndDate"
                        id="idxx6"
                        className="did-floating-input"
                        minDate={
                          watch("businessType") ===
                          POLICY_STATUS.EXPIREDWITHIN90
                            ? moment().subtract(90, "days").toDate()
                            : moment().toDate()
                        }
                        maxDate={moment().add(180, "days").toDate()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Previous Policy Expiry Date"
                      />
                      <label htmlFor="idxx6" className="did-floating-label">
                        Previous Policy Expiry Date
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error text-danger">
                        {errors?.prevPolicyEndDate?.message}
                      </p>
                    </div>
                  )}
                </div>

                {/* Submit Button */}
                <button type="submit" className="modalBtnSubmit mb-2">
                  {submit ? "Getting Quotes..." : "Submit and View Plans"}
                </button>
              </form>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VehicleDetailsModal;
