import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WhyUs from "./common/WhyUs";
import WhyChooseUs from "./common/WhyChooseUs";
import OurPartner from "./common/OurPartner";
import FAQ from "./common/FAQ";
import Footer from "./common/Footer";
import Products from "./common/Products";
import BetterLife from "./common/BetterLife";
import Sliders from "./common/Sliders";
import Support from "./common/Support";
import FastestInsurance from "./common/FastestInsurance";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetHealthQuickQuote,
  dispatchResetMotorQuote,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  dispatchUserDetails,
  resetQuickQuoteResults,
  resetUserDetails,
} from "../store/action/userActions";
import Layout from "./common/Layout";
import {
  getEmployeeDetails,
  getPreviousUserDetails,
} from "./services/TPApiCall";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toastFailure } from "./utility/constants";

const Index = () => {
  const [stepsData, setStepsData] = useState({});
  const [decodedString, setDecodedString] = useState("");
  const [employeeData, setEmployeeData] = useState(null);
  const [queryStringType, setQueryStringType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);

  const getEmployeeDetail = async () => {
    try {
      const empId = query.get("employeeId");
      if (empId) {
        const response = await getEmployeeDetails(empId);
        const employeeData = response?.data?.employeeData;
        console.log("response", response?.data?.employeeData);
        setEmployeeData(employeeData);
        for (let key in employeeData) {
          dispatchUserDetails(`${key}`, employeeData[key]);
          if (key === "username")
            dispatchMotorQuote("emplId", employeeData[key]);
          dispatchMotorQuote("source", "empl");
        }
      }
    } catch (error) {
      console.log("getEmployeeDetail Error", error);
      toastFailure("Failed to fetch employee details");
    }
  };

  useEffect(() => {
    // dispatch(resetUserDetails());
    dispatchResetHealthQuickQuote();
    dispatchResetProcessFlowMotor();
    dispatchResetQuickQuote();
    dispatchResetMotorQuote();
    getEmployeeDetail();
  }, []);

  // useEffect(() => {
  //   dispatchResetHealthQuickQuote();
  //   dispatchResetQuickQuote();
  //   dispatchResetMotorQuote();

  //   const queryString = window.location.search;

  //   if (queryString === "") {
  //     setIsLoading(false);
  //   }

  //   if (queryString !== "") {
  //     const params = new URLSearchParams(queryString);
  //     const decodeData = {};
  //     setQueryStringType(true);

  //     for (const [key, value] of params.entries()) {
  //       decodeData[decodeURIComponent(key)] = decodeURIComponent(value);
  //     }
  //     const values = Object.values(decodeData);
  //     setDecodedString(values.join(""));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (decodedString !== "") {
  //     getPreviousUserDetails({
  //       quotationNumber: decodedString,
  //     }).then((response) => {
  //       if (
  //         response?.success &&
  //         response?.message === "Data for customer process!!"
  //       ) {
  //         const stepsData = JSON.parse(response?.data?.stepsData);

  //         setStepsData(stepsData);
  //       }
  //     });
  //   }
  // }, [decodedString]);

  // useEffect(() => {
  //   if (queryStringType) {
  //     if (Object.keys(stepsData).length > 0) {
  //       handleContinueJourney();
  //     }
  //   }
  // }, [stepsData]);

  // const handleContinueJourney = () => {
  //   const stepData = stepsData?.processFlow;
  //   const requestData = { ...stepData.apiRequestQQ };

  //   for (let key in stepData?.apiRequestQQ) {
  //     if (key === "VariantCode") {
  //       for (let key in stepData?.apiRequestQQ?.VariantCode) {
  //         dispatchQuickQuote(
  //           `VariantCode.${key}`,
  //           stepData?.apiRequestQQ?.VariantCode[key]
  //         );
  //       }
  //     } else if (key === "ApiUniqueNumber") {
  //       for (let key in stepData?.apiRequestQQ?.ApiUniqueNumber) {
  //         dispatchQuickQuote(
  //           `ApiUniqueNumber.${key}`,
  //           stepData?.apiRequestQQ?.ApiUniqueNumber[key]
  //         );
  //       }
  //     } else if (key === "KYC") {
  //       for (let key in stepData?.apiRequestQQ?.KYC) {
  //         dispatchQuickQuote(`KYC.${key}`, stepData?.apiRequestQQ?.KYC[key]);
  //       }
  //     } else {
  //       dispatchQuickQuote(`${key}`, stepData?.apiRequestQQ[key]);
  //     }
  //   }

  //   if (stepData?.step4?.variantSelection?.Cubic_Capacity !== "") {
  //     dispatchQuickQuote(
  //       "CubicCapacity",
  //       stepData?.step4?.variantSelection?.Cubic_Capacity
  //     );
  //     dispatchQuickQuote(
  //       "FuelType",
  //       stepData?.step4?.variantSelection?.Fuel_Type
  //     );
  //     dispatchQuickQuote(
  //       "VariantName",
  //       stepData?.step4?.variantSelection?.label
  //     );
  //   }
  //   if (stepData?.step5?.customerDetails?.customer_name) {
  //     const nameParts =
  //       stepData?.step5?.customerDetails?.customer_name.split(" ");

  //     if (nameParts.length > 2) {
  //       dispatchQuickQuote("FirstName", nameParts[0]);
  //       dispatchQuickQuote("MiddleName", nameParts[1]);
  //       dispatchQuickQuote("LastName", nameParts[2]);
  //     } else {
  //       dispatchQuickQuote("FirstName", nameParts[0]);
  //       dispatchQuickQuote("LastName", nameParts[1]);
  //     }
  //     dispatchQuickQuote(
  //       "MobileNumber",
  //       stepData?.step5?.customerDetails?.customer_phone
  //     );
  //     dispatchQuickQuote(
  //       "Email",
  //       stepData?.step5?.customerDetails?.customer_email
  //     );
  //     dispatchQuickQuote("CustomerType", requestData?.CustomerType);
  //     dispatchQuickQuote(
  //       "PreviousOwnerChanged",
  //       requestData?.PreviousOwnerChanged
  //     );
  //   }

  //   if (
  //     stepData &&
  //     stepData?.step6?.pypSelection &&
  //     Object.keys(stepData?.step6?.pypSelection)
  //   ) {
  //     for (let key in stepData?.step6?.pypSelection) {
  //       dispatchQuickQuote(`${key}`, stepData?.step6?.pypSelection[key]);
  //     }
  //   }
  //   if (stepData?.step === "step1") {
  //     dispatchQuickQuote("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     setIsLoading(false);
  //     navigate("/motor", {
  //       state: {
  //         activeStep: 2,
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //       },
  //     });
  //   } else if (stepData?.step === "step2") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     setIsLoading(false);
  //     navigate("/motor", {
  //       state: {
  //         activeStep: 3,
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else if (stepData?.step === "step3") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     setIsLoading(false);

  //     navigate("/motor", {
  //       state: {
  //         activeStep: 4,
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else if (stepData?.step === "step4") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     dispatchProcessFlowMotor(
  //       "step4.variantSelection",
  //       stepData.step4.variantSelection
  //     );
  //     setIsLoading(false);
  //     navigate("/motor", {
  //       state: {
  //         activeStep: 6,
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else if (stepData?.step === "step5") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     dispatchProcessFlowMotor(
  //       "step4.variantSelection",
  //       stepData.step4.variantSelection
  //     );
  //     dispatchProcessFlowMotor(
  //       "step5.customerDetails",
  //       stepData.step5.customerDetails
  //     );
  //     setIsLoading(false);
  //     navigate("/motor", {
  //       state: {
  //         activeStep: 8,
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else if (stepData?.step === "step6") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     dispatchProcessFlowMotor(
  //       "step4.variantSelection",
  //       stepData.step4.variantSelection
  //     );
  //     dispatchProcessFlowMotor(
  //       "step5.customerDetails",
  //       stepData.step5.customerDetails
  //     );
  //     dispatchProcessFlowMotor(
  //       "step6.pypSelection",
  //       stepData.step6.pypSelection
  //     );
  //     setIsLoading(false);
  //     navigate("/quotelist", {
  //       state: {
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else if (stepData?.step === "step7") {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchQuickQuote("NCBSET", false);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     dispatchProcessFlowMotor(
  //       "step4.variantSelection",
  //       stepData.step4.variantSelection
  //     );
  //     dispatchProcessFlowMotor(
  //       "step5.customerDetails",
  //       stepData.step5.customerDetails
  //     );
  //     dispatchProcessFlowMotor(
  //       "step6.pypSelection",
  //       stepData.step6.pypSelection
  //     );
  //     dispatch(resetQuickQuoteResults());
  //     setIsLoading(false);
  //     navigate("/quotelist", {
  //       state: {
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   } else {
  //     dispatchProcessFlowMotor("quotationNumber", stepData.quotationNumber);
  //     dispatchQuickQuote("NCBSET", false);
  //     dispatchProcessFlowMotor(
  //       "step1.registerationNumber",
  //       stepData.step1.registerationNumber
  //     );
  //     dispatchProcessFlowMotor("step2.make", stepData.step2.make);
  //     dispatchProcessFlowMotor("step3.model", stepData.step3.model);
  //     dispatchProcessFlowMotor(
  //       "step4.variantSelection",
  //       stepData.step4.variantSelection
  //     );
  //     dispatchProcessFlowMotor(
  //       "step5.customerDetails",
  //       stepData.step5.customerDetails
  //     );
  //     dispatchProcessFlowMotor(
  //       "step6.pypSelection",
  //       stepData.step6.pypSelection
  //     );
  //     dispatch(resetQuickQuoteResults());
  //     setIsLoading(false);
  //     navigate("/quotelist", {
  //       state: {
  //         journeyContinue: true,
  //         vehicleModal: requestData?.vehicleModal,
  //         vehicleType: requestData?.VehicleType,
  //         requestData: requestData,
  //       },
  //     });
  //   }
  // };

  // return setIsLoading && string !== "" ? (
  //   <div id="loaderSvgWrapper">
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="200px"
  //       height="200px"
  //       viewBox="0 0 200 200"
  //       id="preloader"
  //     >
  //       <circle cx="100" cy="100" r="3" id="red" />
  //       <circle cx="100" cy="100" r="8" id="orange" />
  //       <circle cx="100" cy="100" r="13" id="yellow" />
  //       <circle cx="100" cy="100" r="18" id="green" />
  //     </svg>
  //   </div>
  // ) : (
  // <div class="spinner-border text-success" role="status">
  //   <span class="sr-only"></span>
  // </div>
  return (
    <Layout
    // handleContinueJourney={handleContinueJourney}
    >
      {/* ------------------------ */}
      <Products decodedString={decodedString} />
      {/* ------------------------ */}
      <BetterLife />
      {/* ------------------------ */}
      <WhyUs />
      {/*---------------------------*/}
      <WhyChooseUs />
      {/*---------------------------*/}
      <Sliders />
      {/*---------------------------*/}
      <Support />
      {/*---------------------------*/}
      <FastestInsurance />
      {/*---------------------------*/}
      <FAQ />
      <OurPartner />
    </Layout>
  );
};

export default Index;
