import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container footer">
        <footer
          className="row row-cols-1 row-cols-sm-2 footer-inner"
          style={{
            paddingTop: "2rem",
            marginTop: ".5rem",
          }}
        >
          <div className="col-sm-12 col-md-5 col-lg-5 mb-5 mb-md-3 mb-lg-3 footer-left">
            <img src="/assets/images/insurefast-footer-logo.svg" />
            <p className="justify_para">
              Insure Fast Insurance Web Aggregator Private Limited is one of the
              prominent Insurance Intermediary, customarily from the retail
              clients paired along with its Pan India presence and also balanced
              with the finest blend of Life and Non-Life Insurance business.
              From the desk of Director.
            </p>
            <h5>Download the App on</h5>
            <div className="app">
              <img src="/assets/images/google-play-store-btn.svg" />
              <img src="/assets/images/apple-store-btn.svg" />
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 mb-3">
            <h5>Quick Links</h5>
            <ul className="nav flex-column mt-4">
              <li className="nav-item footer-link-items mb-2">
                <Link to="/" className="nav-link p-0 ">
                  Home
                </Link>
              </li>
              <li className="nav-item footer-link-items mb-2">
                <Link to="/contact-us" className="nav-link p-0 ">
                  Contact Us
                </Link>
              </li>
              <li className="nav-item footer-link-items mb-2">
                <Link to="/about-us" className="nav-link p-0 ">
                  About Us
                </Link>
              </li>
              <li className="nav-item footer-link-items mb-2">
                <Link to="/privacy-policy" className="nav-link p-0 ">
                  Privacy Policy{" "}
                </Link>
              </li>
              <li className="nav-item footer-link-items mb-2">
                <a href="/terms-conditions" className="nav-link p-0 ">
                  Terms & Conditions
                </a>
              </li>
              <li className="nav-item footer-link-items mb-2">
                <a
                  href="https://insurefast.in/license/License.pdf"
                  target="_blank"
                  className="nav-link p-0 "
                >
                  IRDAI License
                </a>
              </li>
              {/* <li className="nav-item footer-link-items mb-2">
                <a href="#" className="nav-link p-0 ">
                  FAQ
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4 mb-3">
            <h5>Support On</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-4 mt-4 d-flex">
                <div className="footer-section-circle-1">
                  <img src="/assets/images/loction icon.png" />
                </div>
                <div className="footer-link-right">
                  <p>Location</p>
                  <p>
                    {" "}
                    601, 6th Floor, Brij Anukampa Tower, Ashok Marg, C-Scheme,
                    Jaipur, Rajasthan, 302001
                  </p>
                </div>
              </li>
              <li className="nav-item mb-4 d-flex">
                <div className="footer-section-circle">
                  <img src="/assets/images/mail.png" />
                </div>
                <div className="footer-link-right">
                  <p>Email Address</p>
                  <p>support@insurefast.in</p>
                </div>
              </li>
              <li className="nav-item mb-4 d-flex">
                <div className="footer-section-circle">
                  <img src="/assets/images/call icon.png" />
                </div>
                <div className="footer-link-right">
                  <p>Phone</p>
                  <p>+91-9485948516</p>
                </div>
              </li>
            </ul>
          </div>
        </footer>
        <p className="footer-last-content">
          Discount is offered by the Insurance company as approved by IRDAI for
          the product under File & Use guidelines. <br /> On the basis of your
          profile
          <br />
          Website Approved by IRDAI(Govt of India) Reg No.
          "IRDAI/INT/WBA80/2023" Valid till: "04/07/2026" <br />
          ISO 9001:2015 : EGQ/2312IA/1500
          <br /> Insurance is the subject matter of solicitation. Visitors are
          hereby informed that their information submitted on the website may be
          shared with insurers. The product information for comparison displayed
          on this website is of the insurers with whom our company has an
          agreement. Product information is authentic and solely based on the
          information received from the Insurer. <br />
          The information provided on this website/page is for information
          purpose only. Insure Fast does not in any form or manner endorse the
          information so provided on the website and strives to provide factual
          and unbiased information to customers to assist in making informed
          insurance choices. <br />
          ©️ 2023 Insure Fast Insurance Web Aggregators Pvt. Ltd. All rights
          reserved. Insure Fast Logo are registered trademark.
        </p>
      </div>
    </div>
  );
};

export default Footer;
