import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

const PaymentPage = () => {

  useEffect(() => {
    // Code to submit the form when the component mounts
    const form = document.forms['PAYMENTFORM']
    if (form) {
      form.submit()
    }
  }, []) // Empty dependency array ensures the effect runs only once

  return (
        <form
          action='https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run'
          name='PAYMENTFORM'
          method='post'
        >
          <div>
            <input type='hidden' name='proposalNum' value='1120005996989' />
            <input
              type='hidden'
              name='returnURL'
              value='http://localhost:3000'
            />
          </div>
        </form>
  )
}

export default PaymentPage
