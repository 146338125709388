export const LOGGED_USER_DETAILS = "LOGGED_USER_DETAILS";
export const USER_DATA = "USER_DATA";
export const RESET_USER_DATA = "RESET_USER_DATA";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";
export const QUICK_QUOTE_PAYLOAD = "QUICK_QUOTE_PAYLOAD";
export const QUICK_QUOTE_RESULTS = "QUICK_QUOTE_RESULTS";
export const HEALTH_QUICK_QUOTE_PAYLOAD = "HEALTH_QUOTE_PAYLOAD";
export const HEALTH_QUICK_QUOTE_RESULTS = "HEALTH_QUOTE_RESULTS";
export const PET_QUICK_QUOTE_PAYLOAD = "PET_QUICK_QUOTE_PAYLOAD";
export const PET_QUICK_QUOTE_RESULT = "PET_QUICK_QUOTE_RESULT";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const MOTOR_QUOTE_PAYLOAD = "MOTOR_QUOTE_PAYLOAD";
export const RESET_MOTOR_QUOTE_PAYLOAD = "RESET_MOTOR_QUOTE_PAYLOAD";
export const KYC_CONFIG = "KYC_CONFIG";
export const RESET_KYC_CONFIG = "RESET_KYC_CONFIG";

export const PROCESS_FLOW_MOTOR = "PROCESS_FLOW_MOTOR";
export const PROCESS_FLOW_HEALTH = "PROCESS_FLOW_HEALTH";

export const RESET_PROCESS_FLOW_MOTOR = "RESET_PROCESS_FLOW_MOTOR";
export const RESET_PROCESS_FLOW_HEALTH = "RESET_PROCESS_FLOW_HEALTH";

export const RESET_QUICK_QUOTE_PAYLOAD = "RESET_QUICK_QUOTE_PAYLOAD";
export const RESET_QUICK_QUOTE_RESULTS = "RESET_QUICK_QUOTE_RESULTS";
export const RESET_HEALTH_QUICK_QUOTE_PAYLOAD = "RESET_HEALTH_QUOTE_PAYLOAD";
export const RESET_HEALTH_QUICK_QUOTE_RESULTS = "RESET_HEALTH_QUOTE_RESULTS";
export const RESET_PET_QUICK_QUOTE_PAYLOAD = "RESET_PET_QUICK_QUOTE_PAYLOAD";
export const RESET_PET_QUICK_QUOTE_RESULT = "RESET_PET_QUICK_QUOTE_RESULT";
export const RESET_SELECTED_PLAN = "RESET_SELECTED_PLAN";
export const INSPECTION_QUICK_QUOTE = "INSPECTION_QUICK_QUOTE";

export const API_ERROR_RESPONSE = "API_ERROR_RESPONSE";
export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE";
