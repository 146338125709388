import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import { Link, useNavigate } from "react-router-dom";
import { PostData, PostDataWithToken } from "../../api/apiHelper";
import {
  dispatchUserData,
  dispatchUserDetails,
} from "../../store/action/userActions";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { size } from "lodash";
import SignUpModal from "./SignUpModal";
import { useCookies } from "react-cookie";
import { checkEmployee, employeeLogin } from "../services/TPApiCall";
function LoginModel({ show, setModel, setSignUpModal }) {
  const toggleLoginModel = () => setModel(!show);
  const [select, setSelect] = useState("");
  const [bonus, setBonus] = useState(0);
  const [mobile, setMobile] = useState();
  const [loginOtpModal, setLoginOtpModal] = useState(false);
  const [otp, setOtp] = useState();
  const [signUpFirst, setSignUpFirst] = useState(false);
  const [wrongOtp, setWrongOtp] = useState(false);
  const [error, setError] = useState(null);
  const [resendBtn, setResendBtn] = useState(false);
  const userDetailsObj = useSelector((state) => state?.root?.userDetails);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [cookie, setCookie] = useCookies(["data"]);
  const [empErrorMessage, setEmpErrorMessage] = useState(null);
  const [isEmployee, setIsEmployee] = useState(false);

  const navigate = useNavigate();

  const validateMobile = (value) => {
    const mobileRegex = /^[6-9]\d{0,9}$/;
    const isValid = mobileRegex.test(value);
    setIsValidMobile(isValid);
    return isValid;
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    if (validateMobile(value) || value === "") {
      handleLogin(value);
    }
  };

  const handleLogin = async (number) => {
    setSignUpFirst(false);
    let obj = {
      api_status: "sendOtp",
      mobileNumber: number,
      otp: "",
    };

    let loginDataForEmployee = {
      isVerified: false,
      mobileNumber: number,
    };

    if (number.length === 10) {
      const checkEmployeeResponse = await checkEmployee(number);
      console.log("checkEmployee", checkEmployeeResponse);
      if (
        checkEmployeeResponse?.status === "success" &&
        checkEmployeeResponse?.data?.isEmployeeUser
      ) {
        setIsEmployee(true);
        const employeeLoginResponse = await employeeLogin(loginDataForEmployee);
        console.log("employeeLoginResponse", employeeLoginResponse);
        if (
          employeeLoginResponse?.status === "success" &&
          employeeLoginResponse?.data?.message === "OTP Sent Successfully !!"
        ) {
          setLoginOtpModal(true);
          dispatchUserDetails("Phone", number);
        } else if (employeeLoginResponse?.status === "failure") {
          setSignUpFirst(true);
          employeeLoginResponse?.errors.map((error) =>
            setEmpErrorMessage(error.displayMessage)
          );
        }
      } else {
        setIsEmployee(false);
        const response = await PostDataWithToken("auth/loginCustomer", obj);

        if (
          response?.success &&
          response?.message === "OTP Sent Successfully !!"
        ) {
          setLoginOtpModal(true);
          dispatchUserDetails("Phone", number);
        } else if (response?.data?.success === false) {
          setSignUpFirst(true);
        }
      }
    }
  };

  const verifyLoginOtp = async () => {
    let obj1 = {
      api_status: "verify",
      mobileNumber: userDetailsObj.Phone,
      otp: otp,
    };

    let loginDataForEmployee = {
      isVerified: true,
      mobileNumber: userDetailsObj.Phone,
      otp: otp,
    };

    try {
      const res = isEmployee
        ? await employeeLogin(loginDataForEmployee)
        : await PostDataWithToken("auth/loginCustomer", obj1);
      if (isEmployee) {
        console.log("res isEmployee", res);
        if (
          res?.status === "success" &&
          res?.data?.message === "Login Successfully"
        ) {
          dispatchUserDetails("username", res?.data?.data?.username);
          dispatchUserDetails("name", res?.data?.data?.name);
          dispatchUserDetails("email", res?.data?.data?.email);
          dispatchUserDetails("mobile_no", res?.data?.data?.mobile_no);

          toggleLoginModel();
          navigate("/");
        } else if (res?.data?.status === "failure") {
          res?.data.errors.map((error) => {
            error?.displayMessage === "Please enter Valid Otp"
              ? setWrongOtp(true)
              : setError(error?.displayMessage);
          });
          // setWrongOtp(true);
        }
      } else {
        console.log("res", res);
        if (res.success) {
          const data = res?.data[0];

          dispatchUserDetails("FullName", data?.customer_name);
          dispatchUserDetails("Gender", data?.customer_gender);
          dispatchUserDetails("Email", data?.customer_email);
          dispatchUserDetails("Phone", data?.customer_phone);
          dispatchUserDetails("DateofBirth", data?.dateOfBirth);
          dispatchUserDetails("MaritalStatus", data?.marital_status);
          dispatchUserDetails("City", data?.city);
          dispatchUserDetails(
            "CustomerUniqueNumber",
            data?.customer_unique_number
          );
          dispatchUserData("FullName", data?.customer_name);
          dispatchUserData("Gender", data?.customer_gender);
          dispatchUserData("Email", data?.customer_email);
          dispatchUserData("Phone", data?.customer_phone);
          dispatchUserData("DateofBirth", data?.dateOfBirth);
          dispatchUserData("MaritalStatus", data?.marital_status);
          dispatchUserData("City", data?.city);
          dispatchUserData(
            "CustomerUniqueNumber",
            data?.customer_unique_number
          );
          toggleLoginModel();
          navigate("/");
        } else if (
          res?.data?.success === false &&
          res?.data?.message === "please enter Valid Otp"
        ) {
          setWrongOtp(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const [signUpModal, setSignUpModal] = useState(false);
  const handleSignUp = () => {
    toggleLoginModel();
    setSignUpModal(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setResendBtn(true);
    }, 30000);
  }, [resendBtn]);

  useEffect(() => {
    if (isEmployee && otp && otp.length === 6) {
      verifyLoginOtp();
    } else if (!isEmployee && otp && otp.length === 4) {
      verifyLoginOtp();
    }
    // if (otp && otp.length === 4) {
    //   verifyLoginOtp();
    // }
  }, [otp, verifyLoginOtp]);

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggleLoginModel}
        centered
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          <div class="p-3">
            <div class="gsc_modalWrapper">
              {loginOtpModal === false ? (
                <>
                  <div className="modal-logo">
                    <a>
                      <img src="/assets/images/insurefastlogos.svg"></img>
                    </a>
                  </div>
                  <div className="modal_heading w-100 mb-4">
                    <h4 className="text-center">Login with Mobile Number</h4>
                    <div onClick={toggleLoginModel} class="v2_close false">
                      <a>
                        <img
                          src="/assets/images/delete_icon.svg"
                          title="Close"
                          alt="close"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="did-floating-label-content">
                    <input
                      type="number"
                      className={`did-floating-input ${
                        isValidMobile ? "" : "invalid"
                      }`}
                      placeholder=" "
                      onChange={(e) => {
                        let value = e.target.value.trim();
                        if (
                          value.startsWith("0") ||
                          value.startsWith("1") ||
                          value.startsWith("2") ||
                          value.startsWith("3") ||
                          value.startsWith("4") ||
                          value.startsWith("5")
                        ) {
                          value = value.substring("1");
                        }
                        e.target.value = value;
                        setMobile(e?.target?.value);
                        handleChange(e);
                        // handleLogin(e?.target?.value);
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 10);
                      }}
                      defaultValue={mobile}
                    />
                    <label className="did-floating-label">Mobile Number</label>
                    {!isValidMobile && mobile.length > 0 && (
                      <span className="text-danger">
                        Enter a valid mobile number
                      </span>
                    )}
                  </div>
                  {signUpFirst && (
                    <p className="text-danger">
                      {empErrorMessage
                        ? empErrorMessage
                        : "You are not a registered user. You have to Sign Up first"}
                    </p>
                  )}
                  {/* <div class="v2_btngrp mb-3">
                    <div class="wrapper">
                      <button
                        onClick={handleLogin}
                        name="submitBtn"
                        class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                      >
                        Send OTP
                      </button>
                    </div>
                  </div> */}
                  <div className="d-flex justify-content-center align-items-center sign_text">
                    <span style={{ fontSize: "18px" }}>
                      First time user?{" "}
                      <p
                        onClick={() => handleSignUp()}
                        style={{
                          color: "green",
                          textDecoration: "none",
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                      >
                        Sign up
                      </p>
                    </span>
                  </div>
                </>
              ) : loginOtpModal === true ? (
                <>
                  <div className="modal-logo">
                    <a>
                      <img src="/assets/images/insurefastlogos.svg"></img>
                    </a>
                  </div>
                  <div className="modal_heading w-100 mb-4">
                    <h4 className="text-center fs-6">
                      Please Enter OTP sent on {mobile}
                    </h4>
                    <p
                      className="text-center fw-bold"
                      onClick={() => setLoginOtpModal(false)}
                      style={{ color: "#59b67c", cursor: "pointer" }}
                    >
                      Edit Number
                    </p>
                    <div onClick={toggleLoginModel} class="v2_close false">
                      <a>
                        <img
                          src="/assets/images/delete_icon.svg"
                          title="Close"
                          alt="close"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="mb-3">
                    {/* <h6 class='table bold'>Please enter OTP</h6> */}
                    <div className="d-flex w-100 justify-content-center align-items-center">
                      <OtpInput
                        value={otp}
                        inputStyle={{
                          borderRadius: "5px",
                          // width: '25%',
                          height: "50px",
                          width: "50px",
                          border: "1px solid black",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                          gap: "10px",
                        }}
                        containerStyle={
                          {
                            // width: '70%'
                          }
                        }
                        onChange={setOtp}
                        numInputs={isEmployee ? 6 : 4}
                        renderSeparator={<span>-</span>}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                  </div>
                  {wrongOtp && (
                    <p className="text-danger text-center">
                      {error ? error : "Please Enter a valid OTP"}
                    </p>
                  )}
                  <div class="v2_btngrp mb-3">
                    <div class="wrapper">
                      {resendBtn && (
                        <p class="central-login-Receive-OTP text-center">
                          Didn't receive the OTP yet?
                          <span
                            className="ms-1 fw-bold"
                            style={{ color: "#59b67c", cursor: "pointer" }}
                            onClick={() => {
                              handleLogin(mobile);
                              setResendBtn(false);
                            }}
                          >
                            Resend
                          </span>
                        </p>
                      )}

                      {/* <button
                        onClick={verifyLoginOtp}
                        name="submitBtn"
                        class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                      >
                        Verify OTP
                      </button> */}
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-center align-items-center sign_text">
                    <span>
                      First time user?{" "}
                      <p
                        onClick={() => handleSignUp()}
                        style={{
                          color: "green",
                          textDecoration: "none",
                          fontSize: "18px",
                          fontWeight: "600",
                          display: "inline-block",
                        }}
                      >
                        Sign up
                      </p>
                    </span>
                  </div> */}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default LoginModel;
