import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="create-wealth-outer">
      <div className="container">
        <div className="create-wealth">
          <div className="text-center mb-3">
            <h4 className="create-wealth-heading">Why choose us</h4>
            <img
              className="create-wealth-img "
              src="/assets/images/underlline.png"
            />
            {/* <h2 className="create-wealth-heading-2 mt-2 mb-5">
            Life Insurance That <br /> <span>Creates Wealth</span> For Everyone
          </h2> */}
          </div>
          <div className="create-wealth-content d-flex">
            <div
              className="create-wealth-card text-center"
              style={{ background: "#e0f3f8", border: "1px solid #e1f4f8" }}
            >
              <img
                src="/assets/images/UNBIASED ADVICE.png"
                className="card-img-top"
                alt="..."
              />
              <div className="create-wealth-body mt-3">
                <h5 className="create-wealth-title">
                  Unbiased Advice from Experts
                </h5>
                {/* <p className="create-wealth-desc">
                Secure your future with our comprehensive insurance solutions
                that ensure you're set for life.
              </p> */}
              </div>
            </div>
            <div
              className="create-wealth-card text-center"
              style={{ background: "#fde5e7", border: "1px solid #fde5e7" }}
            >
              <img
                src="/assets/images/TRUSTED PARTNER.png"
                className="card-img-top"
                alt="..."
              />
              <div className="create-wealth-body mt-3 mb-3">
                <h5 className="create-wealth-title">
                  Trusted Insurance Partners
                </h5>
                {/* <p className="create-wealth-desc">
                Secure your future with our comprehensive insurance solutions
                that ensure you're set for life.
              </p> */}
              </div>
            </div>
            <div
              className="create-wealth-card text-center"
              style={{ background: "#f9f6d7", border: "1px solid #f9f6d7" }}
            >
              <img
                src="/assets/images/FREE CLAIM ASSISTANT.png"
                className="card-img-top"
                alt="..."
              />
              <div className="create-wealth-body mt-3 mb-3">
                <h5 className="create-wealth-title">Free Claims Assistance</h5>
                {/* <p className="create-wealth-desc">
                Secure your future with our comprehensive insurance solutions
                that ensure you're set for life.
              </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
