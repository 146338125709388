import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const BetterLife = () => {
  var settingsInsuranceSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="lifeBetter">
      <div className="container">
        <div className="lifeBetter-inner">
          <div className="lifeBetter-inner2">
            <h2 className="lifeBetter-text">
              Insurance Makes <br />
              <span>Life Better </span>
            </h2>
            <p className="justify_para">
              Insurance enhances life by offering financial protection and peace
              of mind. Whether through health, life, auto, or property
              insurance, these policies act as a safety net against unexpected
              events, alleviating the financial burden and reducing stress.
            </p>
          </div>
          <div className="d-flex lifeBetterCards">
            <Slider
              className="betterlifeslider-inner"
              {...settingsInsuranceSlider}
            >
              <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/health.svg"} alt="" />
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/bike.svg"} alt="" />
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/car (1).svg"} alt="" />
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/pet.svg"} alt="" />
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/travel.svg"} alt="" />
                  </Link>
                </div>
              </div>

              {/* <div className="">
                <div className="sliderText">
                  <Link to="">
                    <img src={"/assets/images/health.jpeg"} alt="" />
                  </Link>
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BetterLife;
