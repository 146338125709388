import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";
import {
  dispatchHealthQuickQuote,
  dispatchMotorQuote,
  dispatchProcessFlowHealth,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  dispatchResetHealthQuickQuote,
  dispatchResetProcessFlow,
  dispatchResetProcessFlowHealth,
  dispatchResetProcessFlowMotor,
  dispatchResetQuickQuote,
  dispatchUserData,
  dispatchUserDetails,
  resetHealthQuickQuoteResults,
  resetProcessFlowMotor,
  resetQuickQuoteResults,
  resetSelectedPlan,
} from "../../store/action/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehiclePincode,
  getVehiclePincodeHealth,
  getVehiclePreviousInsurer,
  getVehicleRto,
} from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelect";
import { Modal, ModalBody } from "reactstrap";
import {
  getPreviousJourneyDetails,
  getPreviousUserDetails,
  getQuoteCount,
  getRC,
  processFlow,
} from "../services/TPApiCall";
import moment from "moment";
import { InputMask } from "react-input-mask";
import { PostDataWithToken } from "../../api/apiHelper";
import HealthModal from "../Health/HealthModal";
import {
  FOUR_WHEELER_VEHICLE_TYPE,
  POLICY_STATUS,
  TWO_WHEELER_VEHICLE_TYPE,
  VEHICLE_TYPE,
  caseStatus,
} from "../utility/constants";
import CryptoJS from "crypto-js";
import { useCookies } from "react-cookie";

const Products = ({ decodedString }) => {
  const [healthModal, setHealthModal] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm({ mode: "onBlur" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedBox, setSelectedBox] = useState("");
  const [pincodeData, setPincodeData] = useState([]);
  const [insurerData, setInsurerData] = useState([]);
  const [age, setAge] = useState([]);
  const [filteredPincodes, setFilteredPincodes] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const [retentionModal, setRetentionModal] = useState(false);
  const toggleRetentionModal = () => setRetentionModal(!retentionModal);
  const [currentSteps, setCurrentSteps] = useState(1);
  const [rcName, setRCName] = useState("");
  const [vType, setVtype] = useState({ vehivleType: null, vehicleModel: null });
  const [isBharatSeries, setIsBharatSeries] = useState(false);
  const [RegistrationNumber, setRegistrationNumner] = useState("");
  const [rtoData, setRtoData] = useState([]);
  const [isNewVehicle, setIsNewVehicle] = useState(false);
  const [stepsData, setStepsData] = useState({});
  const [previousProcessJourney, setPreviousProcessJourney] = useState({
    processDescription: "",
    processStep: "",
    customerLeadId: "",
  });
  const [queryStringType, setQueryStringType] = useState(false);
  const [apiRequestQQForProcess, setApiRequestQQForProcess] = useState({});
  const [loadingHome, setLoadingHome] = useState(false);
  const [vTypeForAadrila, setVtypeForAadrila] = useState({
    vehivleType: null,
    vehicleModel: null,
    activeStep: null,
  });

  const [submit, setSubmit] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["customerLeadId"]);

  const healthRequestQQ = useSelector((state) => state.root.healthRequestQQ);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjHealth = useSelector(
    (state) => state?.root?.processFlowObjHealth
  );
  const userDetails = useSelector((state) => state?.root?.userDetails);

  const params = new URLSearchParams(window.location.search);
  console.log("PARAMSSSS", params.get("employeeId"));

  // useEffect(() => {
  //   // setQueryStringType(false);

  //   getVehiclePincodeHealth()
  //     .then((response) => {
  //       if (response.status === true) {
  //         let i = 0;
  //         let arr = [];
  //         let arr1 = [];
  //         let j = 0;
  //         while (i < response.data.length) {
  //           arr.push({
  //             value: response.data[i].Pincode,
  //             label: response.data[i].Pincode,
  //             District: response.data[i].District,
  //             City: response.data[i].City,
  //             State: response.data[i].State,
  //           });

  //           i++;
  //         }
  //         while (j < response.data.length) {
  //           arr1.push({
  //             value: response.data[j].Pin_Code,
  //             label: response.data[j].District,
  //             District: response.data[j].District,
  //             City: response.data[j].City,
  //           });

  //           j++;
  //         }
  //         setPincodeData(arr);
  //         // setCityData(arr1);
  //       }
  //     })
  //     .catch((err) => console.log(err));

  //   dispatch(resetHealthQuickQuoteResults());
  //   dispatch(resetSelectedPlan());
  //   dispatchResetProcessFlowMotor();
  //   dispatchResetProcessFlowHealth();

  //   const queryString = window.location.search;
  //   // console.log("queryString", queryString);

  //   // if (queryString !== "") {
  //   //   setLoadingHome(true);
  //   // }

  //   // let decodedString = "";
  //   // if (queryString !== "") {
  //   //   const params = new URLSearchParams(queryString);
  //   //   const decodeData = {};
  //   //   setQueryStringType(true);

  //   //   for (const [key, value] of params.entries()) {
  //   //     decodeData[decodeURIComponent(key)] = decodeURIComponent(value);
  //   //   }
  //   //   const values = Object.values(decodeData);
  //   //   decodedString = values.join("");
  //   // }

  //   // let LeadId = localStorage.getItem("leadId");
  //   // if (decodedString === "") {
  //   //   getPreviousUserDetails({
  //   //     quotationNumber: decodedString ? decodedString : LeadId,
  //   //   }).then((response) => {
  //   //     console.log("RESPONSE", response);
  //   //     if (
  //   //       response?.success &&
  //   //       response?.message === "Data for customer process!!"
  //   //     ) {
  //   //       const stepsData = response?.data?.stepsData;
  //   //       console.log("stepsData", stepsData);
  //   //       setStepsData(stepsData);
  //   //       const apiRequest = response?.data?.apiRequestQQ;
  //   //       setApiRequestQQForProcess(apiRequest);
  //   //       console.log("stepsData", stepsData, apiRequest);
  //   //     }
  //   //   });
  //   // }

  //   // if (cookies.customerLeadId) {
  //   //   getPreviousJourneyDetails({
  //   //     customerLeadId: cookies.customerLeadId,
  //   //   }).then((response) => {
  //   //     console.log("getPreviousJourneyDetails response", response);
  //   //     if (response?.data?.processStep !== "step1") {
  //   //       dispatchMotorQuote("customerLeadId", cookies.customerLeadId);
  //   //       setRetentionModal(true);
  //   //       setStepsData(response?.data?.stepsData);
  //   //       setPreviousProcessJourney({
  //   //         processDescription: response?.data?.processDiscription,
  //   //         processStep: response?.data?.processStep,
  //   //         customerLeadId: response?.data?.customerLeadId,
  //   //       });
  //   //     }
  //   //   });
  //   // }
  //   // setTimeout(() => {
  //   //   if (queryString === "" && LeadId) {
  //   //     // setRetentionModal(true);
  //   //   }
  //   // }, [2000]);
  // }, []);

  // useEffect(() => {
  //   debugger;
  //   console.log("queryStringType", queryStringType);
  //   if (queryStringType) {
  //     if (Object.keys(stepsData).length > 0) {
  //       handleContinueJourney();
  //     }
  //   }
  // }, [stepsData]);

  // useEffect(() => {
  //   getVehicleRto().then((response) => {
  //     if (response.status === "success") {
  //       let data = response.data;
  //       let i = 0;
  //       let arr = [];
  //       while (i < data.length) {
  //         let item = data[i];
  //         arr.push({
  //           label: item.registered_city_name + " (" + item.RTO_Code + ")",
  //           value: item.RTO_Code,
  //           registered_state_name: item.registered_state_name,
  //           id: item.id,
  //           registered_city_name: item.registered_city_name,
  //         });
  //         i++;
  //       }

  //       setRtoData(arr);
  //     }
  //   });
  // }, []);

  function validateRegNo() {
    var e = RegistrationNumber;
    let t,
      o = e.split("-");
    o &&
      o.length > 2 &&
      o[o.length - 1].length < 4 &&
      !isNaN(o[o.length - 1]) &&
      (3 == o[o.length - 1].length &&
        (o[o.length - 1] = `0 ${o[o.length - 1]}`),
      2 == o[o.length - 1].length &&
        (o[o.length - 1] = `00 ${o[o.length - 1]}`),
      (e = o.join("-")));
    let a = e.split("-");
    var n = !0;
    return (
      0 ==
      ("BH" == e.slice(3, 5)
        ? /^[\d]{2}BH[\d]{4}[A-Z]{1,2}$/
        : (t =
            a[a.length - 1].length < 4
              ? /^[A-Z]{2}[0-9]{1,3}[A-Z]{0,3}[0-9]{5}$/
              : /^[A-Z]{2}[0-9]{1,3}[A-Z]{0,3}[0-9]{4}$/)
      ).test(e.replace(/-/g, "").toUpperCase())
        ? (setTimeout(() => {
            // loggigOnPageBrokenOrError("Registrationno_error", e);
          }, 3e3),
          document
            .querySelector("#error_car_number")
            .classList.remove("d-none"),
          document.querySelector("#error_car_number").classList.add("d-block"),
          (n = !1))
        : (document
            .querySelector("#error_car_number")
            .classList.remove("d-block"),
          document.querySelector("#error_car_number").classList.add("d-none")),
      n
    );
  }

  const formatReg = (e) => {
    var t = e;
    t = t.replace(/-/g, "");
    var o = new Array(4);
    (o[0] = t.slice(0, 2)), (o[1] = ""), (o[2] = ""), (o[3] = "");
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8));
    } catch (e) {}
    return o
      .join("-")
      .replace(/ /g, "")
      .replace(/--/g, "-")
      .replace(/-\s*$/, "")
      .replace(/[^- 0-9 a-z A-z]/g, "");
  };

  const setRegistrationValue = (e) => {
    var message = document.getElementById("message");
    if (isBharatSeries === true) {
      const regex = /^(0[1-9]|[1-9][0-9])\sBH\s\d{4}\s[A-Z]{2}$/;
      if (regex.test(e.target.value)) {
        message.innerHTML = "";
        setRegistrationNumner(e.target.value);
      } else {
        message.innerHTML =
          "The Bharat series number format is XXBH####YY(12BH1234AB)";
      }
    } else {
      if (navigator.userAgent.match(/UCBrowser/)) return !1;
      var t = document.querySelector("#vinpuut").value,
        o = e.keyCode;
      if (
        -1 != navigator.userAgent.indexOf("MSIE") ||
        1 == !!document.documentMode
      ) {
        if (32 == o || 8 == o) return !1;
        t.length >= 3 && (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector("#vinpuut").value = t.toUpperCase());
      } else
        setTimeout(function () {
          if (32 == o || 8 == o) return !1;
          (t = document.querySelector("#vinpuut").value).length >= 3 &&
            (t = formatReg(t)),
            t.length >= 5 && (t = formatReg(t)),
            t.length >= 7 && (t = formatReg(t)),
            (document.querySelector("#vinpuut").value = t.toUpperCase());
        }, 100);
      setRegistrationNumner(t);
    }
  };

  const submitRegistrationNumber = () => {
    // var message1 = document.getElementById("message");
    // if (0 == validateRegNo()) return;
    // if (RegistrationNumber == "") {
    //   return (message1.innerHTML = "Please enter registration number");
    // }
    // var message = document.getElementById("message1");
    // if (mobileNumber == "") {
    //   return (message.innerHTML = "Please enter mobile number");
    // }
    // if (!mobileNumber.match(/^[6-9]\d{9}$/)) {
    //   return (message.innerHTML = "Invalid mobile number");
    // }
    dispatch(resetQuickQuoteResults());
    dispatch(resetProcessFlowMotor());
    // localStorage.clear();
    getPolicyTenuare();
    let rtocode;
    if (!isBharatSeries) {
      if (RegistrationNumber.includes("-")) {
        rtocode = RegistrationNumber.substring(0, 5)
          .replaceAll("-", "")
          .toUpperCase();
      } else {
        rtocode = RegistrationNumber.substring(0, 4).toUpperCase();
      }
      let index = rtoData.findIndex((item) => item.value === rtocode);
      dispatchQuickQuote(
        "RegistrationCity",
        rtoData[index]?.registered_city_name.toUpperCase()
      );
      // dispatchQuickQuote("State", rtoData[index]?.registered_state_name);
      dispatchQuickQuote("IsVehicleNew", false);
      dispatchQuickQuote("RTOCityName", rtocode);
      dispatchMotorQuote(
        "rtoCityName",
        rtoData[index]?.registered_city_name.toUpperCase()
      );
      dispatchMotorQuote("rtoCode", rtocode);
    }
    setIsNewVehicle(false);

    setValue("rc_no", RegistrationNumber);
    dispatchQuickQuote("RegistrationNumber", RegistrationNumber.toUpperCase());
    dispatchUserData("RegistrationNumber", RegistrationNumber.toUpperCase());
    // toggleModal();
    // toggleRtoModal()
    dispatchQuickQuote("IsVehicleNew", false);
    // apiRequestQQ.PolicyType === "Renewal" ? toggleTab(activeTab + 2) : toggleTab(activeTab + 1);
  };

  const getPolicyTenuare = () => {
    if (isNewVehicle === true && vehicleType == "2w") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(5, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else if (isNewVehicle === true && vehicleType == "4w") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(3, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    }
  };

  const handlePincodeChange = (val) => {
    const newData = { ...healthRequestQQ };

    dispatchHealthQuickQuote("CustomerDetails.customerPincode", val.value);

    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.pincode",
      val.value
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.pincode",
      val.value
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.city",
      val.City
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.communicationAddress.state",
      val.State
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.city",
      val.City
    );
    dispatchHealthQuickQuote(
      "CustomerDetails.customerAddress.permanentAddress.state",
      val.State
    );
  };

  useEffect(() => {}, [selectedBox === "Pvt Car", selectedBox === "MotorBike"]);

  const handleBoxClick = (box) => {
    box === "health"
      ? dispatch(resetQuickQuoteResults())
      : dispatch(resetHealthQuickQuoteResults());
    const currentBox = box;
    let currentVehicleType;
    if (box === "MotorBike") {
      currentVehicleType = "2w";
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE.TWO_WHEELER);
    } else if (box === "Pvt Car") {
      currentVehicleType = "4w";
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE.FOUR_WHEELER);
    }

    setSelectedBox(currentBox);
    if (box === "MotorBike" || box === "Pvt Car") {
      navigate("/motor", {
        state: {
          selectedVehicleType: {
            vehicleModal: currentBox,
            vehicleType: currentVehicleType,
          },
          activeStep: 0,
        },
      });
    } else if (box === "health") {
      navigate("/health");
    }
  };

  const generateSessionToken = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const tokenLength = 32;
    let token = "";

    for (let i = 0; i < tokenLength; i++) {
      token += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return token;
  };

  const submitQuoteDetails = (data) => {
    let registrationNumberWithoutHyphen;
    if (selectedBox !== "health") {
      registrationNumberWithoutHyphen = data?.rc_no.replace(/-/g, "");
    }

    let newData = { ...healthRequestQQ };
    dispatchResetProcessFlowHealth();
    dispatchResetProcessFlowMotor();

    if (selectedBox === "health") {
      dispatch(resetQuickQuoteResults());
      dispatchResetQuickQuote();
      getQuoteCount().then((response) => {
        if (
          response.success === true &&
          response.message === "Quote Count Fetched"
        ) {
          if (response?.dataCount === 0) {
            const today = new Date().toLocaleDateString("en-GB");
            const [day, month, year] = today.split("/");

            const paddedDay = day.padStart(2, "0");
            const paddedMonth = month.padStart(2, "0");

            const formattedDate = paddedDay + paddedMonth + year;

            var newQuotationNumber = `INFST${formattedDate}0001`;

            dispatchHealthQuickQuote("quotationNumber", newQuotationNumber);
            dispatchProcessFlowHealth("quoteType", "health");
            dispatchProcessFlowHealth("quotationNumber", newQuotationNumber);
            dispatchProcessFlowHealth(
              "loggedInUser",
              userDetails?.Phone && userDetails?.CustomerUniqueNumber
                ? true
                : false
            );
            if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
              for (let key in userDetails) {
                dispatchProcessFlowHealth(
                  `customerDetails.${key}`,
                  userDetails[key]
                );
              }
            }
          } else {
            const newCount = response?.dataCount + 1;
            const today = new Date().toLocaleDateString("en-GB");
            const [day, month, year] = today.split("/");

            const paddedDay = day.padStart(2, "0");
            const paddedMonth = month.padStart(2, "0");

            const formattedDate = paddedDay + paddedMonth + year;

            const paddedCount = newCount.toString().padStart(4, "0");

            var newQuotationNumber = `INFST${formattedDate}${paddedCount}`;

            dispatchHealthQuickQuote("quotationNumber", newQuotationNumber);
            dispatchProcessFlowHealth("quoteType", "health");
            dispatchProcessFlowHealth("quotationNumber", newQuotationNumber);
            dispatchProcessFlowHealth(
              "loggedInUser",
              userDetails?.Phone && userDetails?.CustomerUniqueNumber
                ? true
                : false
            );
            if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
              for (let key in userDetails) {
                dispatchProcessFlowHealth(
                  `customerDetails.${key}`,
                  userDetails[key]
                );
              }
            }
          }
        }
        dispatchProcessFlowHealth("step1", data);
        const newDataa = {
          ...processFlowObjHealth,
          quotationNumber: newQuotationNumber,
          step: "step1",
          quoteType: "health",
          loggedInUser:
            userDetails?.Phone && userDetails?.CustomerUniqueNumber
              ? true
              : false,
          step1: data,
        };
        processFlow(newDataa).then((response) => {
          console.log("RESPONSE", response);
        });
      });
      // dispatchHealthQuickQuote("state", "health");
      // dispatchQuickQuote("state", "");
      for (let key in data) {
        const stateKey = `CustomerDetails.${key}`;
        newData = {
          ...newData,
          [stateKey]: data[key],
        };

        dispatchHealthQuickQuote(stateKey, data[key]);
        navigate("/health");
      }
    } else if (selectedBox === "MotorBike") {
      dispatchQuickQuote("state", "MotorBike");
      dispatchProcessFlowMotor("quoteType", "motor");
      dispatchProcessFlowMotor(
        "loggedInUser",
        userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false
      );
      if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
        for (let key in userDetails) {
          dispatchProcessFlowMotor(`customerDetails.${key}`, userDetails[key]);
        }
      }
      dispatchQuickQuote("vehicleModal", "MotorBike");
      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER,
        },
        step: "step1",
        quoteType: "motor",
        processDiscription: "registrationNumber",
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: data?.rc_no,
          vehicleType: VEHICLE_TYPE.TWO_WHEELER,
        },
      };
      processFlow(newDataa).then((response) => {
        console.log("RESPONSE", response);
        if (
          response?.status === "success" &&
          response?.data?.message === "process started!!"
        ) {
          dispatchMotorQuote(
            "customerLeadId",
            response?.data?.data?.customerLeadId
          );
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", response?.data?.data?.customerLeadId, {
            expires,
          });
        }
      });
      dispatchQuickQuote("RegistrationNumber", data?.rc_no);
      dispatchQuickQuote("VehicleType", "2w");
      dispatchMotorQuote("registrationNumber", registrationNumberWithoutHyphen);
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE?.TWO_WHEELER);
      dispatchProcessFlowMotor("step1.registrationNumber", data?.rc_no);
      dispatchProcessFlowMotor("step", "step1");
      dispatchResetHealthQuickQuote();
      setRCName(data?.rc_no);

      setRCName(data?.rc_no);
      navigate("/motor", {
        state: { vTypeForAadrila, journeyContinue: false },
      });
      // getRC(newData).then((response) => {
      //   console.log("response getRC", response);
      //   if (
      //     response?.data?.success === false &&
      //     response?.data?.message === "not able to fetch registration number"
      //   ) {
      //     navigate("/motor", { state: vTypeForAadrila });
      //   } else {
      //     setModal(true);
      //   }
      // });

      // if (data.rc_no !== "") setModal(true);

      // setModal(true);
    } else if (selectedBox === "Pvt Car") {
      dispatchProcessFlowMotor("quoteType", "motor");
      dispatchProcessFlowMotor(
        "loggedInUser",
        userDetails?.Phone && userDetails?.CustomerUniqueNumber ? true : false
      );
      if (userDetails?.Phone && userDetails?.CustomerUniqueNumber) {
        for (let key in userDetails) {
          dispatchProcessFlowMotor(`customerDetails.${key}`, userDetails[key]);
        }
      }
      dispatchQuickQuote("vehicleModal", "Pvt Car");
      const newDataa = {
        ...processFlowObjMotor,
        motorRequest: {
          ...motorRequest,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER,
        },
        step: "step1",
        processDiscription: "registrationNumber",
        quoteType: "motor",
        loggedInUser:
          userDetails?.Phone && userDetails?.CustomerUniqueNumber
            ? true
            : false,
        step1: {
          registrationNumber: data?.rc_no,
          vehicleType: VEHICLE_TYPE.FOUR_WHEELER,
        },
      };
      processFlow(newDataa).then((response) => {
        if (
          response?.status === "success" &&
          response?.data?.message === "process started!!"
        ) {
          dispatchMotorQuote(
            "customerLeadId",
            response?.data?.data?.customerLeadId
          );
          const now = new Date();
          // Set the cookie to expire in 2 hours
          const expires = new Date(now.getTime() + 2 * 60 * 60 * 1000);
          setCookie("customerLeadId", response?.data?.data?.customerLeadId, {
            expires,
          });
        }
      });

      dispatchQuickQuote("RegistrationNumber", data?.rc_no);
      dispatchMotorQuote("registrationNumber", registrationNumberWithoutHyphen);
      dispatchQuickQuote("VehicleType", "4w");
      dispatchMotorQuote("vehicleType", VEHICLE_TYPE?.FOUR_WHEELER);
      dispatchProcessFlowMotor("step1.registerationNumber", data?.rc_no);
      dispatchProcessFlowMotor("step", "step1");
      dispatchResetHealthQuickQuote();
      setRCName(data?.rc_no);
      console.log("vTypeForAadrila -", vTypeForAadrila);
      navigate("/motor", {
        state: { vTypeForAadrila, journeyContinue: false },
      });
      // getRC(newData).then((response) => {
      //   console.log("response getRC", response);
      //   if (
      //     response?.data?.success === false &&
      //     response?.data?.message === "not able to fetch registration number"
      //   ) {
      //     navigate("/motor", { state: vTypeForAadrila });
      //   } else {
      //     setModal(true);
      //   }
      // });

      // if (data.rc_no !== "") setModal(true);
    }
    //  else if (selectedBox === "Pet") {
    //   setPetModal(true);
    // }

    // const getRtoDetailsFromRegNumber = () => {
    //   let Data = {
    //     rc_no: RegistrationNumber,
    //   };
    //   getRto(Data).then((response) => {
    //     if (response?.success) {
    //       setVehicleData(response?.data?.data);
    //       dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    //       dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    //       dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    //       dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    //       toggleRtoModal();
    //     }
    //   });
    // };
  };

  const ageRange = () => {
    const age = [];
    for (let i = 18; i <= 99; i++) {
      age.push(i);
    }
    setAge(age);
  };

  useEffect(() => {
    ageRange();
  }, []);

  const handleUserDetails = (data) => {
    dispatchResetHealthQuickQuote();

    let newData = {
      FirstName: "",
      MiddleName: "",
      LastName: "",
      // Email: data.Email,
      MobileNumber: data.MobileNumber,
    };
    const nameParts = data.FullName.split(" ");

    if (nameParts.length > 0) {
      newData.FirstName = nameParts[0];
    }

    if (nameParts.length > 2) {
      newData.MiddleName = nameParts[1];
    }

    if (nameParts.length > 1) {
      newData.LastName = nameParts[nameParts.length - 1];
    }

    for (let key in newData) {
      dispatchQuickQuote(key, newData[key]);
    }

    // setCurrentSteps(currentSteps + 1);
    setSubmit(true);
    setTimeout(() => {
      setSubmit(false);
      navigate("/quotelist");
    }, 1000);
  };

  const setVehicleType = (vehicleType, vehicleModel) => {
    const updatedVType = {
      vehicleModal: vehicleModel,
      vehicleType: vehicleType,
    };
    navigate("/motor", {
      state: { updatedVType, journeyContinue: false, rtoData: rtoData },
    });
    setVtype(updatedVType);
  };

  const setVehicleTypeAadrila = (vehicleType, vehicleModel, stepNumber) => {
    const updatedVType = {
      vehicleModal: vehicleModel,
      vehicleType: vehicleType,
      stepNumber: stepNumber,
    };
    // navigate("/motor", {
    //   state: updatedVType,
    // });
    setVtypeForAadrila(updatedVType);
  };

  const handleNotKnownVehicleDetails = () => {
    navigate("/motor", { state: { vTypeForAadrila, journeyContinue: false } });
  };

  const getFullName = (data) => {
    if (data?.MiddleName) {
      return `${data?.FirstName} ${data?.MiddleName} ${data?.LastName}`;
    } else {
      return `${data?.FirstName} ${data?.LastName}`;
    }
  };

  const handleMoreDetails = (data) => {
    for (let key in data) {
      dispatchQuickQuote(`${key}`, data[key]);
    }
    if (data?.PreInsurerCode === "0") {
      dispatchQuickQuote("IsPreviousInsurerKnown", false);
    } else {
      dispatchQuickQuote("IsPreviousInsurerKnown", true);
    }

    if (data?.isClaimLastYear) {
      dispatchQuickQuote("PreviousNoClaimBonus", 0);
    }
    let newData = {
      enquiryId: apiRequestQQ?.enquiryId,
      rc_no: rcName,
      customerDetails: {
        customerName: getFullName(apiRequestQQ),
        customerMobileNumber: apiRequestQQ?.MobileNumber,
        customerType: "Individual",
      },
      isClaimLastYear: apiRequestQQ?.PreviousInsuranceClaimed,
      previousPolicyDetails: {
        previousPolicyInsurer: data?.prePolicyInsurer,
        previousInsurerKnown: apiRequestQQ?.IsPreviousInsurerKnown,
        previousPolicyEndDate: data?.PrePolicyEndDate,
        previousPolicyType: data?.PreviousPolicyType,
        previousPolicyNumber: "",
        previousNoClaimBonus: data?.PreviousNoClaimBonus,
      },
    };
  };

  const dateMask1 = {
    mask: "AA 00 XX****",
    placeholder: "XX YY ZZ xxxx",
    definitions: {
      A: {
        validator: (char) => /[A-Z]/.test(char),
      },
      X: {
        validator: (char) => /[A-HJ-NP-Z]/.test(char),
      },
      0: {
        validator: (char) => /\d/.test(char),
      },
    },
  };

  const [vehicleNumber, setVehicleNumber] = useState("");
  const [isValid, setIsValid] = useState(true);

  // const handleInputChange = (event) => {
  //   const inputValue = event.target.value;
  //   const regex = /^[A-Za-z0-9\s]*$/;

  //   // Check if the input matches the regex pattern
  //   const isValidInput = regex.test(inputValue);
  //   setIsValid(isValidInput);

  //   // Update the state only if the input is valid or empty
  //   if (isValidInput || inputValue === "") {
  //     setVehicleNumber(inputValue);
  //   }
  // };

  const handleInputChange = (e) => {
    const reg = /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/;
    // debugger;
    if (reg.test(e.currentTarget.value)) {
      setVehicleNumber(e.currentTarget.value);
    }
  };

  const handleContinueJourney = () => {
    // debugger;
    if (
      previousProcessJourney?.processStep === "step1" &&
      previousProcessJourney?.processDescription === "registrationNumber"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );
      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      navigate("/motor", {
        state: {
          activeStep: 2,
          journeyContinue: true,
          vehicleType: stepsData?.step1?.vehicleType,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step2" &&
      previousProcessJourney?.processDescription === "makeSelection"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );

      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      dispatchMotorQuote("makeId", stepsData?.step2?.make?.value);
      dispatchMotorQuote("makeName", stepsData?.step2?.make?.label);
      navigate("/motor", {
        state: {
          activeStep: 3,
          journeyContinue: true,
          vehicleType: stepsData?.step1?.vehicleType,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step3" &&
      previousProcessJourney?.processDescription === "modelSelection"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );
      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      dispatchMotorQuote("makeId", stepsData?.step2?.make?.value);
      dispatchMotorQuote("makeName", stepsData?.step2?.make?.label);
      dispatchMotorQuote("modelId", stepsData?.step3?.model?.value);
      dispatchMotorQuote("modelName", stepsData?.step3?.model?.label);
      navigate("/motor", {
        state: {
          activeStep: 4,
          journeyContinue: true,
          vehicleType: stepsData?.step1?.vehicleType,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step4" &&
      previousProcessJourney?.processDescription === "variantSelection"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );
      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      dispatchMotorQuote("makeId", stepsData?.step2?.make?.value);
      dispatchMotorQuote("makeName", stepsData?.step2?.make?.label);
      dispatchMotorQuote("modelId", stepsData?.step3?.model?.value);
      dispatchMotorQuote("modelName", stepsData?.step3?.model?.label);
      dispatchMotorQuote(
        "variantId",
        stepsData?.step4?.variantSelection?.value
      );
      dispatchMotorQuote(
        "variantName",
        stepsData?.step4?.variantSelection?.label
      );
      dispatchMotorQuote(
        "fuelType",
        stepsData?.step4?.variantSelection?.fuelType
      );
      navigate("/motor", {
        state: {
          activeStep: 5,
          journeyContinue: true,
          vehicleType: stepsData?.step1?.vehicleType,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step5" &&
      previousProcessJourney?.processDescription === "customerDetails"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );
      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      dispatchMotorQuote("makeId", stepsData?.step2?.make?.value);
      dispatchMotorQuote("makeName", stepsData?.step2?.make?.label);
      dispatchMotorQuote("modelId", stepsData?.step3?.model?.value);
      dispatchMotorQuote("modelName", stepsData?.step3?.model?.label);
      dispatchMotorQuote(
        "variantId",
        stepsData?.step4?.variantSelection?.value
      );
      dispatchMotorQuote(
        "variantName",
        stepsData?.step4?.variantSelection?.label
      );
      dispatchMotorQuote(
        "fuelType",
        stepsData?.step4?.variantSelection?.fuelType
      );
      for (let key in stepsData?.step5?.customerDetails) {
        dispatchUserDetails(`${key}`, stepsData?.step5?.customerDetails[key]);
      }
      let newData = {
        firstName: "",
        middleName: "",
        lastName: "",
      };
      const nameParts =
        stepsData?.step5?.customerDetails.customer_name.split(" ");

      if (nameParts.length > 0) {
        newData.firstName = nameParts[0];
      }

      if (nameParts.length > 2) {
        newData.middleName = nameParts[1];
      }

      if (nameParts.length > 1) {
        newData.lastName = nameParts[nameParts.length - 1];
      }

      for (let key in newData) {
        dispatchMotorQuote(`${key}`, newData[key]);
      }
      dispatchMotorQuote(
        "email",
        stepsData?.step5?.customerDetails?.customer_email
      );
      dispatchMotorQuote(
        "mobileNumber",
        stepsData?.step5?.customerDetails?.customer_phone
      );

      dispatchMotorQuote("customerType", stepsData?.step5?.customerType);
      dispatchMotorQuote("isOwnerChanged", stepsData?.step5?.isOwnerChanged);
      dispatchMotorQuote(
        "manufaturingDate",
        stepsData?.step5?.manufaturingDate
      );
      navigate("/motor", {
        state: {
          activeStep: 8,
          journeyContinue: true,
          vehicleType: stepsData?.step1?.vehicleType,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step6" &&
      previousProcessJourney?.processDescription === "pypSelection"
    ) {
      dispatchMotorQuote(
        "registrationNumber",
        stepsData?.step1?.registrationNumber.replace(/-/g, "")
      );
      dispatchMotorQuote(
        "rtoCode",
        stepsData?.step1?.registrationNumber.replace(/-/g, "").substring(0, 4)
      );
      dispatchMotorQuote("vehicleType", stepsData?.step1?.vehicleType);
      dispatchMotorQuote("makeId", stepsData?.step2?.make?.value);
      dispatchMotorQuote("makeName", stepsData?.step2?.make?.label);
      dispatchMotorQuote("modelId", stepsData?.step3?.model?.value);
      dispatchMotorQuote("modelName", stepsData?.step3?.model?.label);
      dispatchMotorQuote(
        "variantId",
        stepsData?.step4?.variantSelection?.value
      );
      dispatchMotorQuote(
        "variantName",
        stepsData?.step4?.variantSelection?.label
      );
      dispatchMotorQuote(
        "fuelType",
        stepsData?.step4?.variantSelection?.fuelType
      );
      for (let key in stepsData?.step5?.customerDetails) {
        dispatchUserDetails(`${key}`, stepsData?.step5?.customerDetails[key]);
      }
      let newData = {
        firstName: "",
        middleName: "",
        lastName: "",
      };
      const nameParts =
        stepsData?.step5?.customerDetails.customer_name.split(" ");

      if (nameParts.length > 0) {
        newData.firstName = nameParts[0];
      }

      if (nameParts.length > 2) {
        newData.middleName = nameParts[1];
      }

      if (nameParts.length > 1) {
        newData.lastName = nameParts[nameParts.length - 1];
      }

      for (let key in newData) {
        dispatchMotorQuote(`${key}`, newData[key]);
      }
      dispatchMotorQuote(
        "email",
        stepsData?.step5?.customerDetails?.customer_email
      );
      dispatchMotorQuote(
        "mobileNumber",
        stepsData?.step5?.customerDetails?.customer_phone
      );
      dispatchMotorQuote("customerType", stepsData?.step5?.customerType);
      dispatchMotorQuote("isOwnerChanged", stepsData?.step5?.isOwnerChanged);
      dispatchMotorQuote(
        "manufaturingDate",
        stepsData?.step5?.manufaturingDate
      );
      for (let key in stepsData?.step6?.pypSelection) {
        dispatchMotorQuote(`${key}`, stepsData?.step6?.pypSelection[key]);
      }
      dispatch(resetQuickQuoteResults());
      navigate(`/quotelist/${previousProcessJourney?.customerLeadId}`);
    } else if (
      previousProcessJourney?.processStep === "step7" &&
      previousProcessJourney?.processDescription === "quotation"
    ) {
      for (let key in stepsData?.step7?.motorRequest) {
        dispatchMotorQuote(`${key}`, stepsData?.step7?.motorRequest[key]);
      }
      for (let key in stepsData?.step7?.previousClaimData) {
        dispatchMotorQuote(`${key}`, stepsData?.step7?.previousClaimData[key]);
      }

      dispatch(resetQuickQuoteResults());
      navigate(`/quotelist/${previousProcessJourney?.customerLeadId}`);
    } else if (
      previousProcessJourney?.processStep === "step8" &&
      previousProcessJourney?.processDescription === "kycAndUserDetails"
    ) {
      navigate(`/proposal/${cookies?.customerLeadId}`, {
        state: {
          previousProcessJourney: previousProcessJourney,
          stepsData: stepsData,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step9" &&
      previousProcessJourney?.processDescription === "nomineeDetails"
    ) {
      navigate(`/proposal/${cookies?.customerLeadId}`, {
        state: {
          previousProcessJourney: previousProcessJourney,
          stepsData: stepsData,
        },
      });
    } else if (
      previousProcessJourney?.processStep === "step10" &&
      previousProcessJourney?.processDescription === "vehicleDetails"
    ) {
      if (
        stepsData?.step10?.vehicleDetails?.proposalStatus ===
        caseStatus.proposalSuccess
      ) {
        navigate(`/checkout/${cookies?.customerLeadId}`, {
          state: {
            previousProcessJourney: previousProcessJourney,
            stepsData: stepsData,
          },
        });
      } else {
        navigate(`/proposal/${cookies?.customerLeadId}`, {
          state: {
            previousProcessJourney: previousProcessJourney,
            stepsData: stepsData,
          },
        });
      }
    }
  };

  return (
    <div className="container">
      <div className="d-flex hero-container">
        <div className="d-flex flex-column border justify-content-around insuranceBox">
          <div className="bestInsurance">
            <h2 className="">
              Let's find <span>Best Insurance</span>
            </h2>
          </div>
          <div className="d-flex insuranceCards justify-content-between align-items-center flex-wrap">
            {/* Health */}
            <div
              className={``}
              style={{ position: "relative" }}
              onClick={() => {
                // handleBoxClick("health");
                // setHealthModal(true);
              }}
            >
              <div
                className={`rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center ${
                  selectedBox === "health" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/cardiogram 1.png" />
                <p className="text-center">Health</p>
                <div className="coming-soon rounded">
                  <span>Coming Soon</span>
                </div>
              </div>
            </div>

            {/* Bike */}
            <div
              className={``}
              onClick={() => {
                handleBoxClick("MotorBike");
              }}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "MotorBike" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/bycicle.svg" />
                <p className="text-center">Bike</p>
              </div>
            </div>

            {/* Car */}
            <div
              className=""
              onClick={() => {
                handleBoxClick("Pvt Car");
              }}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "Pvt Car" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/car 1.svg" />
                <p className="text-center">Car</p>
              </div>
            </div>

            {/* Family */}
            <div
              className=""
              style={{ position: "relative" }}
              // onClick={() => handleBoxClick("family")}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "family" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/family.svg" />
                <p className="text-center">Family</p>
                <div className="coming-soon rounded">
                  <span>Coming Soon</span>
                </div>
              </div>
            </div>

            {/* Taxi */}
            {/* <div
              className=""
              style={{ position: "relative" }}
              // onClick={() => handleBoxClick("taxi")}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "taxi" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/taxi.svg" />
                <p className="text-center">Taxi</p>
                <div className="coming-soon rounded">
                  <span>Coming Soon</span>
                </div>{" "}
              </div>
            </div> */}

            {/* Pet */}
            <div
              className=""
              style={{ position: "relative" }}
              // onClick={() => handleBoxClick("pet")}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "pet" ? "selected" : ""
                }`}
                // onClick={() => navigate("/dog-health")}
              >
                <span style={{ textDecoration: "none", color: "#000" }}>
                  <img src="/assets/images/pet-house.svg" />
                  <p className="text-center">Pet</p>
                  <div className="coming-soon rounded">
                    <span>Coming Soon</span>
                  </div>{" "}
                </span>
              </div>
            </div>

            {/* Travel */}
            <div
              className=""
              style={{ position: "relative" }}
              // onClick={() => handleBoxClick("travel")}
            >
              <div
                className={` rounded bikeInsuranceBox d-flex flex-column justify-content-center align-items-center  ${
                  selectedBox === "travel" ? "selected" : ""
                }`}
              >
                <img src="/assets/images/airplane.png" />
                <p className="text-center">Travel</p>
                <div className="coming-soon rounded">
                  <span>Coming Soon</span>
                </div>{" "}
              </div>
            </div>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit(submitQuoteDetails)}>
            {selectedBox === "health" && (
              <div className="inputBox-motor inputBox-motor-inner row mt-3">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="did-floating-label-content">
                    <div className="add-class">
                      {/* css-1n6nu92-control */}
                      <select
                        name="customerRelation"
                        {...register("customerRelation", {
                          required: "Field is required",
                        })}
                        className="form-select form-control-inner"
                        id="idxx2"
                      >
                        <option value="">For Whom</option>
                        <option value="Self">Self</option>
                        {/* <option value="Self">Spouse</option> */}
                        {/* <option value="Self">Son</option> */}
                        {/* <option value="Self">Daughter</option> */}
                        {/* <option value="Self">Mother</option> */}
                        {/* <option value="Self">Father</option> */}
                      </select>
                      {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerRelation
                      </label> */}

                      {errors?.customerRelation?.message && (
                        <span className="text-danger">
                          {errors?.customerRelation?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="did-floating-label-content">
                    <div className="add-class">
                      <select
                        name="customerGender"
                        {...register("customerGender", {
                          required: "Field is required",
                        })}
                        className="form-select form-control-inner"
                        id="idxx2"
                      >
                        <option value="">Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerGender
                      </label> */}

                      {errors?.customerGender?.message && (
                        <span className="text-danger">
                          {errors?.customerGender?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="did-floating-label-content">
                    <div
                      className="add-class"
                      // className="form-floating position-relative undefined me-1"
                      // style={{ position: "relative", height: "100%" }}
                    >
                      <select
                        name="customerAge"
                        {...register("customerAge", {
                          required: "Field is required",
                        })}
                        className="form-select form-control-inner"
                        id="idxx2"
                      >
                        <option value="">Age</option>
                        {age?.map((item) => {
                          return (
                            <option value={item}>
                              {item} {"Years"}
                            </option>
                          );
                        })}
                      </select>
                      {/* <label htmlFor="idxx4" className="did-floating-label">
                        customerAge
                      </label> */}
                      {errors?.customerAge?.message && (
                        <span className="text-danger">
                          {errors?.customerAge?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="did-floating-label-content">
                    <div className="remove-class">
                      <ReactSelect
                        // className="form-select form-control-inner"
                        options={pincodeData}
                        onChange={(val) => handlePincodeChange(val)}
                        placeholder="Select  pincode"
                      />
                      {/* <label htmlFor="idxx4" className="did-floating-label">
                        Select area pincode
                      </label> */}
                    </div>
                  </div>
                </div>

                <div className="add-class">
                  <button
                    className="btn checkQuote checkQuote-health rounded"
                    type="submit"
                  >
                    Check Quote
                  </button>
                </div>
              </div>
              // <HealthModal show={healthModal} setHealthModal={setHealthModal} />
            )}
            {selectedBox === "MotorBike" && (
              <div className="inputBox-motor row mt-5">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input text-uppercase"
                        {...register("rc_no", {
                          required: "Field is required",
                          minLength: {
                            value: 10,
                            message: "Enter a Valid Number",
                          },
                        })}
                        id="vinpuut"
                        onChange={(e) => {
                          setRegistrationValue(e);
                          clearErrors("rc_no");
                        }}
                        placeholder=""
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Bike Number
                      </label>
                      {errors?.rc_no?.message && (
                        <span className="text-danger">
                          {errors?.rc_no?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      className="btn checkQuote rounded w-100"
                      type="submit"
                      // style={{ height: "100%" }}
                      onClick={() => {
                        setVehicleTypeAadrila("2w", "MotorBike", 2);
                        dispatch(resetHealthQuickQuoteResults());
                        submitRegistrationNumber();
                      }}
                    >
                      Check Quote
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      className="btn motorButton rounded text-truncate"
                      type="button"
                      // style={{ height: "100%" }}
                      onClick={() => setVehicleType("2w", "MotorBike")}
                    >
                      Don't have Bike Number?{" "}
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      className="btn motorButton rounded text-truncate"
                      type="button"
                      // style={{ height: "100%" }}
                      onClick={() => {
                        dispatchQuickQuote("IsVehicleNew", true);
                        dispatchMotorQuote("isVehicleNew", true);
                        dispatchMotorQuote("businessType", POLICY_STATUS.NEW);
                        dispatchQuickQuote("IsPreviousInsurerKnown", false);
                        dispatch(resetQuickQuoteResults());
                        setVehicleType("2w", "MotorBike");
                      }}
                    >
                      Buying a new Bike?
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedBox === "Pvt Car" && (
              <div className="inputBox-motor row mt-5">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <div className="did-floating-label-content">
                      {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Car Number"
                    {...register("rc_no", {
                      required: "Field is required",
                    })}
                    style={{ textTransform: "uppercase" }}
                    onChange={(e) => setRegistrationValue(e)}
                    aria-label="Example text with button addon"
                    aria-describedby="button-addon1"
                  /> */}
                      <input
                        type="text"
                        className="did-floating-input"
                        id="vinpuut"
                        {...register("rc_no", {
                          required: "Field is required",
                          minLength: {
                            value: 10,
                            message: "Enter a valid Number",
                          },

                          // pattern: {
                          //   value:
                          //     /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$|^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/,
                          //   message: "Invalid Registration Number",
                          // },
                        })}
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => {
                          setRegistrationValue(e);
                          clearErrors("rc_no");
                        }}
                        placeholder=""
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Car Number
                      </label>
                      {errors?.rc_no?.message && (
                        <span className="text-danger">
                          {errors?.rc_no?.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class mb-3 mb-sm-0 mb-md-0">
                    <button
                      className="btn checkQuote rounded w-100"
                      type="submit"
                      // style={{ height: "100%" }}
                      onClick={() => {
                        setVehicleTypeAadrila("4w", "Pvt Car", 2);
                        submitRegistrationNumber();
                      }}
                    >
                      Check Quote
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class mb-3 mb-sm-0 mb-md-0">
                    <button
                      className="btn motorButton rounded text-truncate"
                      type="button"
                      // style={{ height: "100%" }}
                      onClick={() => setVehicleType("4w", "Pvt Car")}
                    >
                      Don't have Car Number?{" "}
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      className="btn motorButton rounded text-truncate"
                      type="button"
                      // style={{ height: "100%" }}
                      onClick={() => {
                        dispatchQuickQuote("IsVehicleNew", true);
                        dispatchMotorQuote("isVehicleNew", true);
                        dispatchQuickQuote("IsPreviousInsurerKnown", false);
                        dispatchMotorQuote("businessType", POLICY_STATUS.NEW);
                        dispatch(resetQuickQuoteResults());
                        setVehicleType("4w", "Pvt Car");
                      }}
                    >
                      Buying a new Car?
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedBox === "taxi" && (
              <div className="inputBox-motor row mt-5">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input"
                        placeholder=""
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Taxi Number
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      // style={{ height: "100%" }}
                      className="btn checkQuote rounded"
                      type="submit"
                    >
                      Check Quote
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      // style={{ height: "100%" }}
                      className="btn motorButton rounded text-truncate"
                      type="submit"
                    >
                      Don't have Taxi Number?{" "}
                    </button>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <button
                      // style={{ height: "100%" }}
                      className="btn motorButton rounded text-truncate"
                      type="submit"
                    >
                      Buying a new Taxi?
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedBox === "pet" && (
              <div className="inputBox-motor row mt-5">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <div className="did-floating-label-content">
                      <input
                        type="text"
                        className="did-floating-input petBoxInput"
                        placeholder=""
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                        maxLength={50}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Owner Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <div className="add-class">
                    <div className="did-floating-label-content">
                      <input
                        type="number"
                        className="did-floating-input petBoxInput"
                        placeholder=""
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                        onInput={(e) =>
                          (e.target.value = e.target.value.slice(0, 10))
                        }
                        onChange={(e) => {
                          let value = e.target.value.trim();
                          if (
                            value.startsWith("0") ||
                            value.startsWith("1") ||
                            value.startsWith("2") ||
                            value.startsWith("3") ||
                            value.startsWith("4") ||
                            value.startsWith("5")
                          ) {
                            value = value.substring("1");
                          }
                          e.target.value = value;
                        }}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Contact Number
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-3">
                  <button
                    className="btn checkQuote rounded"
                    type="submit"
                    onClick={() => togglePetModal()}
                  >
                    Check Quote
                  </button>
                </div>
              </div>
            )}
            {selectedBox === "travel" && (
              <div
                className=" d-flex justify-content-center"
                // style={{ border: "1px solid #56b96c" }}
              >
                <h4
                  className="text-center mt-4 fs-4 rounded p-2"
                  style={{
                    border: "1px solid #56b96c",
                    color: "#56b96c",
                    boxShadow: "0px 0px 4px 0px #2add52",
                  }}
                >
                  We're gearing up to introduce Travel Insurance – Coming Soon!
                </h4>
              </div>
            )}
            {selectedBox === "family" && (
              <div
                className=" d-flex justify-content-center"
                // style={{ border: "1px solid #56b96c" }}
              >
                <h4
                  className="text-center mt-4 fs-4 rounded p-2"
                  style={{
                    border: "1px solid #56b96c",
                    color: "#56b96c",
                    boxShadow: "0px 0px 4px 0px #2add52",
                  }}
                >
                  We're gearing up to introduce Family Insurance – Coming Soon!
                </h4>
              </div>
            )}
          </form>
        </div>
        <div className="youthImage">
          <img src="assets/images/young.png" />
        </div>
      </div>
      {/* -----------------------------MODAL BOX-------------------------- */}
      <Modal
        isOpen={modal}
        className="car_modal"
        toggle={toggleModal}
        size="sm"
        backdrop="static"
        centered
        // className="vehicleInfoModal"
      >
        <ModalBody>
          {currentSteps === 1 && (
            <div className="modalBody">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-3">
                <h4 className="text-center">Did we get the right details?</h4>
                <div onClick={toggleModal} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="modalContent">
                <div className="leftContent">
                  <h5 className="vehicleNumber">
                    {rcName === "" ? "RJ52CA1597" : rcName.toUpperCase()}
                  </h5>
                  {selectedBox === "MotorBike" ? (
                    <p>HONDA CB SHINE SP125</p>
                  ) : (
                    <p>HYUNDAI GRAND i10</p>
                  )}
                  <p>
                    {/* GRAND i10 */}
                    {/* <FaDotCircle size={13} className="dot" /> */}
                  </p>
                  <p>
                    {selectedBox === "MotorBike" ? "2017" : "2018"}
                    <FaDotCircle size={13} className="dot" /> Petrol{" "}
                    {/* <FaDotCircle size={13} className="dot" /> */} <br />
                    {/* Jaipur */}
                  </p>
                </div>
                <div className="rightContent">
                  <img
                    src={
                      selectedBox === "MotorBike"
                        ? "/assets/images/BIKE PNG (1).png"
                        : "/assets/images/car-wash.png"
                    }
                  />
                </div>
              </div>
              <div className="modalBtn">
                <button
                  className="modalBtnYes"
                  onClick={() => {
                    selectedBox === "MotorBike" ? (
                      <>
                        {
                          (dispatchUserData("VehicleType", "2w"),
                          dispatchQuickQuote("VehicleType", "2w"))
                        }
                      </>
                    ) : (
                      <>
                        {
                          (dispatchUserData("VehicleType", "4w"),
                          dispatchQuickQuote("VehicleType", "4w"))
                        }
                      </>
                    );
                    setCurrentSteps(currentSteps + 1);
                  }}
                >
                  {selectedBox === "MotorBike"
                    ? "Yes, Its my Bike"
                    : "Yes, Its my Car"}
                </button>
                <button
                  className="modalBtnNo"
                  onClick={() => handleNotKnownVehicleDetails()}
                >
                  {selectedBox === "MotorBike"
                    ? "No, Its not my Bike"
                    : "No, Its not my Car"}
                </button>
              </div>
            </div>
          )}
          {currentSteps === 2 && (
            <div className="modalBody">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-3">
                <h4 className="text-center">Fill your Personal Details?</h4>
                <div onClick={toggleLoginModel} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
              <div className="modalContent">
                <div className="leftContent">
                  <h5 className="vehicleNumber">
                    {rcName ? rcName : "RJ45SA9490"}
                  </h5>
                  {selectedBox === "MotorBike" ? (
                    <p>HONDA CB SHINE SP125</p>
                  ) : (
                    <p>HYUNDAI GRAND i10</p>
                  )}
                </div>
                <div className="rightContent">
                  <img
                    src={
                      selectedBox === "MotorBike"
                        ? "/assets/images/BIKE PNG (1).png"
                        : "/assets/images/car-wash.png"
                    }
                  />
                </div>
              </div>
              <form
                onSubmit={handleSubmit1(handleUserDetails)}
                className="w-100"
              >
                <div className="">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className="userDetailsInput did-floating-input"
                      name="FullName"
                      {...register1("FullName", {
                        required: "Field is required",
                      })}
                      placeholder=""
                      maxLength={50}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\d/g, ""))
                      }
                    />
                    <label className="did-floating-label">
                      Enter Full Name
                    </label>
                    <p className="text-danger">{errors1?.FullName?.message}</p>
                  </div>

                  <div className="did-floating-label-content">
                    {" "}
                    <input
                      type="number"
                      className="userDetailsInput did-floating-input"
                      name="MobileNumber"
                      {...register1("MobileNumber", {
                        required: "Field is required",
                        pattern: {
                          value:
                            /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                          message: "Invalid Mobile Number",
                        },
                      })}
                      placeholder=""
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                    />
                    <label className="did-floating-label">
                      Enter Mobile Number
                    </label>
                    <p className="text-danger">
                      {errors1?.MobileNumber?.message}
                    </p>
                  </div>

                  <button type="submit" className="modalBtnSubmit mb-2">
                    {submit ? "Getting Quotes..." : "Submit and View Plans"}
                  </button>
                </div>
                {/* <div className="modalContent2">
                  <div className="leftContent">
                    <img
                      className="w-100"
                      src={
                        selectedBox === "MotorBike"
                          ? "/assets/images/BIKE PNG (1).png"
                          : "/assets/images/car icon (2).png"
                      }
                    />
                    <p className="contentText">
                      {rcName ? rcName : "RJ45SA9490"}
                    </p>
                    {selectedBox === "MotorBike" ? (
                      <p>HONDA CB SHINE SP125</p>
                    ) : (
                      <p>HYUNDAI GRAND i10</p>
                    )}
                  </div>
                </div> */}
              </form>
            </div>
          )}
          {currentSteps === 3 && (
            <div className="modalBody">
              <form onSubmit={handleSubmit2(handleMoreDetails)}>
                <div className="modalHeading">
                  {/* <h3>Fill your Personal Details?</h3> */}
                </div>

                <div className="">
                  <div className="form-floating mb-3">
                    <select
                      name="PreviousInsuranceClaimed"
                      {...register2("PreviousInsuranceClaimed", {
                        required: "Field is required",
                      })}
                      className="form-select formStep8PolicyInsurerSelect"
                      id="idxx3"
                    >
                      <option className="">
                        Have you Made Claim in Last Year?
                      </option>

                      <option value="true">Yes</option>
                      <option value={"false"}>No</option>
                    </select>
                  </div>

                  <div className="form-floating mb-3">
                    <select
                      name="PolicyStatus"
                      {...register2("PolicyStatus", {
                        required: "Field is required",
                      })}
                      className="form-select formStep8PolicyInsurerSelect"
                      id="idxx3"
                    >
                      <option className="d-none">Select Policy Status</option>

                      <option value="continue">Not Expired</option>
                      <option value="expired within 90 day">
                        Expired Within 90 days
                      </option>
                      <option value="expired above 90 day">
                        Expired Above 90 days
                      </option>
                      <option value="false">
                        No Information About Previous Policy
                      </option>
                    </select>
                    {/* <label htmlFor="idxx3">Previous Policy Insurer</label> */}
                  </div>

                  <div className=" formStep8PolicyInsurer">
                    <div className="form-floating ">
                      <select
                        name="PreInsurerCode"
                        {...register2("prePolicyInsurer", {
                          required: "Field is required",
                        })}
                        className="form-select formStep8PolicyInsurerSelect"
                        id="idxx3"
                      >
                        <option className="d-none">
                          Previous Policy Insurer
                        </option>
                        <option value={0}>
                          Don't Know About Previous Insurer
                        </option>
                        {insurerData &&
                          insurerData.length > 0 &&
                          insurerData.map((item, i) => (
                            <>
                              <option key={i} value={item.option}>
                                {item.option}
                              </option>
                            </>
                          ))}
                      </select>
                      {/* <label htmlFor="idxx3">Previous Policy Insurer</label> */}
                    </div>
                    <p className="f-error">{errors?.PreInsurerCode?.message}</p>
                  </div>

                  <div className="form-floating">
                    <input
                      {...register2("PrePolicyEndDate", {
                        required: "Field is required",
                      })}
                      name="PrePolicyEndDate"
                      type="date"
                      id="idxx4"
                      className=" form-control w-100 text-center"
                      // style={{ paddingTop: "0.625rem" }}
                      // defaultValue={moment()
                      //   .startOf("month")
                      //   .format(`${regYear}-MM-DD`)}
                    />
                    <label
                      htmlFor="idxx4"
                      style={{ position: "absolute", left: "7rem" }}
                    >
                      Previous Policy End Date
                    </label>
                    <p className="f-error text-danger">
                      {errors1?.FullName?.message}
                    </p>
                  </div>

                  <div className="form-floating mb-3">
                    <select
                      name="prePolicyType"
                      {...register2("PreviousPolicyType", {
                        required: "Field is required",
                      })}
                      className="form-select formStep8PolicyInsurerSelect"
                      id="idxx3"
                    >
                      <option className="">Previous Policy Type?</option>

                      <option value="Comprehensive">Comprehensive</option>
                      <option value="ThirdParty">Third Party</option>
                      <option value="ODOnly">Own Damage</option>
                    </select>
                  </div>

                  {watch2("PreviousInsuranceClaimed") === "false" && (
                    <div className="form-floating mb-3">
                      <select
                        name="PreviousNoClaimBonus"
                        {...register2("PreviousNoClaimBonus", {
                          required: "Field is required",
                        })}
                        className="form-select formStep8PolicyInsurerSelect"
                        id="idxx8"
                      >
                        <option className="d-none">
                          Previous No Claim Bonus (NCB)
                        </option>
                        <option value={0}>0%</option>
                        <option value={20}>20%</option>
                        <option value={25}>25%</option>
                        <option value={35}>35%</option>
                        <option value={45}>45%</option>
                        <option value={50}>50%</option>
                      </select>
                      {/* <label htmlFor="idxx8">Previous No Claim Bonus (NCB)</label> */}
                    </div>
                  )}

                  <button type="submit" className="modalBtnSubmit mb-2">
                    Submit and View Plans
                  </button>
                </div>
              </form>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={retentionModal}
        toggle={toggleRetentionModal}
        size="md"
        centered
        backdrop="static"
      >
        <ModalBody>
          <div className="vehicle_modal">
            <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-4">
              <h4 className="text-center">Vehicle Details</h4>
              <div onClick={toggleRetentionModal} class="v2_close false">
                <a>
                  <img
                    src="./assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>
            <div className="modalContent">
              <div className="leftContent">
                {stepsData?.step1 && (
                  <h5 className="vehicleNumber">
                    {stepsData?.step1?.registrationNumber}
                  </h5>
                )}

                {stepsData?.step2 && (
                  <>
                    <p>{`${stepsData.step2.make?.label}  `}</p>
                    <p>
                      {stepsData?.step3?.model
                        ? stepsData.step3.model?.label
                        : ""}
                    </p>
                    <p className="text-capitalize">
                      {stepsData?.step4?.variantSelection?.label
                        ? stepsData.step4.variantSelection.label
                        : ""}
                    </p>
                  </>
                )}

                <p>
                  {/* GRAND i10 */}
                  {/* <FaDotCircle size={13} className="dot" /> */}
                </p>
                <p>
                  {stepsData?.processFlow?.apiRequestQQ &&
                    stepsData?.processFlow?.apiRequestQQ?.FuelType}
                  {stepsData?.processFlow?.apiRequestQQ &&
                  stepsData?.processFlow?.apiRequestQQ?.ManufaturingDate ? (
                    <FaDotCircle size={13} className="dot" />
                  ) : (
                    ""
                  )}
                  {stepsData?.processFlow?.apiRequestQQ &&
                    stepsData?.processFlow?.apiRequestQQ?.ManufaturingDate !==
                      "" &&
                    new Date(
                      stepsData?.processFlow?.apiRequestQQ?.ManufaturingDate
                    ).getFullYear()}
                  {/* <FaDotCircle size={13} className="dot" /> */} <br />
                  {/* Jaipur */}
                </p>
              </div>
              <div className="rightContent">
                <img
                  src={
                    stepsData && stepsData?.step1?.vehicleType === "2w"
                      ? "/assets/images/BIKE PNG (1).png"
                      : "/assets/images/car-wash.png"
                  }
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3 gap-4">
              <button
                className="vehicle_btn"
                onClick={() => handleContinueJourney()}
              >
                Continue Journey
              </button>
              <button
                className="vehicle_btn"
                onClick={() => {
                  removeCookie("customerLeadId");
                  toggleRetentionModal();
                }}
              >
                Start a New Search
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Products;
