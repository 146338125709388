import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { APPURL, PostDataWithToken } from "../../../api/apiHelper";
import { makeid } from "../../store/reducer/userReducers";
const SHA512 = require("crypto-js/sha512");
const KotakPayment = () => {
  // console.log("asdfj");
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const [randomId, setRandomId] = useState("");
  const [hashedVal, setHashedVal] = useState("");
  const ProductInfo =
    apiRequestQQ.NewPolicyType === "ThirdParty"
      ? "Private Car Liability Only Policy"
      : apiRequestQQ.NewPolicyType === "ODOnly"
      ? // ? "ODOnly"
        "Private Car OD Only"
      : "Private Car Comprehensive";
  useEffect(() => {
    console.log(selectedPlan, "selectedPlan");
    let rdId = makeid(35);
    setRandomId(rdId);
    // console.log(
    //   "hash value",
    //   `KoXBAd|${rdId}|${selectedPlan.FinalPremium}|${selectedPlan.insurer}|${apiRequestQQ.FirstName}|${apiRequestQQ.Email}|INSUREFAST INSURANCE WEB AGGREGATOR PRIVATE LIMITED|${selectedPlan.PolicyId}|${selectedPlan.ApiId}|${ProductInfo}|${selectedPlan?.vProposalNumber}||||||uM9IRHYR`
    // );
    // let hashedValue = SHA512(
    //   `KoXBAd|${rdId}|${selectedPlan.FinalPremium}|${selectedPlan.insurer}|${apiRequestQQ.FirstName}|${apiRequestQQ?.Email}|INSUREFAST INSURANCE WEB AGGREGATOR PRIVATE LIMITED|${selectedPlan.PolicyId}|${selectedPlan.ApiId}|${ProductInfo}|${selectedPlan?.vProposalNumber}||||||uM9IRHYR`
    // ).toString();
    let hashedValue = SHA512(
      `an7rIU|${rdId}|${selectedPlan.FinalPremium}|${selectedPlan.insurer}|${apiRequestQQ.FirstName}|${apiRequestQQ?.Email}|INSUREFAST INSURANCE WEB AGGREGATOR PRIVATE LIMITED|${selectedPlan.PolicyId}|${selectedPlan.ApiId}|${ProductInfo}|${selectedPlan?.vProposalNumber}||||||8MUr8LS7`
    ).toString();
    setHashedVal(hashedValue);
  }, []);

  return (
    <>
      <form
        method="post"
        id="kotak-form"
        // action="https://secure.payu.in/_payment"
        action="https://test.payu.in/_payment"
      >
        <input type="text" value={hashedVal} name="hash" hidden />
        {/* <input type="text" value={"KoXBAd"} name="key" hidden />  */}
        <input type="text" value={"an7rIU"} name="key" hidden />
        <input type="text" value={randomId} name="txnid" hidden />
        <input
          type="text"
          value={selectedPlan.FinalPremium}
          name="amount"
          hidden
        />
        <input
          type="text"
          value={selectedPlan.insurer}
          name="productinfo"
          hidden
        />
        <input
          type="text"
          value={apiRequestQQ.FirstName}
          name="firstname"
          hidden
        />
        <input type="text" value={apiRequestQQ?.Email} name="email" hidden />
        <input
          type="text"
          value={apiRequestQQ?.MobileNumber}
          name="phone"
          hidden
        />
        <input
          type="text"
          value={apiRequestQQ?.LastName}
          name="lastname"
          hidden
        />
        {/* <input type="text" value={process.env.REACT_APP_SUCCESS_URL} name="surl" hidden />
        <input type="text" value={process.env.REACT_APP_ERROR_URL} name="furl" hidden />
        <input type="text" value={process.env.REACT_APP_ERROR_URL} name="curl" hidden /> */}
        <input
          type="text"
          // value={`${APPURL()}api/v1/motor/successPage`}
          // value={`http://localhost:10111/api/v1/motor/successpage/?ProposalNumber=${selectedPlan.vProposalNumber}&ProductCode=${selectedPlan?.vProductCode}&Api_name=kotak`}
          value={`https://api.insurefast.in/api/v1/motor/successpage/?ProposalNumber=${selectedPlan.vProposalNumber}&ProductCode=${selectedPlan?.vProductCode}&Api_name=kotak`}
          // value={`http://localhost:10111/api/v1/motor/successpage`}
          name="surl"
          hidden
        />
        <input
          type="text"
          // value='https://uatapi.insurefast.in/api/v1/motor/errorpage'
          value="https://api.insurefast.in/api/v1/motor/errorpage"
          // value={`http://localhost:10111/api/v1/motor/errorpage`}
          name="furl"
          hidden
        />
        <input
          type="text"
          // value='https://uatapi.insurefast.in/api/v1/motor/errorpage'
          value="https://api.insurefast.in/api/v1/motor/errorpage"
          // value={`http://localhost:10111/api/v1/motor/errorpage`}
          name="curl"
          hidden
        />
        <input
          type="text"
          value={"INSUREFAST INSURANCE WEB AGGREGATOR PRIVATE LIMITED"}
          name="udf1"
          hidden
        />
        <input type="text" value={selectedPlan.PolicyId} name="udf2" hidden />
        <input type="text" value={selectedPlan.ApiId} name="udf3" hidden />
        <input type="text" value={ProductInfo} name="udf4" hidden />
        <input
          type="text"
          value={selectedPlan?.vProposalNumber}
          name="udf5"
          hidden
        />
        {/* <input type="text" value={selectedPlan?.Api_name} name="udf5" hidden /> */}
        <button
          type="submit"
          className="btn btn-success fs-4 px-4 py-2 w-100"
          style={{ backgroundColor: "#56b96c", color: "#ffffff" }}
        >
          Buy <span> {selectedPlan.FinalPremium}</span>
        </button>
      </form>
    </>
  );
};

export default KotakPayment;
