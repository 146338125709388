import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import { isEmpty } from "lodash";
import { IoClose } from "react-icons/io5";
function QuotelistAllFilter({ show, setModel, list, heading }) {
  const toggle = () => setModel(!show);
  const [select, setSelect] = useState({
    label: "Sort By",
    key: "sort",
    desc: "Plans with your preference will be shown first",
  });
  const [selectInner, setSelectInner] = useState("");
  const obj = {
    sort: sortByList,
    Cover: coverModelList,
    Insurer: insurerList,
  };
  const [selectedData, setSelectedData] = useState([]);

  const handelMulitselect = (item) => {
    if (
      !isEmpty(
        selectedData?.find((res) => {
          return res?.type == item?.type;
        })
      )
    ) {
      setSelectedData((prev) => {
        let find = prev?.filter((res) => {
          return res?.type !== item?.type;
        });
        return [...find, item];
      });
    } else {
      setSelectedData((prev) => {
        return [...prev, item];
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="xl">
      <ModalBody>
        <div class="">
          <div class="gsc_modalWraselectedData.find((res) => {return res?.type == item?.type})pper">
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
              <h4>Filter Plans By</h4>
              <div onClick={toggle} class="v2_close false">
                <img
                  src="./assets/images/v2_close.svg"
                  title="Close"
                  alt="close"
                />
              </div>
            </div>
            {selectedData?.length > 0 && (
              <div class="innerAppliedBox">
                {selectedData?.map((res) => {
                  return (
                    <div class="innerApplied">
                      {res?.label}{" "}
                      <IoClose
                        onClick={() =>
                          setSelectedData((prev) => {
                            return prev.filter((item) => {
                              return item?.key !== res?.key;
                            });
                          })
                        }
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div className="mb-3 all-filter-main">
              <div className="all-filter-left">
                <div className="all-filter-inner">
                  {leftList?.map((res) => {
                    return (
                      <div
                        className={select?.key == res?.key ? "active" : " "}
                        onClick={() => setSelect(res)}
                      >
                        {res?.label}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="all-filter-right">
                <div class="all-filter-right-inner">
                  <h4>{select?.label}</h4>
                  <p>{select?.desc}</p>
                  <div class="all-filter-right-inner-wrapper">
                    {obj[select?.key]?.map((res) => {
                      return (
                        <div
                          onClick={() => handelMulitselect(res)}
                          class={
                            selectedData?.find((item) => {
                              return (
                                res?.type == item?.type && res?.key == item?.key
                              );
                            })?.key == res?.key
                              ? "active"
                              : ""
                          }
                        >
                          <span>{res?.label}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div class="v2_btngrp  v2_btngrp-filter mb-3">
              <div
                onClick={() => setSelectedData([])}
                class="wrapper"
                style={{ width: "25%" }}
              >
                <button
                  type="submit"
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12 cancel"
                >
                  <div>Reset</div>
                </button>
              </div>
              <div class="wrapper" style={{ width: "25%" }}>
                <button
                  type="submit"
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  <div>Apply</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistAllFilter;

const leftList = [
  {
    label: "Sort By",
    key: "sort",
    desc: "Plans with your preference will be shown first",
  },
  {
    label: "Cover",
    key: "Cover",
    desc: "In India, 50% of hospital bills are paid out-of-pocket due to coverage limits. Choose yours wisely.",
  },
  {
    label: "Room Rent Type",
    key: "Room",
    desc: "It is the type of room you are eligible for in case of hospitalization",
  },
  {
    label: "Policy Benefits",
    key: "Policy",
    desc: "These benefits are part of your insurance cover. You can check plans as per your desired benefits",
  },
  {
    label: "Permium (per month)",
    key: "Permium",
    desc: "This is the amount that you pay for buying the health insurance for 1 year policy period",
  },
  {
    label: "Portability",
    key: "Portability",
    desc: "Switch your existing policy to a new insurer without loosing waiting period benefits",
  },
  {
    label: "Existing Diseas waiting period",
    key: "Existing",
    desc: "It is a time span before a select list of ailments get covered in your policy",
  },
  {
    label: "Policy period",
    key: "period",
    desc: "Selecting a multi-year plan saves your money and the trouble of remembering yearly renewal",
  },
  {
    label: "Insurer",
    key: "Insurer",
    desc: "You can select plan based on your preferred insurer",
  },
];

const coverModelList = [
  {
    label: "Recommended",
    key: "Recommended",
    type: "cover",
  },
  {
    label: "Below 5 Lakh",
    key: "5",
    type: "cover",
  },
  {
    label: "5 - 9 Lakh",
    key: "9",
    type: "cover",
  },
  {
    label: "10 - 24 Lakh",
    key: "10",
    type: "cover",
  },
  {
    label: "25 - 99 Lakh",
    key: "25",
    type: "cover",
  },
  {
    label: "1 Cr+ Lakh",
    key: "1",
    type: "cover",
  },
];

const sortByList = [
  {
    label: "By Relevance",
    key: "relevance",
    type: "sort",
  },
  {
    label: "Premium low to high",
    key: "lowtohigh",
    type: "sort",
  },
  {
    label: "Cashless Hospitals Network",
    key: "cashless",
    type: "sort",
  },
];

const insurerList = [
  {
    label: "Universal Sompo General Insurance",
    key: "USGI",
    type: "insurer",
  },
  // {
  //   label: "Adity Birla",
  //   key: "Birla",
  //   type: "insurer",
  // },
  // {
  //   label: "Care Health",
  //   key: "Health",
  //   type: "insurer",
  // },
];

const planTypeList = [
  {
    label: "Base Plan",
    key: "Plan",
  },
  {
    label: "1Cr Cover",
    key: "1",
  },
  {
    label: "Multi-Individual Plans",
    key: "Multi",
  },
  {
    label: "Arogya Sanjeevani",
    key: "Sanjeevani",
  },
  {
    label: "Top-up Plan",
    key: "Top",
  },
];
