import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import { Route, Routes } from "react-router-dom";
import Index from "./components/Index";
import InsuranceSlider from "./components/common/InsuranceSlider";
import Health from "./components/Health";
import Quotelist from "./components/Quotelist";
import HealthProposal from "./components/HealthProposal";
import PolicyOverview from "./components/PolicyOverview";
import WhyChoose from "./components/WhyChoose";
import AboutHealth from "./components/AboutHealth";
import PaymentPage from "./components/PaymentPage";
import MotorProcess from "./components/MotorProcess/MotorProcess";
import AboutBike from "./components/AboutBike";
import ProposalForm from "./components/Proposal/ProposalForm";
import SuccessMessage from "./components/Proposal/SuccessMessage";
import ContactUs from "./components/common/ContactUs";
import AboutUs from "./components/common/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import MyProfile from "./components/Profile/Profile";
import TermsCondition from "./components/TermsCondition";
import SupportNew from "./components/common/SupportNew";
import Dog from "./components/pet/pet";
import KycRedirectionPage from "./components/Proposal/KycRedirectionPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/slider" element={<InsuranceSlider />} />
      <Route path="/care_payment" element={<PaymentPage />} />
      <Route path="/health" element={<Health />} />
      <Route path="/quotelist/:id" element={<Quotelist />} />
      <Route path="/health-quote" element={<Quotelist />} />
      <Route path="/checkout/:id" element={<PolicyOverview />} />
      <Route path="/why-choose" element={<WhyChoose />} />
      <Route path="/about-health" element={<AboutHealth />} />
      <Route
        path="/health-proposal/:planType/:enquiryId"
        element={<HealthProposal />}
      />
      <Route path="/motor" element={<MotorProcess />} />
      <Route path="/about-bike" element={<AboutBike />} />
      <Route path="/proposal/:id" element={<ProposalForm />} />
      <Route path="/checkout" element={<PolicyOverview />} />
      <Route path="/success-page/:id" element={<SuccessMessage />} />
      <Route path="/kyc-redirection/:id" element={<KycRedirectionPage />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-conditions" element={<TermsCondition />} />
      <Route path="/my-profile" element={<MyProfile />} />
      <Route path="/support" element={<SupportNew />} />

      {/* pet route */}
      <Route path="/dog-health" element={<Dog />} />
    </Routes>
  );
}

export default App;
