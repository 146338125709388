import React, { useEffect } from 'react'
import Layout from './common/Layout'

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <div className='policy-outer'>
        <div className='container'>
          <div className='policy-main'>
            <div class='card-body-heading'>
              <h1 className='text-dark mb-3'>Terms & Conditions</h1>
            </div>
            {/* <div> */}
            <div class='card-body'>
              <p style={{ textAlign: 'justify' }}>
                Welcome to{' '}
                <span style={{ color: 'green' }}>www.insurefast.in</span>{' '}
                website. Insurefast provides its services to you subject to the
                following conditions. Before you may use the Site / Mobile
                Application, you must read and accept all of the terms and
                conditions in, and linked to, this Terms of Use, the linked
                Privacy Policy and other Legal and Admin Policies. <br />
                We strongly recommend that, as you read this T&C, you also
                access and read the linked information, since it is incorporated
                into and hereby made part of this T&C. This T&C is effective
                upon acceptance. Use of any functionality of the Site / Mobile
                Application constitutes acceptance of this T&C. If this T&C
                conflicts with any other documents, the T&C will control for the
                purposes of usage of the Site / Mobile Application. If you do
                not agree to be bound by this T&C and the Privacy Policy, you
                may not use the Site / Mobile Application in any way. Be sure to
                return to this page periodically to review the most current
                version of the T&C. We reserve the right, at any time, at our
                sole discretion, to change or otherwise modify the T&C without
                prior notice, and your continued access or use of this Site /
                Mobile Application signifies your acceptance of the updated or
                modified T&C.
              </p>
              <p>
                You understand that in case of your non-compliance (intentional
                or otherwise) with the Privacy Policy, Terms & Conditions and
                other Legal and Admin Policies for access or usage of the Site /
                Mobile Application, Insurefast reserves the right to terminate
                your access to the Site / Mobile Application and/or your account
                (“collectively referred to as Access”) or remove such
                non-compliant information (if any) provided by you, or both.
              </p>
              <p>
                By agreeing to these Terms & Conditions, you hereby provide a
                formal mandate to Insurefast to represent you to the insurer and
                communicate the grant of a cover to you after effecting the
                insurance. Such mandate shall be valid for a period of 18
                months, unless otherwise agreed between Insurefast and you.
                Please note that no mandate is required for pre-underwritten
                policies or individual policies.
              </p>
              <span className='fs-4'>1. Description of Services</span>
              <p>
                In the Site / Mobile Application, Insurefast provides users with
                access to information primarily about Financial and Insurance
                products and services including but not restricted to General
                Insurance, Life Insurance products and related services
                (including but not limited to renewals) (the "Service"). You are
                responsible for obtaining access to the Site / Mobile
                Application, and that access may involve third-party fees (such
                as Internet service provider or airtime charges). In addition,
                you must provide and are responsible for all equipment necessary
                to access the Site / Mobile Application. By making use of this
                Site / Mobile Application, and furnishing your personal /
                contact details, you hereby agree that you are interested in
                availing and purchasing the Service(s) that you have selected.
                You hereby agree that Insurefast may contact you either
                electronically or through phone, to understand your interest in
                the selected products and Service(s) (like renewals, reminders
                etc.) and to fulfill your demand. You also agree that Insurefast
                reserves the right to make your details available to its
                affiliates and partners and you may be contacted by the
                affiliates and partners for information and for sales through
                email, telephone and/or sms. You agree to receive promotional
                materials and/or special offers from Insurefast through email or
                sms.
              </p>
              <span className='fs-4'>
                2. License and Site / Mobile Application Access
              </span>
              <p>
                Insurefast grants you a limited license to access and make
                personal use of the Site / Mobile Application and the Service.
                This license does not include any downloading or copying of any
                kind of information for the benefit of another individual,
                vendor or any other third party; caching, unauthorized hypertext
                links to the Site / Mobile Application and the framing of any
                Content available through the Site / Mobile Application
                uploading, posting, or transmitting any content that you do not
                have a right to make available (such as the intellectual
                property of another party); uploading, posting, or transmitting
                any material that contains software viruses or any other
                computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer software or hardware
                or telecommunications equipment; any action that imposes or may
                impose (in Insurefast's sole discretion) an unreasonable or
                disproportionately large load on Insurefast's infrastructure; or
                any use of data mining, robots, or similar data gathering and
                extraction tools. You may not bypass any measures used by
                Insurefast to prevent or restrict access to the Site / Mobile
                Application. Any unauthorized use by you shall terminate the
                permission or license granted to you by Insurefast. By using the
                Site / Mobile Application you agree not to: (i) use this Site /
                Mobile Application or its contents for any commercial purpose;
                (ii) make any speculative, false, or fraudulent transaction or
                any transaction in anticipation of demand; (iii) access, monitor
                or copy any content or information of this Site / Mobile
                Application using any robot, spider, scraper or other automated
                means or any manual process for any purpose without our express
                written permission; (iv) violate the restrictions in any robot
                exclusion headers on this Site / Mobile Application or bypass or
                circumvent other measures employed to prevent or limit access to
                this Site / Mobile Application; (v) take any action that
                imposes, or may impose, in our discretion, an unreasonable or
                disproportionately large load on our infrastructure; (vi)
                deep-link to any portion of this Site / Mobile Application
                (including, without limitation, the purchase path for any
                service) for any purpose without our express written permission;
                or (vii) "frame", "mirror" or otherwise incorporate any part of
                this Site / Mobile Application into any other website without
                our prior written authorization.
              </p>
              <span className='fs-4'>3. Eligibility</span>
              <p>
                The Service is not available to minors under the age of 18 or to
                any users suspended or removed from the system by Insurefast for
                any reason. Users may not have more than one active account.
                Additionally, users are prohibited from selling, trading, or
                otherwise transferring their accounts to another party. If you
                do not qualify, you may not use the Service or the Site / Mobile
                Application.
              </p>
              <span className='fs-4'>4. Your Account</span>
              <p>
                In consideration of your use of the Site / Mobile Application,
                you represent that you are of legal age to form a binding
                contract and are not a person barred from receiving services
                under the laws of India or other applicable jurisdiction and
                will only use the Site / Mobile Application to make legitimate
                purchases for you or for another person for whom you are legally
                authorized to act (and will inform such other persons about the
                T&C and/or Privacy Policy) that apply to the purchase you have
                made on their behalf (including all rules and restrictions
                applicable thereto). You also agree to provide true, accurate,
                current and complete information about yourself as prompted by
                the Site / Mobile Application. If you provide any information
                that is untrue, inaccurate, not current or incomplete (or
                becomes untrue, inaccurate, not current or incomplete), or
                Insurefast has reasonable grounds to suspect that such
                information is untrue, inaccurate, not current or incomplete,
                Insurefast has the right to suspend or terminate your account
                and refuse any and all current or future use of the Site /
                Mobile Application (or any portion thereof). If you use the Site
                / Mobile Application, you are responsible for maintaining the
                confidentiality of your account and password, and for
                restricting access to your computer. You agree to accept
                responsibility for all activities that occur under your account
                or password. Because of this, we strongly recommend that you
                exit from your account at the end of each session. You agree to
                notify Insurefast immediately of any unauthorized use of your
                account or any other breach of security. Insurefast reserves the
                right to refuse service, terminate accounts, or remove or edit
                content in its sole discretion. Notwithstanding the above, we
                retain the right at our sole discretion to deny access to anyone
                to the Site / Mobile Application and the Services we offer, at
                any time and for any reason, including, but not limited to, for
                violation of the T&C or Privacy Policy. Additional terms and
                conditions will apply to your purchase of Services that you
                select. Please read these additional terms and conditions
                carefully. You agree to abide by the terms and conditions of
                purchase imposed by any service provider with whom you elect to
                deal, including, but not limited to, payment of all amounts when
                due and compliance with the service provider's rules and
                restrictions regarding use of products, and/or Services. You
                agree that any violation of any such service provider's rules
                and restrictions may result in cancellation of your purchase(s),
                in your being denied access to the applicable service(s), in
                your forfeiting any monies paid for such Services, and/or in our
                debiting your account for any costs we incur as a result of such
                violation.
              </p>
              <span className='fs-4'>5. Submitted Content</span>
              <p>
                Insurefast does not claim ownership of any materials you make
                available through the Site / Mobile Application. At Insurefast's
                sole discretion, such materials may be included in the Service
                in whole or in part or in a modified form. With respect to such
                materials you submit or make available for inclusion on the Site
                / Mobile Application, you grant Insurefast a perpetual,
                irrevocable, non-terminable, worldwide, royalty-free and
                non-exclusive license to use, copy, distribute, publicly
                display, modify, create derivative works, and sublicense such
                materials or any part of such materials (as well as use the name
                that you submit in connection with such submitted content). You
                hereby represent, warrant and covenant that any materials you
                provide do not include anything (including, but not limited to,
                text, images, music or video) to which you do not have the full
                right to grant the license specified in this Section 4. We take
                no responsibility and assume no liability for any submitted
                content posted or submitted by you. We have no obligation to
                post your comments; we reserve the right in our absolute
                discretion to determine which comments are published on the Site
                / Mobile Application. If you do not agree to these terms and
                conditions, please do not provide us with any submitted content.
                You agree that you are fully responsible for the content you
                submit. You are prohibited from posting or transmitting to or
                from this Site / Mobile Application: (i) any unlawful,
                threatening, libelous, defamatory, obscene, pornographic, or
                other material or content that would violate rights of publicity
                and/or privacy or that would violate any law; (ii) any
                commercial material or content (including, but not limited to,
                solicitation of funds, advertising, or marketing of any good or
                services); and (iii) any material or content that infringes,
                misappropriates or violates any copyright, trademark, patent
                right or other proprietary right of any third party. You shall
                be solely liable for any damages resulting from any violation of
                the foregoing restrictions, or any other harm resulting from
                your posting of content to this Site / Mobile Application.
              </p>
              <span className='fs-4'>
                6. Disclaimer of Liability and Warranty
              </span>
              <p>
                The Content, Products, and Services Published on this Site /
                Mobile Application may include inaccuracies or errors, Including
                Pricing errors. We do not guarantee the accuracy of , and
                disclaim all liability for any errors or other inaccuracies
                relating to the information and description of the content,
                products, and services we expressly reserves the right to
                correct any pricing errors on the Site / Mobile Application
                and/or on pending reservations made under an incorrect price.
                Insurefast makes no representation about the suitability of the
                information, Software, products, and services contained on this
                Site / Mobile Application for any purpose, and the inclusion or
                offering of any products or services on this Site / Mobile
                Application does not constitute any endorsement or
                recommendation of such products or services. All such
                information, software, products, and services are provided "AS
                IS" without warranty of any kind. Insurefast disclaims all
                warranties and conditions that this Site / Mobile Application,
                its services or any email sent from Insurefast, its affiliates,
                and/or their respective or associated service providers are free
                of viruses or other harmful components. Insurefast hereby
                disclaims all warranties and conditions with regard to this
                information, software, products, and services, including all
                implied warranties and conditions of merchantability, fitness
                for a particular purpose, title, and no infringement. The
                service providers providing services on this Site / Mobile
                Application are independent affiliates and Insurefast are not
                liable for the acts, errors, omissions, representations,
                warranties, breaches or negligence of any such service providers
                or for any personal injuries, death, property damage, or other
                damages or expenses resulting therefore. Insurefast and its
                affiliates have no liability and will make no refund in the
                event of any delay, cancellation, strike, force majeure or other
                causes beyond their direct control, and they have no
                responsibility for any additional expense omissions delays or
                acts of any government or authority. In no event shall
                Insurefast and/or its affiliates be liable for any direct,
                indirect, punitive, incidental, special, or consequential
                damages arising out of, or in any way connected with, your
                access to, display of or use of this Site / Mobile Application
                or with the delay or inability to access, display or use this
                Site / Mobile Application (including, but not limited to, your
                reliance upon opinions appearing on this Site / Mobile
                Application; any computer viruses, information, software, linked
                sites, products, and services obtained through this Site /
                Mobile Application; or otherwise arising out of the access to,
                display of or use of this Site / Mobile Application) whether
                based on a theory of negligence, contract, tort, strict
                liability, or otherwise, and even if Insurefast and/or its
                affiliates their respective service providers have been advised
                of the possibility of such damages.
              </p>
              <span className='fs-4'>
                7. Disclaimer of Online Availability, Impressions, And
                Click-Throughs
              </span>
              <p>
                In addition to the other disclaimers and limitations discussed
                in this notice, there are no guarantees and no warranties
                regarding online availability, impressions, and click-through of
                www.Insurefast.in, its web pages, and any material, information,
                links, or content presented on the web pages at
                www.Insurefast.in. Insurefast.in, its web pages, and any
                material, information, links, or content presented on the web
                pages at www.Insurefast.in, may be unavailable for online access
                at anytime. Advertising sponsors and advertising, if any and if
                permitted, must be approved by Insurefast before the posting of
                any advertising material, information, links, content, banners,
                and graphics on www.Insurefast.in. Insurefast reserves the right
                to accept or to reject any advertising sponsor or any
                advertising for any reason.
              </p>
              <span className='fs-4'>
                8. Cancellation & Refund/Chargeback Terms and Conditions
              </span>
              <br />
              <span className='fs-5'>
                (a) Free look period Cancellation and Refund
              </span>
              <p>
                As per the IRDAI rules and regulations, you have the right to
                cancel your insurance policy within 15(fifteen) days from the
                date of (online) receipt of insurance policy (referred to as
                “Free look period”) and refund of your premium amount shall be
                processed as per the applicable process and procedures followed
                by the insurer. This Free look facility can be availed only for
                Life and Health insurance policies, subject to certain other
                terms and conditions specified by IRDAI. We encourage all our
                customers to read the insurance policy document carefully and
                avail the free look facility in case the terms and conditions of
                the insurance policy document don’t match your insurance
                requirements. Further, you understand that once you place a
                cancellation request within the Free look period, the policy
                gets cancelled and entire premium is refunded to you subject to
                deduction of (i) charges pertaining to medical tests conducted
                (ii) administrative and service cost like stamp duty etc. and;
                (iii )Charges for mortality for the period the policy was in
                force. Please note such deduction is at the sole discretion of
                the insurer. All payments with respect to refunds as stated
                under this paragraph 1 shall be the sole responsibility of the
                insurer per the rules and regulations laid down by the IRDAI.
                You understand that Insurefast has tied up with RBI authorized
                payment gateways to allow you to make online payments towards
                your insurance premium amount and is only acting as a mere
                facilitator and providing its assistance to its customers for
                speedy refunds.
              </p>
              <span className='fs-5'>(b) Chargebacks</span>
              <p>
                You (cardholder) may dispute a transaction carried out through
                our Website and file a Charegback with your issuing bank for the
                reversal of that transaction. Chargeback allows transactions to
                be reversed by you under specified circumstances such as, on
                account of (i) alleged forgery of the card number / bank account
                or other details (ii) any charge/debit made on a card that has
                been listed as a hot listed card or otherwise listed on the card
                association (Visa, MasterCard, etc.) warning bulletins (iii)
                duplicate processing of the transaction; or (iv) for other
                reasons as per applicable rules and guidelines issued by RBI,
                card Associations, your card/payment instrument issuing bank
                etc. However, we reserve the right to reject a Chargeback,
                including but not limited to, under the following circumstances:
                <br />
                1. In the event your medical tests or underwriting is in process
                with your chosen insurer, not exceeding a period of 3 months
                from the date of your insurance policy application
                <br />
                2. In the event there is any delay caused on the part of the
                insurer in conducting medical tests or during the process of
                medical underwriting or issuance of insurance policy or any
                service provided by the insurer in connection with the insurance
                policy issuance, not exceeding a period of 3 months from the
                date of your insurance policy application
                <br />
                3. In case your insurance policy application is rejected by the
                insurer, post your medical test Our Company shall not be liable
                to you, in any form or manner whatsoever, for any claims,
                disputes, proceedings which may arise in connection with
                Chargebacks initiated by you in the circumstances as specified
                under (a), (b) and (c) herein above.
              </p>
              <span className='fs-4'>
                9. Additional Terms and Conditions of proposal form (applicable
                for health insurance products only):
              </span>
              <p>
                (i) You hereby declare, on your behalf and on behalf of all
                persons proposed to be insured, that the statements, answers
                and/ or particulars given by you are true and complete in all
                respects to the best of your knowledge and that you are
                authorized to propose on behalf of these other persons.
                <br />
                (ii) You understand that the information provided by you will
                form the basis of the insurance policy, is subject to the Board
                approved underwriting policy of the insurer and that the policy
                will come into force only after full payment of the premium
                chargeable.
                <br />
                (iii) You further declare that you will notify in writing any
                change occurring in the occupation or general health of the life
                to be insured/proposer after the proposal has been submitted but
                before communication of the risk acceptance by the insurance
                company.
                <br />
                (iv) You declare that you consent to the insurance company
                seeking medical information from any doctor or hospital
                who/which at any time has attended on the person to be
                insured/proposer or from any past or present employer concerning
                anything which affects the physical or mental health of the
                person to be insured/proposer and seeking information from any
                insurer to whom an application for insurance on the person to be
                insured/proposer has been made for the purpose of underwriting
                the proposal and/or claim settlement.
                <br />
                (v) You authorize the Insurefast/insurance company to share
                information pertaining to your proposal including the medical
                records of the insured/ proposer for the sole purpose of
                underwriting the proposal and/or claims settlement and with any
                Governmental and/or Regulatory authority.
                <br />
                (vi) You consent to and authorize any of insurance company’s
                authorized representatives not being direct employees of the
                company to seek medical information required for the purpose of
                policy issuance or claim settlement under this policy from any
                hospital/medical practitioner that you or any person proposed to
                be insured/insured has attended or may attend in future
                concerning any disease or illness or injury.
              </p>
              <span className='fs-4'>
                10. You understand agree that (section 41 of Insurance Act)
              </span>
              <p>
                (a) No person shall allow or offer to allow, either directly or
                indirectly, as an inducement to any person to take out or renew
                or continue an insurance in respect of any kind of risk relating
                to lives or property in India, any rebate of the whole or part
                of the commission payable or any rebate of the premium shown on
                the policy, nor shall any person taking out or renewing or
                continuing a Policy accept any rebate, except such rebate as may
                be allowed in accordance with the published prospectuses or
                tables of the insurers.
                <br />
                (b) Any person making default in complying with the provision of
                this section shall be liable for a penalty which may extend to
                ten lakh rupees.
              </p>
              <span className='fs-4'>
                11. Customer Due Diligence requirements (CDD)
              </span>
              <p>
                You agree and acknowledge that for undertaking any financial
                transaction through the website, Our Company may undertake
                client/customer due diligence measures and seek mandatory
                information required for KYC purpose which as a customer you are
                obliged to give, while facilitating your request of insurance
                requirements with the insurance company(ies), in accordance with
                applicable laws and rules. Where required, you hereby consent to
                and authorize Our Company to obtain your credit information
                through authorized entities. Our Company may obtain sufficient
                information to establish, to its satisfaction or the insurance
                company, the identity of each new customer, and the purpose of
                the intended nature of insurance relationship between you and
                the insurance company(ies). You agree and understand that you
                shall ensure that any payment towards insurance premium is
                remitted only through your bank account or from a joint bank
                account in which you are a joint holder. In the event, payment
                towards insurance premium is remitted through a bank account
                opened in the name of a third party(i.e. not being in your
                name), you agree and acknowledge that Our Company can undertake
                enhanced due diligence measures(including any documentation), to
                satisfy itself relating to customer due diligence requirements.
                You further agree and acknowledge that, in line with the
                requirements and obligations under the PMLA Act and rules, all
                refunds shall be processed by the insurance company(ies) through
                us to the bank account which was used to remit payment of
                insurance premium.
              </p>
              <span className='fs-4'>12. License Disclaimer</span>
              <p>
                Nothing on any Insurefast website shall be construed as
                conferring any license under any of Insurefast's or any third
                party's intellectual property rights, whether by estoppel,
                implication, or otherwise.
              </p>
              <span className='fs-4'>13. Local Laws</span>
              <p>
                Insurefast is compliant as per the local, state and central
                laws. We endeavour to provide information under its control or
                possession, or assistance to any Government agency which is
                lawfully authorised to investigate or protect cybersecurity
                activities, for the purposes of verification of identity, or for
                the prevention, detection, investigation, or prosecution, of
                offences under any law for the time being in force, or for cyber
                security incidents as soon as possible, but not later than
                seventy two hours of the receipt of an order or as per the
                timelines specified in any government or regulatory order.
                Accordingly, our Grievance officer and our legal team is
                authorized to receive and acknowledge any order, notice or
                direction issued by the Appropriate Government, any competent
                authority, or a court of competent jurisdiction. Insurefast
                controls and operates this Website from its headquarters in
                Gurgaon, India and makes no representation that the materials on
                the website are appropriate or available for use in other
                locations. If you use this Website from other locations, you are
                responsible for compliance with applicable local laws including
                but not limited to the export and import regulations of other
                countries. Unless otherwise explicitly stated, all marketing or
                promotional materials found on this Website are solely directed
                to individuals, companies or other entities located in India and
                comply with the laws prevailing for the time being in force in
                India. Disputes if any shall be subject to the exclusive
                jurisdiction of Courts at Gurgaon.
              </p>
              <span className='fs-4'>14. Limitation of Liability</span>
              <p>
                You expressly understand and agree that Insurefast and its
                subsidiaries, affiliates, officers, employees, agents, partners
                and licensors shall not be liable to you for any direct,
                indirect, incidental, special, consequntial or exemplary
                damages, including, but not limited to, damages for loss of
                profits, goodwill, use, data or other intangible losses (even if
                Insurefast has been advised of the possibility of such damages),
                resulting from use of the Site / Mobile Application, content or
                any related services. If, despite the limitation above,
                Insurefast or its Affiliates are found liable for any loss or
                damage which arises out of or in any way connected with any of
                the occurrences described above, then the liability of
                Insurefast and/or Its Affiliates will in no event exceed, in the
                aggregate, the greater of (a) the service fees you paid to
                Insurefast in connection with such transaction(s) on this Site /
                Mobile Application, or (b) Rupees One Hundred only (INR 100) The
                limitation of liability reflects the allocation of risk between
                the parties. The limitations specified in this section will
                survive and apply even if any limited remedy specified in these
                terms is found to have failed of its essential purpose. The
                limitations of liability provided in these terms inure to the
                benefit of Insurefast, Its affiliates, and/or their respective
                service providers.
              </p>
              <span className='fs-4'>15. Indemnity</span>
              <p>
                You agree to indemnify and hold Insurefast (and its officers,
                directors, agents, subsidiaries, joint ventures, and employees)
                harmless from any and against any claims, causes of action,
                demands, recoveries, losses, damages, fines, penalties or other
                costs or expenses of any kind or nature, including reasonable
                attorneys' fees, or arising out of or related to your breach of
                this T&C, your violation of any law or the rights of a third
                party, or your use of the Site / Mobile Application.
              </p>
              <span className='fs-4'>16. Electronic Communication</span>
              <p>
                When you use the Site / Mobile Application or send emails to
                Insurefast, you are communicating with Insurefast
                electronically. You consent to receive communications from
                Insurefast electronically (including your email ID and
                WhatsApp). Insurefast may communicate with you by email or by
                posting notices on the Site / Mobile Application or by phone or
                usually available means of communication (including WhatsApp).
                You agree that all agreements, notices, disclosures and other
                communications that we provide to you electronically satisfy any
                legal requirement that
              </p>
              <span className='fs-4'>17. Site-Provided Email and Postings</span>
              <p>
                The Site / Mobile Application may provide users with the ability
                to send email messages to other users and non-users and to post
                messages on the Site / Mobile Application. Insurefast is under
                no obligation to review any messages, information or content
                ("Postings") posted on the Site / Mobile Application by users
                and assumes no responsibility or liability relating to any such
                Postings. Notwithstanding the above, Insurefast may from time to
                time monitor the Postings on the Site / Mobile Application and
                may decline to accept and/or remove any email or Postings. You
                understand and agree that you shall not to use any functionality
                provided by the Site / Mobile Application to post content or
                initiate communications or host, display, upload, modify,
                publish, transmit, store, update or share any information that
                contain: (i) Any unlawful, harmful, threatening, abusive,
                harassing, defamatory, vulgar, obscene, profane, hateful,
                racially, ethnically or otherwise objectionable material of any
                kind, including, but not limited to, any material which
                encourages conduct that would constitute a criminal offense,
                give rise to civil liability or otherwise violate any applicable
                local, state, national or international law for the time being
                in force or is defamatory, obscene, pornographic, paedopholic,
                invasive of another privacy including bodily privacy, insulting
                or harassing on the basis of gender, libellous, racially or
                ethnically objectionable, relating or encouraging money
                laundering or gambling, or otherwise inconsistent with or
                contrary to the laws in force; (ii) Advertisements or
                solicitations of any kind; (iii) Impersonate others or provide
                any kind of false information or belongs to another person and
                to which the user does not have any right; (iv) Personal
                information such as messages which state phone numbers, account
                numbers, addresses, or employer references.; (v) Messages by
                non-spokesperson employees of Insurefast purporting to speak on
                behalf of Insurefast or containing confidential information or
                expressing opinions concerning Insurefast; (vi) Messages that
                offer unauthorized downloads of any copyrighted or private
                information or infringes any patent, trademark, copyright or
                other proprietary rights; (vii) Multiple messages placed within
                individual folders by the same user restating the same point;
                (viii) Chain letters of any kind; (ix) Identical (or
                substantially similar) messages to multiple recipients
                advertising any product or service, expressing a political or
                other similar message, or any other type of unsolicited
                commercial message; (x) is harmful to child; (xi) deceives or
                misleads the addressee about the origin of the message or
                knowingly and intentionally communicates any information which
                is patently false or misleading in nature but may reasonably be
                perceived as a fact; (xii) impersonates another person; (xiii)
                threatens the unity, integrity, defence, security or sovereignty
                of India, friendly relations with foreign States, or public
                order, or causes incitement to the commission of any cognisable
                offence or prevents investigation of any offence or is insulting
                other nation; (xiv) contains software virus or any other
                computer code, file or program designed to interrupt, destroy or
                limit the functionality of any computer resource; or (xv) is
                patently false and untrue, and is written or published in any
                form, with the intent to mislead or harass a person, entity or
                agency for financial gain or to cause any injury to any person.
                This prohibition includes but is not limited to a) Using
                Insurefast to send messages to people who don't know you or who
                are unlikely to recognize you as a known contact; b) Using
                Insurefast to connect to people who don't know you and then
                sending unsolicited promotional messages to those direct
                connections without their permission; and c) Sending messages to
                distribution lists, newsgroup aliases, or group aliases.
              </p>
              <span className='fs-4'>18. Links</span>
              <p>
                The Site / Mobile Application or third parties may provide links
                to other World Wide Web sites or resources. Because Insurefast
                has no control over such sites and resources, you acknowledge
                and agree that Insurefast is not responsible for the
                availability of such external sites or resources, and does not
                endorse and is not responsible or liable for any content,
                advertising, products or other materials on or available from
                such sites or resources. You further acknowledge and agree that
                Insurefast shall not be responsible or liable, directly or
                indirectly, for any damage or loss caused or alleged to be
                caused by or in connection with use of or reliance on any such
                content, goods or services available on or through any such Site
                / Mobile Application or resource.
              </p>
              <span className='fs-4'>
                19. Access to Password Protected/Secure Areas
              </span>
              <p>
                Access to and use of password protected and/or secure areas of
                the Site / Mobile Application is restricted to authorized users
                only. Unauthorized individuals attempting to access these areas
                of the Site / Mobile Application may be subject to prosecution.
              </p>
              <span className='fs-4'>
                20. Modification and Notification of Changes
              </span>
              <p>
                Insurefast reserves the right to make changes to the Site /
                Mobile Application, related policies and agreements, this T&C
                and the Privacy Policy at any time. If Insurefast makes a
                material modification to this T&C, it may notify you by: (i)
                displaying a prominent announcement above the text of this T&C
                or the Privacy Policy, as appropriate, for thirty (30) days,
                being deemed sufficient notification, of such changes. After
                notice of a modification to the T&C or the Privacy Policy has
                been posted for 30 days, the notice will be removed. If you have
                not used the Site / Mobile Application for more than 30 days,
                you should check the T&C and/or Privacy Policy prior to any
                further use of the Site / Mobile Application. Should you wish to
                terminate your account due to a modification to the T&C or the
                Privacy Policy, you may do so by sending an email with the
                subject line "Termination" to the following email address:
                support@Insurefast.in. If you choose to continue using the Site
                / Mobile Application, you agree that by doing so you will be
                deemed to accept the new T&C or Privacy Policy, as relevant.
              </p>
              <span className='fs-4'>21. Trademarks</span>
              <p>
                The trademarks, logos and service marks ("Marks") displayed on
                the Site / Mobile Application are the property of Insurefast/its
                parent company and other associated parties and service
                providers. Users are prohibited from using any Marks for any
                purpose including, but not limited to use as metatags on other
                pages or sites on the World Wide Web without the written
                permission of Insurefast or such third party which may own the
                Marks. All information and content including any software
                programs available on or through the Site / Mobile Application
                ("Content") is protected by copyright. Users are prohibited from
                modifying, copying, distributing, transmitting, displaying,
                publishing, selling, licensing, creating derivative works or
                using any Content available on or through the Site / Mobile
                Application for commercial or public purposes.
              </p>
              <span className='fs-4'>
                22. Survival of Terms After Agreement Ends
              </span>
              <p>
                Notwithstanding any other provisions of this T&C, or any general
                legal principles to the contrary, any provision of this T&C that
                imposes or contemplates continuing obligations on a party will
                survive the expiration or termination of this T&C.
              </p>
              <span className='fs-4'>23. General</span>
              <p>
                If any of these conditions are deemed invalid, void, or for any
                reason unenforceable, the parties agree that the court should
                endeavor to give effect to the parties’ intentions as reflected
                in the provision, and the unenforceable condition shall be
                deemed severable and shall not affect the validity and
                enforceability of any remaining condition. Headings are for
                reference purposes only and do not limit the scope or extent of
                such section. This T&C and the relationship between you and
                Insurefast will be governed by the laws of the India without
                regard to its conflict of law provisions. You and Insurefast
                agree to submit to the personal jurisdiction of the courts
                located in Jaipur jurisdiction with respect to any legal
                proceedings that may arise in connection with this T&C. The
                failure of Insurefast to act with respect to a breach by you or
                others does not waive its right to act with respect to
                subsequent or similar breaches. Insurefast does not guarantee it
                will take action against all breaches of this T&C. Except as
                otherwise expressly provided in this T&C, there shall be no
                third-party beneficiaries to this T&C. This T&C constitutes the
                entire agreement between you and Insurefast and governs your use
                of the Site / Mobile Application, superseding any prior
                agreements between you and Insurefast with respect to the Site /
                Mobile Application.
              </p>

              <span className='fs-4'>Disclaimer-</span>
              <p>
                Insurefast intends to provide clear and unbiased information
                about insurance products and services. The information and data
                are generic in nature. Our efforts are to offer unbiased,
                accurate and responsible data to the best of our ability. We are
                however, in no manner or form responsible for any discrepancies
                in the data published on our website/ mobile application There
                is no intention or purpose of violating any intellectual
                property or ancillary rights. All information provided on the
                website/portal/ mobile application. Insurefast is subject to
                it’s discretion and is likely to change without any notice.
                Though, any changes in public utility will be communicated on
                our website/portal/ mobile application.
              </p>
              <p>
                Although we have tried to maintain high standards in quality,
                clarity and accuracy of the material posted on the
                website/portal/ mobile application. Insurefast is not legally
                responsible for the same in any manner what so ever. Employees,
                partners and associated staff of Insurefast are not accountable
                for any loss, harm or damage that occurs or may occur or arise
                from the usage of information from the website/portal/ mobile
                application. Customers are advised to use their own discretion
                in such matters.
              </p>
              <p>
                It is important to understand that insurance is a subject matter
                of solicitation and market risks. It is the responsibility of
                the customer to understand the limitations of insurance policies
                and the risks involved, and under no circumstance, form or
                manner, do we take any liability in such cases. We also
                recommend you to please read the subject and offer documents
                carefully. The information provided on the website/portal/
                mobile application is for financial and insurance purposes. It
                is a mutual understanding between us and the customers that
                their association with the website/portal/ mobile application
                will be at the customer's sole discretion and risk.
              </p>
              <p>
                You hereby agree and confirm that you shall be solely
                responsible for the authenticity of the document uploaded here.
                We are acting only as a facilitator and claim acceptance or
                rejection is the sole discretion of the insurer. Visitors to
                this website/portal/ mobile application and every third party is
                hereby informed that the owners of this website/portal/ mobile
                application, viz., Insurefast Insurance Web Aggregator Private
                Limited, are not the agents, corporate agents, related party or
                intermediaries of the insurers, whose products are dealt with in
                this website/portal/ mobile application. They are also not the
                sub-agents or sub-intermediaries of the agents and
                intermediaries of the respective insurers. Though endeavour is
                made to make correct policy/product comparisons, quotes,
                features, etc., based on the information provided by the
                insurers, it is made abundantly clear that Insurefast Insurance
                Web Aggregator Private Limited, its directors, shareholders,
                officers and employees and Insurefast.in are in no way
                responsible to or liable for any one for his/her investment
                decision, and every prospect/ investor/policyholder shall be
                solely responsible for the consequences of his/her decision.
              </p>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermsCondition
