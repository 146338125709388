import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const QuotelistCashlessHospitalList = ({ show, list, heading, setModel }) => {
  const toggle = () => setModel(!show);
  const [searchHospital, setSearchHospital] = useState("");

  const filteredHospitalList = list.filter((result) =>
    result.HospitalName.toLowerCase().includes(searchHospital.toLowerCase())
  );

  const searchIcon = <FaSearch />;

  return (
    <Modal isOpen={show} toggle={toggle} centered backdrop="static" size="lg">
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
          <h4>{heading}</h4>
          <div onClick={toggle} class="v2_close false">
            <img src="./assets/images/v2_close.svg" title="Close" alt="close" />
          </div>
        </div>
        <hr />
        <div className="input-group mb-3">
          <span className="input-group-text">
            <FaSearch />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Search hospitals..."
            value={searchHospital}
            onChange={(e) => setSearchHospital(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <div className="d-flex justify-content-between my-3">
            <div className="fw-bold" style={{ width: "20rem" }}>
              Name
            </div>
            <div className="fw-bold" style={{ width: "20rem" }}>
              Address
            </div>
          </div>
          {/* {list?.map((res) => {
            return (
              <div className="d-flex justify-content-between my-3">
                <div style={{ width: "20rem" }}>{res.HospitalName}</div>
                <div style={{ width: "20rem" }}>
                  {res.HospitalAddress}, {res.PinCode}
                </div>
              </div>
            );
          })} */}
          {filteredHospitalList.map((result) => (
            <div
              className="d-flex justify-content-between my-3"
              key={result.id}
            >
              <div style={{ width: "20rem" }}>{result.HospitalName}</div>
              <div style={{ width: "20rem" }}>
                {result.HospitalAddress}, {result.PinCode}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default QuotelistCashlessHospitalList;
