import React from "react";

const PreviousButton = ({ onClick }) => {
  return (
    <div class="rto-sub final-step-inner">
      <button type="button" className="btn" onClick={onClick}>
        Previous
      </button>
    </div>
  );
};

export default PreviousButton;
