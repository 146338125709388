import React from "react";

function FastestInsurance() {
  return (
    <div className="ad-section-outer container">
      <div className="ad-section">
        <div className="ad-section-top d-flex ">
          <div className="ad-section-top-left">
            <h2>
              Buying an insurance policy was <br />
              never so easy, fast and hassle-free
            </h2>
            <img src="/assets/images/underlline.png" />
            <p>
              At Insurefast, we're your go-to insurance web aggregator and
              broker. Experience a future where insurance seamlessly meets
              innovation. Redefining your insurance journey, our cutting-edge
              digital platform puts you in control. As your trusted aggregator,
              we curate the best options, making insurance comparison swift and
              secure. Your journey to optimal coverage starts here – fast,
              efficient, and personalized just for you.
            </p>
          </div>
          <div className="ad-section-top-right">
            <img src="/assets/images/family-2.svg" />
          </div>
        </div>
        <div className="ad-section-bottom d-flex flex-wrap ">
          <div className="ad-section-card">
            <div className="ad-section-circle circle-1">
              <img src="/assets/images/security.svg" />
            </div>
            <h4 className="py-3">Secured Data</h4>
            <p>
              Discover peace of mind at Insurefast – your gateway to secured
              data. We prioritize your privacy, employing cutting-edge
              encryption to safeguard your information. Your protection is our
              top priority.
            </p>
          </div>
          <div className="ad-section-card card-odd">
            <div className="ad-section-circle circle-2">
              <img src="/assets/images/fast-service.svg" />
            </div>
            <h4 className="py-3">Fast Service</h4>
            <p>
              Experience lightning-fast service with our cutting-edge aggregator
              platform. Compare quotes, choose the best policies, and secure
              coverage in minutes. Your time matters, and so does your peace of
              mind.
            </p>
          </div>
          <div className="ad-section-card card-odd">
            <div className="ad-section-circle circle-3">
              <img src="/assets/images/stress-free-claim.svg" />
            </div>
            <h4 className="py-3">Stress Free Claims</h4>
            <p>
              InsureFast ensures stress-free claims with a seamless process. Our
              intuitive platform aggregates top insurance providers, offering
              you diverse options. File claims effortlessly, and our dedicated
              support team ensures swift resolutions.
            </p>
          </div>
          <div className="ad-section-card">
            <div className="ad-section-circle circle-4">
              <img src="/assets/images/direct-debit.svg" />
            </div>
            <h4 className="py-3">Seemless Payment Options</h4>
            <p>
              Enjoy a seamless experience with our smooth payment options. From
              credit cards to digital wallets, we've got you covered. Secure,
              swift transactions make protecting what matters most a breeze.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FastestInsurance;
