import React from "react";
import Layout from "./Layout";
const SupportNew = () => {
  return (
    <Layout>
      <section class="supporthero mt-0">
        <div class="supportcontent-inner">
          <div class="row justify-content-between pt-lg-3">
            <div class="supporthero-inner col-lg-5 col-12">
              <h1 class="green-inner">Give your</h1>
              <h1 class="">Customer Support</h1>
              <p class="text-muted mt-3">
                InsureFast is a new player in the vehicle insurance market, but
                we are dedicated to providing you with the best coverage options
                and exceptional customer service. Our website is user-friendly
                and designed to make the insurance process easy and hassle-free.
                We work with multiple insurance providers to offer you
                competitive rates and a range of policy options. With
                InsureFast, you can trust that you're getting the best value for
                your money.
              </p>
            </div>
            <div class="col-lg-5 col-md-9 col-11 mx-lg-0 mx-auto">
              <img
                src="https://insurefast.in/assets/img/supporthero.png"
                class="w-100"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <div class="supportcontent">
        <div class="supportcontent-inner">
          <div class="row supportcontent-main justify-content-between">
            <div class="col-lg-4 col-12">
              <div class="supportcontent-card h-100">
                <div class="supportcontent-card-body">
                  <img
                    src="https://insurefast.in/assets/img/Supportone.png"
                    alt=""
                  />
                  <h1 class="fw-600 font-jost my-3 py-3">
                    24x7 Insurance support service
                  </h1>
                  <p class="mt-4 mb-0 text-muted text-start fs-4">
                    At InsureFast, we understand that unexpected situations can
                    arise at any time, which is why we offer 24x7 insurance
                    support services. Our team of dedicated professionals is
                    available to answer your questions and assist you with any
                    concerns you may have regarding your policy, coverage, or
                    claims. Whether it's early in the morning or late at night,
                    we're always here to help.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="supportcontent-card margincard h-100">
                <div class="supportcontent-card-body">
                  <img
                    src="https://insurefast.in/assets/img/supporttwo.png"
                    alt=""
                  />
                  <h1 class="fw-600 font-jost my-3 py-3">
                    Ask to Any Question
                  </h1>
                  <p class="mt-4 mb-0 text-muted text-start fs-4">
                    We believe that an informed customer is a happy customer.
                    That's why we encourage our customers to ask us any question
                    they may have about their policy, coverage, or claims
                    process. Our knowledgeable customer service representatives
                    are always ready to provide accurate and timely responses to
                    your queries, no matter how big or small they may be. Don't
                    hesitate to reach out to us - we're here to help you make
                    informed decisions about your insurance needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="supportcontent-card h-100">
                <div class="supportcontent-card-body">
                  <img
                    src="https://insurefast.in/assets/img/SupportThree.png"
                    alt=""
                  />
                  <h1 class="fw-600 font-jost my-3 py-3">Get A Solutions</h1>
                  <p class="mt-4 mb-0 text-muted text-start fs-4">
                    At InsureFast, we're committed to providing practical and
                    effective solutions to our customers. Whether you need help
                    selecting the right policy, filing a claim, or understanding
                    your coverage, our team is dedicated to finding the right
                    solution for you. We work closely with our customers to
                    understand their unique needs and provide customized
                    solutions that meet their specific requirements. Our goal is
                    to make the insurance process as simple and stress-free as
                    possible
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="supportcontent mt-4 mb-4">
        <div class="supportcontent-inner">
          <div class="row">
            <div class="aboutqury col-lg-9 col-12 mx-auto">
              <h1 class="text-center fw-600 font-jost display-5">
                Need to call any insurance regarding
              </h1>
              <p class="text-muted text-center mt-4 pt-3">
                When it comes to insurance-related inquiries about your policy
                or coverage, rely on InsureFast for accurate information and
                reliable support. Our knowledgeable team is well-versed in the
                intricacies of the insurance industry and will assist you in
                navigating through any complexities. Collaborating with top-tier
                insurance providers, we offer a diverse range of options to
                ensure you find the coverage that perfectly aligns with your
                unique requirements.
              </p>
            </div>
            <div class="col-lg-5 col-11 mx-auto">
              <form action="#" class="mt-5">
                <div class="input-group aboutqury-button mb-3 position-relative">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Enter your email"
                  />
                  <button
                    class="btn btn-primary"
                    type="button"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SupportNew;