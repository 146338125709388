import React, { useEffect, useRef, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import _ from "lodash";
import { Link } from "react-router-dom";
// import petModal from "./petModal";
import PetModal from "./petModal";
import { useForm } from "react-hook-form";
import { dispatchPetQuickQuote } from "../../store/action/userActions";

const Dog = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const [petModal, setPetModal] = useState(false);

  const usernameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const contactNumberInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const showPasswordButtonRef = useRef(null);
  const faceRef = useRef(null);

  useEffect(() => {
    const usernameInput = usernameInputRef.current;
    const emailInput = emailInputRef.current;
    const contactNumberInput = contactNumberInputRef.current;
    const passwordInput = passwordInputRef.current;
    const showPasswordButton = showPasswordButtonRef.current;
    const face = faceRef.current;

    if (
      !usernameInput ||
      !emailInput ||
      !contactNumberInput ||
      !passwordInput ||
      !showPasswordButton ||
      !face
    ) {
      console.error(
        "One or more refs are null. Check your element references."
      );
      return;
    }

    usernameInput.addEventListener("focus", handleUsernameFocus);
    usernameInput.addEventListener("blur", handleUsernameBlur);
    usernameInput.addEventListener(
      "input",
      _.throttle(handleUsernameInput, 100)
    );

    emailInput.addEventListener("focus", handleEmailFocus);
    emailInput.addEventListener("blur", handleEmailBlur);

    contactNumberInput.addEventListener("focus", handleContactNumberFocus);
    contactNumberInput.addEventListener("blur", handleContactNumberBlur);

    passwordInput.addEventListener("focus", handlePasswordFocus);
    passwordInput.addEventListener("blur", handlePasswordBlur);

    showPasswordButton.addEventListener("click", handleShowPasswordClick);

    return () => {
      usernameInput.removeEventListener("focus", handleUsernameFocus);
      usernameInput.removeEventListener("blur", handleUsernameBlur);
      usernameInput.removeEventListener("input", handleUsernameInput);

      emailInput.removeEventListener("focus", handleEmailFocus);
      emailInput.removeEventListener("blur", handleEmailBlur);

      contactNumberInput.removeEventListener("focus", handleContactNumberFocus);
      contactNumberInput.removeEventListener("blur", handleContactNumberBlur);

      passwordInput.removeEventListener("focus", handlePasswordFocus);
      passwordInput.removeEventListener("blur", handlePasswordBlur);
      showPasswordButton.removeEventListener("click", handleShowPasswordClick);
    };
  }, []);

  const handlePasswordFocus = () => {
    const hands = document.querySelectorAll(".hand");
    const tongue = faceRef.current?.querySelector(".tongue");

    if (hands && tongue) {
      hands.forEach((hand) => {
        hand.classList.add("hide");
      });
      tongue.classList?.remove("breath");
    }
  };

  const handlePasswordBlur = () => {
    const hands = document.querySelectorAll(".hand");
    const tongue = faceRef.current?.querySelector(".tongue");

    if (hands && tongue) {
      hands.forEach((hand) => {
        hand.classList.remove("hide");
        hand.classList.remove("peek");
      });
      tongue.classList?.add("breath");
    }
  };

  const handleUsernameFocus = () => {
    let length = Math.min(usernameInputRef.current.value.length - 16, 19);
    document.querySelectorAll(".hand").forEach((hand) => {
      hand.classList.remove("hide");
      hand.classList.remove("peek");
    });

    faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
  };

  const handleUsernameBlur = () => {
    faceRef.current.style.setProperty("--rotate-head", "0deg");
  };

  const handleEmailFocus = () => {
    let length = Math.min(emailInputRef.current.value.length - 16, 19);
    document.querySelectorAll(".hand").forEach((hand) => {
      hand.classList.remove("hide");
      hand.classList.remove("peek");
    });

    faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
  };

  const handleEmailBlur = () => {
    faceRef.current.style.setProperty("--rotate-head", "0deg");
  };

  const handleContactNumberFocus = () => {
    let length = Math.min(contactNumberInputRef.current.value.length - 16, 19);
    document.querySelectorAll(".hand").forEach((hand) => {
      hand.classList.remove("hide");
      hand.classList.remove("peek");
    });

    faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
  };

  const handleContactNumberBlur = () => {
    faceRef.current.style.setProperty("--rotate-head", "0deg");
  };

  const handleUsernameInput = (event) => {
    let length = Math.min(event.target.value.length - 16, 19);
    faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
  };

  const handleShowPasswordClick = () => {
    const passwordInput = passwordInputRef.current;
    if (passwordInput.type === "text") {
      passwordInput.type = "text";
      document.querySelectorAll(".hand").forEach((hand) => {
        hand.classList.remove("peek");
        hand.classList.add("hide");
      });
    } else {
      passwordInput.type = "text";
      document.querySelectorAll(".hand").forEach((hand) => {
        hand.classList.remove("hide");
        hand.classList.add("peek");
      });
    }
    faceRef.current.style.setProperty("--rotate-head", `${-length}deg`);
  };

  //   1,2,34, tabs

  const [activeIndex, setActiveIndex] = useState(0);

  const tabsData = [
    {
      title: "Surgery and Hosptalisation Cover",
      description:
        "If your doggy needs surgery or hospitalisation to get back on its paws, we’ve got you covered! Our policy covers all the major surgeries and treatments. What’s more, we cover medical expenses as well, such as vet fees, diagnostics tests, medicines, drugs and consumables.",
      imageUrl: "/assets/images/health-insurance.jpg",
    },
    {
      title: "OPD Cover",
      description:
        "Upto 20% of the Sum insured of Surgery and Hospitalisation cover, upto maximum amount to INR10,000",
      imageUrl: "/assets/images/health-insurance.jpg",
    },
    {
      title: "OPD Cover",
      description:
        "Upto 20% of the Sum insured of Surgery and Hospitalisation cover, upto maximum amount to INR10,000",
      imageUrl: "/assets/images/health-insurance.jpg",
    },
  ];

  const reset = () => {
    const indexes = document.querySelectorAll(".indexes li");
    const tabs = document.querySelectorAll(".tab");
    const contents = document.querySelectorAll(".tab-content");

    for (let i = 0; i < tabs.length; i++) {
      indexes[i].style.borderColor = "transparent";
      tabs[i].style.zIndex = 0;
      tabs[i].classList.remove("active");
      contents[i].classList.remove("active");
    }
  };

  const showTab = (i) => {
    const indexes = document.querySelectorAll(".indexes li");
    const tabs = document.querySelectorAll(".tab");
    const contents = document.querySelectorAll(".tab-content");

    indexes[i].style.borderColor = "rgba(211,38,38,0.6)";
    tabs[i].style.opacity = 1;
    tabs[i].style.zIndex = 5;
    tabs[i].classList.add("active");
    contents[i].classList.add("active");
  };

  const activate = (index) => {
    reset();
    setActiveIndex(index);
  };

  useEffect(() => {
    showTab(activeIndex);
  }, [activeIndex]);

  const submitUserDetails = (data) => {
    console.log("pet data", data);
    for (let key in data) {
      dispatchPetQuickQuote(`userDetails.${key}`, data[key]);
    }
    setPetModal(true);
  };

  console.log("ERRORS", errors);

  return (
    <>
      <Header />
      <div className="main_sec mt-0">
        <div className="">
          <div className="pet_wrapper">
            <div className="banner">
              <div className="message">
                <span className="health_title">Dog Health Cover</span>
                <h1 className="health_heading">
                  Good dogs can <br /> make bad decisions.
                </h1>
                <p className="pet_sec_text">
                  Discover peace of mind with pet insurance! With comprehensive
                  plans from top insurers covering veterinary expenses for
                  illness, injury, and accidents. Plus, enjoy third-party
                  liability coverage for protection against unexpected
                  incidents. Give your furry friend the care they deserve with a
                  perfect pet insurance at Insurefast. Sign up today!
                </p>
              </div>
            </div>

            <div className="center">
              <div className="ear ear--left"></div>
              <div className="ear ear--right"></div>

              <div className="face" ref={faceRef}>
                <div className="eyes">
                  <div className="eye eye--left">
                    <div className="glow"></div>
                  </div>
                  <div className="eye eye--right">
                    <div className="glow"></div>
                  </div>
                </div>
                <div className="nose">
                  <svg width="38.161" height="22.03">
                    <path
                      d="M2.017 10.987Q-.563 7.513.157 4.754C.877 1.994 2.976.135 6.164.093 16.4-.04 22.293-.022 32.048.093c3.501.042 5.48 2.081 6.02 4.661q.54 2.579-2.051 6.233-8.612 10.979-16.664 11.043-8.053.063-17.336-11.043z"
                      fill="#243946"
                    ></path>
                  </svg>
                  <div className="glow"></div>
                </div>
                <div className="mouth">
                  <svg
                    className="smile"
                    viewBox="-2 -2 84 23"
                    width="84"
                    height="23"
                  >
                    <path
                      d="M0 0c3.76 9.279 9.69 18.98 26.712 19.238 17.022.258 10.72.258 28 0S75.959 9.182 79.987.161"
                      fill="none"
                      strokeWidth="3"
                      strokeLinecap="square"
                      strokeMiterlimit="3"
                    ></path>
                  </svg>
                  <div className="mouth-hole"></div>
                  <div className="tongue breath">
                    <div className="tongue-top"></div>
                    <div className="line"></div>
                    <div className="median"></div>
                  </div>
                </div>
              </div>

              <div className="hands">
                <div className="hand hand--left">
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                </div>
                <div className="hand hand--right">
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                  <div className="finger">
                    <div className="bone"></div>
                    <div className="nail"></div>
                  </div>
                </div>
              </div>

              <form onSubmit={handleSubmit(submitUserDetails)}>
                <div className="login">
                  <label>
                    {/* <div className="fa fa-phone"></div> */}
                    <input
                      className="username"
                      type="text"
                      ref={usernameInputRef}
                      {...register("ownerFullName", {
                        required: "Field is required",
                        minLength: {
                          value: 3,
                          message: "Enter atleast 3 characters",
                        },
                      })}
                      maxLength={100}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\d/g, ""))
                      }
                      autoComplete="on"
                      placeholder="Username"
                    />
                    {errors && (
                      <span className="text-danger ps-2">
                        {errors?.ownerFullName?.message}
                      </span>
                    )}
                  </label>
                  <label>
                    {/* <div className="fa fa-phone"></div> */}
                    <input
                      className="username"
                      type="email"
                      ref={emailInputRef}
                      {...register("ownerEmail", {
                        required: "Field is required",
                      })}
                      autoComplete="on"
                      placeholder="Email Id"
                      onInput={(e) => {
                        let inputValue = e.target.value;

                        if (inputValue.includes(".com")) {
                          inputValue = inputValue.replace(/\.com.*/, ".com");
                        } else if (inputValue.includes(".in")) {
                          inputValue = inputValue.replace(/\.in.*/, ".in");
                        }

                        e.target.value = inputValue;
                      }}
                    />
                    {errors && (
                      <span className="text-danger ps-2">
                        {errors?.ownerEmail?.message}
                      </span>
                    )}
                  </label>
                  <label>
                    {/* <div className="fa fa-phone"></div> */}
                    <input
                      className="username"
                      type="number"
                      ref={contactNumberInputRef}
                      {...register("ownerNumber", {
                        required: "Field is required",
                      })}
                      onChange={(e) => {
                        let value = e.target.value.trim();
                        if (
                          value.startsWith("0") ||
                          value.startsWith("1") ||
                          value.startsWith("2") ||
                          value.startsWith("3") ||
                          value.startsWith("4") ||
                          value.startsWith("5")
                        ) {
                          value = value.substring("1");
                        }
                        e.target.value = value;
                      }}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 10))
                      }
                      autoComplete="on"
                      placeholder="Contact Number"
                    />
                    {errors && (
                      <span className="text-danger ps-2">
                        {errors?.ownerNumber?.message}
                      </span>
                    )}
                  </label>
                  <label
                    className="mt-4 mt-md-3"
                    // onClick={() => {
                    //   setPetModal(true);
                    // }}
                  >
                    <div className="fa fa-commenting"></div>
                    <div
                      className="password"
                      type="password"
                      autoComplete="off"
                      placeholder="password"
                      ref={passwordInputRef}
                    ></div>
                    <button
                      ref={showPasswordButtonRef}
                      type="submit"
                      className="btn-primary w-100"
                    >
                      GET A QUOTE
                    </button>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="pet_about">
          <div className="container">
            <h2 className="pet_heading">What is Dog Health Cover?</h2>
            <p className="pet_text">
              Get the best Dog Health Cover at Insurefast which ensures that
              your canine companion receives top-notch veterinary care for
              illness, injury, and accidents. Enjoy peace of mind with coverage
              for emergency surgeries and specialized treatments. Additionally,
              benefit from third-party liability coverage for financial
              protection. Trust Insurefast to safeguard your furry friend's
              health and well-being.
            </p>
          </div>
        </div>
        <div className="pet_cover">
          <div className="container">
            <h2 className="pet_heading">Our General Insurance Plans</h2>
            <div className="pet_cover_inner">
              <div className="row gy-5 gy-md-5">
                <div className="col-lg-12 col-xl-6">
                  <div className="pet_covered">
                    <h3 className="sub_heading">What is Covered?</h3>
                    <div
                      style={{ display: "flex", gap: "1rem", height: "100%" }}
                    >
                      <ul className="indexes">
                        {tabsData.map((tab, index) => (
                          <li
                            key={index}
                            data-index={index}
                            onClick={() => activate(index)}
                          >
                            0{index + 1}
                          </li>
                        ))}
                      </ul>
                      <ul className="tabs">
                        {tabsData.map((tab, index) => (
                          <li
                            key={index}
                            className={`tab ${
                              index === activeIndex ? "active" : ""
                            }`}
                          >
                            <article
                              className={`tab-content ${
                                index === activeIndex ? "active" : ""
                              }`}
                            >
                              <h3>{tab.title}</h3>
                              <p>{tab.description}</p>
                              <button className="btn-primary">Read More</button>
                            </article>
                            <div className="tab-image">
                              <img
                                src={tab.imageUrl}
                                alt={`Tab ${index + 1}`}
                              />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6">
                  <div className="pet_not_covered">
                    <h3 className="sub_heading">What is Not Covered?</h3>
                    <ul class="list-with-icons">
                      <li>
                        <a href="#">Injuries or illness due to animal racing</a>
                      </li>
                      <li>
                        <a href="#">Dental complications</a>
                      </li>
                      <li>
                        <a href="#">Overseas treatment</a>
                      </li>
                      <li>
                        <a href="#">Sale of the injured dog</a>
                      </li>
                      <li>
                        <a href="#">Wilfully injuring your dog</a>
                      </li>
                      <li>
                        <a href="#">List With Icon 1</a>
                      </li>
                      <li>
                        <a href="#">List With Icon 1</a>
                      </li>
                      <li>
                        <a href="#">List With Icon 1</a>
                      </li>
                      <li>
                        <a href="#">List With Icon 1</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pet_advantage">
          <div className="container">
            <h2 className="pet_heading">Advantages of Dog Health Cover</h2>
            <div className="advantage_cover_list">
              <div class="row">
                <div class="column">
                  <div class="card">
                    <div class="icon-wrapper">
                      <img src="/assets/images/claim-management.png"></img>
                    </div>
                    <h3>Fast claim settlements</h3>
                    <p>
                      Insurefast assures of fast and hassle-free claim
                      settlement
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="card">
                    <div class="icon-wrapper">
                      <img src="/assets/images/policy-insurance.png"></img>
                    </div>
                    <h3>Swift policy issuance</h3>
                    <p>
                      Instant Policy issuance with completion of the application
                      process
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="card">
                    <div class="icon-wrapper">
                      <img src="/assets/images/cmp.png"></img>
                    </div>
                    <h3>Comprehensive coverage</h3>
                    <p>
                      Truly value for money experience with a wide range of
                      benefits
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="card">
                    <div class="icon-wrapper">
                      <img src="/assets/images/wallet.png"></img>
                    </div>
                    <h3>Pocket-friendly premiums</h3>
                    <p>
                      Pick & choose the lowest premium policy that suites your
                      requirements completely
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {petModal && <PetModal show={petModal} setModel={setPetModal} />}
    </>
  );
};

export default Dog;
