import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";

const HealthModal = ({ show, setHealthModal }) => {
  const toggle = () => setHealthModal(!show);

  const [activeStep, setActiveStep] = useState(1);

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={toggle}
        backdrop="static"
        centered
        size="lg"
        className="healthmodal"
      >
        <ModalBody>
          {activeStep === 1 && (
            <div className="p-lg-3">
              <div className="modal-logo">
                <a>
                  <img src="/assets/images/insurefastlogos.svg"></img>
                </a>
              </div>
              <div className="modal_heading w-100 mb-4">
                <h2 className="main-heading">
                  Health Insurance Plans that Suits You the Best
                </h2>
                <div onClick={toggle} className="v2_close false">
                  <a>
                    <img
                      src="./assets/images/delete_icon.svg"
                      title="Close"
                      alt="close"
                    />
                  </a>
                </div>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            <>
              <div className="p-3">
                <div className="modal-logo">
                  <a>
                    <img src="/assets/images/insurefastlogos.svg"></img>
                  </a>
                </div>
                <div className="modal_heading w-100 mb-4">
                  <h4 className="text-center">What’s the age of your pet?</h4>
                  <div onClick={toggle} className="v2_close false">
                    <a>
                      <img
                        src="./assets/images/delete_icon.svg"
                        title="Close"
                        alt="close"
                      />
                    </a>
                  </div>
                </div>
                <div className="pet_input_field">
                  <div className="rofile_details">
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <input
                          type="text"
                          className="did-floating-input"
                          // {...register("fullName", {
                          //   required: "Field is Required",
                          // })}
                          // id="name"
                          placeholder="Enter Full Name"
                          // name="name"
                        />
                        <label htmlFor="name" className="did-floating-label">
                          Name
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label">0</option>
                          <option className="did-floating-label" value="Single">
                            1
                          </option>
                          <option className="did-floating-label" value="">
                            2
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Years
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label">0</option>
                          <option className="did-floating-label" value="Single">
                            1
                          </option>
                          <option className="did-floating-label" value="">
                            2
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Month
                        </label>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="did-floating-label-content">
                        <select
                          className="did-floating-input text-truncate"
                          //   {...register("MaritalStatus")}
                          placeholder=" "
                          // name="email"
                        >
                          <option className="did-floating-label">0</option>
                          <option className="did-floating-label" value="Single">
                            11 Kg to 25 Kg
                          </option>
                          <option className="did-floating-label" value="">
                            25 Kg to 50 Kg
                          </option>
                        </select>
                        <label htmlFor="email" className="did-floating-label">
                          Expected Adult Weight
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {activeStep === 3 && <></>}
          <div class="d-flex final-step-inner-2-button gap-4 justify-content-center mt-lg-4">
            <div class="rto-sub final-step-inner">
              <button
                type="button"
                class="btn"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Previous
              </button>
            </div>
            <div class="rto-sub">
              <button
                type="button"
                class="btn-primary"
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default HealthModal;
