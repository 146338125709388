import React from "react";
import OurPartner from "./common/OurPartner";
import Support from "./common/Support";
import Layout from "./common/Layout";

function AboutBike() {
  return (
    <>
      <div class="about-bike-insurance">
        <div class="about-bike-heading">
          <h1>
            Know More about <b> Bike Insurance</b>
          </h1>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Bike insurance is a crucial financial safeguard for motorcycle/scooter owners. It provides protection against unforeseen circumstances that could lead to financial losses. Contrary to common misconceptions, bike insurance is not just a legal requirement; it is a wise investment that ensures you're covered in various situations.

            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              In essence, bike insurance offers coverage for damages to your motorcycle caused by accidents, theft, or natural disasters. It also extends to cover bodily injuries or property damage caused to others in the event of an accident for which you are responsible. This liability coverage is essential for legal and financial protection.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Comprehensive bike insurance plans go beyond the basics, often including coverage for accessories, medical expenses, and even coverage in case an uninsured or underinsured motorist is involved. Choosing the right bike insurance policy depends on factors such as the type of motorcycle you own, your riding habits, and your budget.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              In the dynamic world of two-wheelers, having the right insurance ensures that you can enjoy the thrill of the ride without worrying about the potential risks. Don't just view bike insurance as a mandatory requirement; consider it as a valuable asset that provides peace of mind on every journey.
            </p>
          </div>
        </div>
        <div class="about-bike-img">
          <img src="/assets/images/about-bike-img.png" alt="" />
        </div>

        <div class="about-bike-img">
          <img src="/assets/images/about-bike-img-2.png" alt="" />
        </div>

        <div class="about-bike-heading">
          <h1>
            Benifits of having<b> Bike Insurance</b>
          </h1>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Securing your ride isn't just about protecting your bike; it's a commitment to your peace of mind. Bike insurance is a crucial investment for riders, offering a range of benefits that extend beyond the obvious.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              First and foremost, bike insurance provides financial protection against unforeseen events, such as accidents, theft, or natural disasters. Repairing or replacing a damaged or stolen bike can be an expensive affair, and insurance ensures that you won't bear the entire financial burden.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Moreover, comprehensive bike insurance often includes coverage for third-party liabilities. In the unfortunate event of an accident where you're at fault, this coverage takes care of any bodily injury or property damage claims against you. It's not just about safeguarding your bike but also protecting your finances from potential legal ramifications.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              In conclusion, bike insurance goes beyond mere protection; it grants you the freedom to enjoy your two-wheeled adventures with confidence, knowing that you're covered against the unexpected twists of the road.
            </p>
          </div>
        </div>
      </div>
      <OurPartner />
      <div class="about-bike-insurance">
        <div class="about-heading">
          <h1>
          Why Should You Purchase Bike Insurance from Insurefast
          </h1>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              At Insurefast, we understand the thrill and freedom that comes with riding a bike. However, safeguarding your two-wheeled companion is of utmost importance. That's where our comprehensive bike insurance comes into play.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Why choose bike insurance from Insurefast? Firstly, we prioritize your peace of mind. Our policies are crafted to cover a wide range of scenarios, from accidents to theft, ensuring that you're protected on every ride. We believe in transparency, and our team is dedicated to providing you with all the necessary information to make informed decisions.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Insurefast stands out for its seamless and user-friendly web aggregator platform. We bring together top insurance providers, offering you a variety of options to choose from. Our commitment to customer satisfaction is reflected in our hassle-free claim process and responsive support team.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Your safety is our priority, and our policies are designed to cater to different budgets and requirements. Whether you're a seasoned rider or a novice, Insurefast has the right coverage for you. Don't leave your bike's safety to chance – choose Insurefast for reliable, affordable, and comprehensive bike insurance. Ride with confidence, knowing that you have the backing of Insurefast every mile of the way.
            </p>
          </div>
        </div>
        <div class="about-bike-img">
          <img src="/assets/images/about-bike-img-3.png" alt="" />
        </div>
      </div>
      <div class="about-health-insurance">
        <div class="about-biike-img">
          <img src="/assets/images/abpout-img-4.png" alt="" />
        </div>
        <div class="about-bike-heading">
          <h1>
            Which <b> Bike Insurance</b> plan <b>Should You Buy?</b>
          </h1>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              When it comes to choosing the right bike insurance plan, navigating through the myriad of options can be overwhelming. At Insurefast, we understand the importance of finding a policy that not only fits your budget but also provides comprehensive coverage. Here's a guide to help you make an informed decision on which bike insurance plan to buy.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Firstly, assess your needs. Consider factors such as the make and model of your bike, your riding habits, and the level of coverage required. Insurefast offers a range of plans, from basic coverage to premium packages that include additional benefits like roadside assistance and coverage for accessories.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Next, compare quotes from different insurers. Our web aggregator platform allows you to easily compare prices and coverage from multiple insurance providers. This ensures that you get the best value for your money without compromising on protection.
            </p>
          </div>
          <div class="about-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Additionally, take note of customer reviews and ratings for each plan. Insurefast provides insights into the customer experience with various insurers, helping you make an informed decision based on real-world feedback.
            </p>
          </div>
        </div>

        <Support />
      </div>
      <div class="about-bike-insurance">
        <div class="about-heading">
          <h1>
            Am I Eligible to<b> Bike Insurance</b>
          </h1>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              When it comes to protecting your two-wheeled companion, having the right bike insurance is paramount. Insurefast, your trusted insurance web aggregator, ensures that you not only get comprehensive coverage but also understand the eligibility criteria for obtaining the best insurance for your bike.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              To secure bike insurance through Insurefast, there are a few key eligibility factors to consider. Firstly, your bike must be a valid and registered vehicle, adhering to all local regulations. Secondly, the owner of the bike must possess a valid driver's license, emphasizing the importance of responsible and legal riding.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Additionally, your insurance eligibility may be influenced by your claim history, with a clean record often resulting in lower premiums owing to no claim bonus.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Navigating the world of bike insurance is made easy with Insurefast. Our user-friendly platform guides you through the process, ensuring you make informed decisions about your coverage. Protect your ride with Insurefast and ride worry-free on the open road.
            </p>
          </div>
        </div>
        <div class="about-bike-img">
          <img src="/assets/images/about-img-7.png" alt="" />
        </div>
      </div>
      <div class="about-bike-insurance">
        <div class="about-bike-img">
          <img src="/assets/images/about-img-6.png" alt="" />
        </div>
        <div class="about-bike-heading">
          <h1>
            Documents Required for <b> Insurance</b>{" "}
          </h1>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Vehicle Registration Certificate (RC): A valid copy of your bike's RC is mandatory. It serves as proof of ownership and provides crucial details about the vehicle.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              PAN Card: Pan Card is essential as per the latest government guidelines for KYC.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Address Proof: Submit a document confirming your current address, such as Aadhar card, passport, or utility bills. This helps in accurate do
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Previous Policy Documents: If you are renewing your bike insurance, provide copies of your previous policy documents. This aids in assessing your insurance history and determining the continuity of coverage.
            </p>
          </div>
          <div class="about-bike-text">
            <img src="/assets/images/about-icon.png" alt="" />{" "}
            <p className="justify_para">
              {" "}
              Insurefast simplifies the insurance process, ensuring you have a hassle-free experience. Get on the road worry-free by securing your bike with the right coverage and the right documents. Your safety is our priority.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutBike;
